import {
  AbstractControl,
  ValidatorFn
} from '@angular/forms';

const MILITARY_TIME = {
  format: 'HH:mm',
  regex: new RegExp(/^(0[0-9]|1[0-9]|2[0-3]):{0,1}[0-5][0-9]$/)
};

const MERIDIAN_TIME = {
  format: 'hh:mm am',
  regex: new RegExp(/((1[0-2]|0[1-9]):{0,1}([0-5][0-9]) ?([AaPp][Mm]))/)
};

/** Validate the time format */
export function timeFormatValidator(useMeridian: boolean): ValidatorFn {
  const timeType = useMeridian ? MERIDIAN_TIME : MILITARY_TIME;

  return (control: AbstractControl): { [key: string]: any } | null => {
    const isPresent = !!control.value?.length;
    const validFormat = timeType.regex.test(control.value);

    if (isPresent && !validFormat) {
      return {
        format: {
          actualFormat: control.value,
          requiredFormat: timeType.format
        }
      };
    }

    return null;
  };
}
