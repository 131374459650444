<div class="sendMessageModal" *ngIf="stateService.state$ | async as state">
    <div>
        <div mat-dialog-content>
            <div class="modal-header">
                <div>
                    <h3 class="modal-title">Secure Chat</h3><br />
                    <h5 class="modal-title title-name">{{data.recipientName}}</h5>
                </div>
                <button mat-button class="btn btn-secondary" (click)="onNoClick()">X</button>
            </div>
            <div class="modal-body">
                <chat [messages]="state.messages"></chat>

            </div>

        </div>
    </div>
</div>