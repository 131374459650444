import { AssessmentDefinition } from '../../../../../noctem-lib/src/core/contracts/models.calculations';


export const relaxation: AssessmentDefinition = {
  name: 'relaxation',
  description: 'Relaxation',
  instructions: null,
  questions: [
    {
      id: 0,
      questionText: '',
      questionEmphasis: ``,
      subText: `<h5>Learn</h5>
      <p>Anxiety can be felt in your body, through changes in breathing, heart rate and muscle tension. </p>

      <p>You can learn to release this muscle tension and reduce anxiety by practicing two types of relaxation techniques.</p>

      <p>These are best used when you have some down time and are not meant for performance situations. They can help you to unwind which may also help you sleep better and promote physical recovery, which will help you perform at your best.</p>

      <p>In <b>Guided Muscle Relaxation</b> you will systematically tense and relax different muscle groups, working throughout your whole body while paying attention to the sensations you experience. By tensing your muscles first you can more easily feel the release of tension, which helps with the relaxation response.</p>

      <p>During <b>Relaxing Visualization</b> you will fully imagine you are in a peaceful setting. By engaging all of your senses you will facilitate a physical relaxation response.</p>
      <p><img src='./assets/img/module-images/relaxation/tropical-beach.png'/></p>
      `,
      questionType: 'module',

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 1,
      questionText: '',
      questionEmphasis: ``,
      subText: `<h5>Practice</h5>
      <p>For both skills, find a comfortable, quiet place you can be undisturbed for at least 10 minutes.</p>

      <div class="call-out">
        Sit or lay with legs uncrossed and arms resting at your side. Close your eyes if you would like.
        Before you begin, notice your current level of tension and rate it, where 0 is "completely relaxed"
        and 10 is "completely tense".
      </div>
      `,
      questionType: 'module',
      uniqueAnswerId: null,
      autofill: true,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 2,
      questionText: '',
      questionEmphasis: ``,
      subText: `<h5>Activity 1</h5>
      <div class="call-out">
        You will be progressively tensing and relaxing the muscle groups throughout your body. Tense each muscle for about 5 seconds.
        If you experience pain or discomfort, skip over that muscle group.<br><br>Try to visualize the tension leaving that area as you release
        the muscle and a wave of relaxation flowing over that area. Keep breathing throughout the exercise and maintain your focus on your body.
      </div>
      <audio  class="w-100" controls>
        <source src="./assets/audio/muscle_relaxation.mp3" type="audio/mp3">
        Your browser does not support the audio element.
      </audio>
      <div class="call-out">
        <h5 class='module'>Forehead:</h5>
        Tense the muscles by arching your eyebrows upward, hold, release, notice the change in sensation.
      </div>
      <div class="call-out">
        <h5 class='module'>Eyes and Mouth:</h5>
        Close your eyes tightly, squeezing the lids together. Smile widely, feeling your cheeks tense. Hold, release, appreciate the softness in your face.
      </div>
      <div class="call-out">
        <h5 class='module'>Neck:</h5>
        Gently pull your head back as if you were looking at the ceiling, hold, release.
      </div>
      <div class="call-out">
        <h5 class='module'>Shoulders:</h5>
        Lift them up, trying to touch your ears, hold and release. Feel their heavy weight.
      </div>
      <div class="call-out">
        <h5 class='module'>Back:</h5>
        Pull your shoulders back, trying to make your shoulder blades touch, hold, release.
      </div>
      <div class="call-out">
        <h5 class='module'>Arms:</h5>
        First flex your biceps, visualize the muscle tightening, hold, and release.
      </div>
      <div class="call-out">
        <h5 class='module'>Hands:</h5>
        Clench your fists tightly, hold, release. Wiggle your fingers.
      </div>
      <div class="call-out">
        <h5 class='module'>Abdomen:</h5>
        Draw in your stomach towards your backbone, hold and release. Feel the relaxation in your upper body.
      </div>
      <div class="call-out">
        <h5 class='module'>Buttocks:</h5>
        Tighten, hold, release. Feel the looseness in your hips.
      </div>
      <div class="call-out">
        <h5 class='module'>Thighs:</h5>
        Tighten by pressing your knees together, hold, release.
      </div>
      <div class="call-out">
        <h5 class='module'>Calves/Feet:</h5>
        Pull your toes towards you, feeling the tension in your calves, hold, release. Curl your toes under, tesning your feet,
        hold, release. Feel the weight of your legs sinking down. Breathe.
      </div>
      <p>Notice the sensations you now feel in your body and rate your current level of tension. To get the most benefit from this skill, try to practice a few times a week.</p>
      `,
      questionType: 'module',
      uniqueAnswerId: null,
      autofill: true,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 3,
      questionText: '',
      questionEmphasis: ``,
      subText: `<h5>Activity 2: Relaxing Visualization</h5>
      <p>Click here for an audio-guided beach relaxation:</p>
      <audio  class="w-100" controls>
        <source src="./assets/audio/beach_visualization.mp3" type="audio/mp3">
        Your browser does not support the audio element.
      </audio>
      `,
      questionType: 'module',
      uniqueAnswerId: null,
      autofill: true,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    }
  ]
};
