import { Observable } from 'rxjs';
import { ApiHelper } from '../helpers';
import { BaseService } from './BaseService';
import { IHttpProvider, IApplicationContext, UserSettings, Role } from '../contracts';
import { BaseRepository } from '../core';
import { map } from 'rxjs/operators';


export class UserService<T extends UserSettings> extends BaseService<T> {
    private http: IHttpProvider;
    private helper: ApiHelper;
    constructor(private appContext: IApplicationContext) {
        super(new BaseRepository(appContext, 'UserSettings'));
        this.http = appContext.HttpProvider;
        this.helper = new ApiHelper(appContext);
    }

    public getCurrentUser(): Observable<T> {
        const params: any = {
            uri: this.helper.RootUrl() + '/current-user',
            json: null, data: null,
            method: 'GET',
            headers: this.helper.Headers()
        };
        return this.http.get(params).pipe(
            map((r: any) => {
                return r.user && r.user.Attributes ?  r.user.Attributes : null;
            })
        );
    }

    public getCurrentUserInfo(): Observable<{ User: T, Roles: Role[] }> {
        const params: any = {
            uri: this.helper.RootUrl() + '/current-user',
            json: null, data: null,
            method: 'GET',
            headers: this.helper.Headers()
        };
        return this.http.get(params).pipe(
            map((r: any) => {
                return r.user && r.user.Attributes ? { User: r.user.Attributes, Roles: r.user.roles } : null;
            })
        );
    }

    public save(user: T): Observable<T> {
        return super.save(user).pipe(
            map(response => {
                return response;
            })
        );
    }

    public saveClinician(user: any): Observable<T> | any {
        const params: any = {
            uri:  'api/user/saveClinician',
            json: user, data: null,
            method: 'POST',
            headers: this.helper.Headers()
        };

        return this.http.post(params);
    }
}

export class DefaultUserService extends UserService<UserSettings> {

}
