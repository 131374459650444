import { AssetDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';
import { twoInternalSystems } from '../questions/two-internal-sleep-systems/two-internal-sleep-systems';

export const twoInternalSystemsDef: AssetDefinition = {
  id: 16,
  name: 'two-internal-sleep-systems',
  label: 'Two Internal Sleep Systems',
  categories: ['all', 'sleep-and-fatigue'],
  showInLibrary: true,
  readOnly: false,
  assessments: [
    twoInternalSystems
  ],
  url: 'two-internal-sleep-systems'
};
