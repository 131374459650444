import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChatStateService, UserStateService } from '../../../state';
import { TimeTrackingService } from '../../../services/time-tracking.service';

@Component({
    selector: 'thread-message-dialog',
    styleUrls: ['thread-message-dialog.component.scss'],
    templateUrl: './thread-message-dialog.component.html',
    providers: [TimeTrackingService]
})
export class ThreadMessageDialog implements OnInit, OnDestroy {
    userId: string;


    constructor(
        public dialogRef: MatDialogRef<ThreadMessageDialog>,
        public stateService: ChatStateService,
        private timeTrackingService: TimeTrackingService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    ngOnInit() {
        this.stateService.setRecipient(this.data.recipientId);
    }

    ngAfterViewInit(){
        this.timeTrackingService.trackTimeOnElement("mat-dialog-container", { activityType: 'Secure_Chat', subEvent: 'Patient_Page_Dialog', patientId: this.data.recipientId });
    }

    ngOnDestroy(): void {
        this.stateService.clearRecipient();
    }


}
