
import { InjectionToken, Injectable, Optional, Inject } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { IApplicationContext, IHttpProvider, IRepository, SystemResponse, HttpParams } from './contracts';
import { HttpClient, HttpParams as AngularHttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BaseService } from './services/BaseService';
import { ApiHelper } from './helpers';
import { IUserStateService } from '../lib/state/user/contracts';

// TODO: is there a better place for this? Perhaps constants?
export const APP_HOST = new InjectionToken<BehaviorSubject<string>>('appHost');

export const USER_STATE_SERVICE = 'USER_STATE_SERVICE';

@Injectable()
export class AngularHttpProvider implements IHttpProvider {
    constructor(
        private http: HttpClient
    ) {
    }

    private getOptions(params: HttpParams): any {
        let searchParams = new AngularHttpParams();

        if (params.method === 'GET') {
            let obj = null;
            if (params.json) {
                obj = params.json;
            }
            if (params.data) {
                obj = params.data;
            }
            if ((params as any).qs) {
                obj = (params as any).qs;
            }
            for (const key in obj) {
                if (obj[key]) {
                    const json = typeof obj[key] === 'object' ? JSON.stringify(obj[key]) : obj[key];
                    searchParams = searchParams.append(key, json);
                }
            }
        }
        return { headers: params.headers, params: searchParams };
    }

    public get(uri: string): any;
    public get(params: HttpParams): any;
    public get(params: any): any {
        if (typeof params === 'string') {
            // TODO: This needs removed, hard coded token
            // let appContext = this.container.get(TYPES.ApplicationContext);
            // let headers = { Authorization: appContext.Token };
            // params = { uri: params, headers: headers, method: 'GET', data: null, json: null }
        }
        const options = this.getOptions(params);
        return this.http.get(params.uri, options);
    }

    public put(params: HttpParams): any {
        const options = this.getOptions(params);
        return this.http.put(params.uri, params.json, options);
    }

    public delete(params: HttpParams): any {
        const options = this.getOptions(params);
        return this.http.delete(params.uri, options);
    }

    public post(uri: string, data: any): any;
    public post(params: HttpParams): any;
    public post(params: any): any {
        if (params.uri && (params.json || params.data)) {
            // Build params object
        }
        const options = this.getOptions(params);
        return this.http.post(params.uri, params.json, options);
    }

}

@Injectable()
export class ApplicationContext implements IApplicationContext {
    Token: string;
    Host: string;
    Organization: string;
    User: any;
    HttpProvider: IHttpProvider;
    AppHost?: string;
    AltLogoutPath?: string;

    // constructor(http: HttpClient, @Inject("appHost") @Optional() appHost: string = null) {
    constructor(http: HttpClient, @Inject(APP_HOST) @Optional( ) private _appHost: BehaviorSubject<string> = null) {
        this.HttpProvider = new AngularHttpProvider(http);
        const token = localStorage.getItem('token');
        this.Token = token;
        this.Host = '/api';
        if (this._appHost) {
            this._appHost.subscribe(val => { this.AppHost = val });

            // Get from localStorage to preserve value changed by login() after page refresh. Note the Patient App's login controller.
            const storedAppHost = localStorage.getItem('appHost');
            if (storedAppHost) {
                this._appHost.next(storedAppHost);
            }
        } else {
            this.AppHost = null;
        }
    }
}

export class Model<T> {

    private _data: BehaviorSubject<T>;

    data$: Observable<T>;

    constructor(initialData: any, immutable: boolean, clone?: (data: T) => T) {
        this._data = new BehaviorSubject(initialData);
        this.data$ = this._data.asObservable().pipe(
            map(data => immutable ? clone ? clone(data) : JSON.parse(JSON.stringify(data)) : data)
        );
    }

    get(): T {
        return this._data.getValue();
    }

    set(data: T) {
        this._data.next(data);
    }

}

export class ModelFactory<T> {

    create(initialData: T): Model<T> { return new Model<T>(initialData, true); }

    createMutable(initialData: T): Model<T> {
        return new Model<T>(initialData, false);
    }

    createWithCustomClone(initialData: T, clone) {
        return new Model<T>(initialData, true, clone);
    }

}

export function useModelFactory() {
    return new ModelFactory();
}

export const MODEL_PROVIDER = {
    provide: ModelFactory, useFactory: useModelFactory
};

export abstract class ClientFuncService<T> extends BaseService<T> {
    abstract get modelName(): string;
    private helper: ApiHelper;
    private http: IHttpProvider;

    constructor(
        private applicationContext: IApplicationContext,
        protected repo: IRepository<T>,
        protected userStateService: IUserStateService) {
        super(repo);
        this.http = applicationContext.HttpProvider;
        this.helper = new ApiHelper(applicationContext);

        if (userStateService) {
            this.userStateService.state$.subscribe(userState => {
                if (this.applicationContext.Token !== userState.Token) {
                    this.applicationContext.Token = userState.Token;
                    this.helper = new ApiHelper(applicationContext);
                }
            });
        }
    }

    // TODO: shouldn't need this, provider should handle with app context
    private getOptions() {
        const headers = { Authorization: this.applicationContext.Token };
        const options = { headers };
        return options;
    }

    private funcUrl(functionName: string): string {
        return `${this.helper.RootUrl()}/${this.modelName.toLowerCase()}/${functionName}`;
    }

    protected deleteFunc<TDto>(id: string): Observable<SystemResponse<TDto>> {
        const uri = `${this.funcUrl(id)}`;
        return Observable.create(o => {

            this.http.delete({ uri, json: null, data: null, method: 'DELETE', headers: this.helper.Headers() }).pipe(
                map((r: any) => {
                    const responseJson = r;
                    return responseJson;
                })
            ).subscribe(re => {
                o.next(re);
                o.complete(re);
            }, err => {
                throw Error(err);
            });
        });

    }

    protected postFunc<TDto>(functionName: string, payload: any): Observable<SystemResponse<TDto>> {
        const options = this.getOptions();
        const uri = `${this.funcUrl(functionName)}`;
        return Observable.create(o => {
            /*uri: url,
            headers: this.apiHelper.Headers(),
            data: null,
            method: verb,
            json: data*/

            this.http.post({ uri, json: payload, data: null, method: 'POST', headers: this.helper.Headers() }).pipe(
                map((r: any) => {
                    const responseJson = r;
                    return responseJson;
                })
            ).subscribe(re => {
                o.next(re);
                o.complete(re);
            }, err => {
                console.log(err);
                throw Error(err);
            });
        });

    }

    protected putFunc<TDto>(functionName: string, payload: any): Observable<SystemResponse<TDto>> {
        const options = this.getOptions();
        const uri = `${this.funcUrl(functionName)}`;
        return Observable.create(o => {
            this.http.put({ uri, json: payload, data: null, method: 'PUT', headers: this.helper.Headers() }).pipe(
                map((r: any) => {
                    const responseJson = r;
                    return responseJson;
                })
            ).subscribe(re => {
                o.next(re);
                o.complete(re);
            }, err => {
                throw Error(err);
            });
        });

    }

    protected getFunc<TDto>(functionName: string): Observable<SystemResponse<TDto>> {
        const options = this.getOptions();

        // return this.http.get({ uri: this.funcUrl(modelName, functionName), json: null, data: null, method: 'POST', headers: null })
        return Observable.create(o => {
            const params = { uri: this.funcUrl(functionName), json: null, data: null, method: 'GET', headers: this.helper.Headers() };
            this.http.get(params)
                .subscribe(re => {
                    o.next(re);
                    o.complete(re);
                }, err => {
                    throw Error(err);
                });
        });
    }

}
