<div *ngIf="patientStateService.state$ | async as state">
    <div mat-dialog-content>
        <div class="modal-header">
            <h3 class="modal-title">{{ mangeAction }} Patient</h3>
        </div>
        <mat-tab-group>
            <div class="modal-body">
                <mat-tab label="Patient Info">
                    <div [formGroup]="patientForm" class="container">
                        <div class="row">
                            <div class="col-9">
                                <div class="row mb-4 mt-3">
                                    <div class="col-6">
                                        <input id="first-name" type="text" formControlName="firstName"
                                            placeholder="First Name" required />
                                        <div *ngIf="patientForm.get('firstName').invalid && (patientForm.get('firstName').dirty && patientForm.get('firstName').touched)"
                                            class="mt-1 text-danger">
                                            First name is required
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <input id="last-name" type="text" placeholder="Last Name"
                                            formControlName="lastName" required />
                                        <div *ngIf="patientForm.get('lastName').invalid && (patientForm.get('lastName').dirty && patientForm.get('lastName').touched)"
                                            class="mt-1 text-danger">
                                            Last name is required
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-4">
                                    <div class="col-6">
                                        <div>
                                            <mat-select class="form-control mb-0" formControlName="gender" id="gender"
                                                required>
                                                <mat-option value="" disabled selected>Gender</mat-option>
                                                <mat-option value="M">Male</mat-option>
                                                <mat-option value="F">Female</mat-option>
                                                <mat-option value="NB">Non-Binary</mat-option>
                                                <mat-option value="NA">Decline To Answer</mat-option>
                                                <mat-option value="UKN">Unknown</mat-option>
                                            </mat-select>
                                            <span class="select-highlight"></span>
                                            <span class="select-bar"></span>
                                            <div *ngIf="patientForm.get('gender').invalid && (patientForm.get('gender').dirty || patientForm.get('gender').touched)"
                                                class="mt-1 text-danger">
                                                Gender is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="input-group">
                                            <input type="number" placeholder="Age" min="17" max="120" maxlength="3"
                                                formControlName="age" id="age" required />
                                        </div>
                                        <div *ngIf="patientForm.get('age').invalid && (patientForm.get('age').dirty && patientForm.get('age').touched)"
                                            class="mt-1 text-danger">
                                            Age is required and must be between 17-120
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-4">
                                    <div class="col-6">
                                        <input type="email" placeholder="Email (optional)" formControlName="emailAddress"
                                            id="emailAddress" />
                                        <div *ngIf="patientForm.get('emailAddress').invalid && (patientForm.get('emailAddress').dirty && patientForm.get('emailAddress').touched)"
                                            class="mt-1 text-danger">
                                            A valid email address is required<br>
                                            e.g. youremail@email.com
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <input type="tel" placeholder="Phone Number (optional)" formControlName="phoneNumber"
                                            id="phone-number" maxLength="12" />
                                        <div *ngIf="patientForm.get('phoneNumber').invalid && (patientForm.get('phoneNumber').dirty && patientForm.get('phoneNumber').touched)"
                                            class="mt-1 text-danger">
                                            A valid phone number is required <br>
                                            e.g. 555-555-5555
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-4">
                                    <!-- External/Alt. Id -->
                                    <div class="col-12">
                                        <input type="text" placeholder="Alt. Patient ID (optional)" formControlName="externalId"
                                            id="externalId" />
                                        <div *ngIf="patientForm.get('externalId').invalid && (patientForm.get('externalId').dirty && patientForm.get('externalId').touched)"
                                            class="mt-1 text-danger">
                                            Invalid alt. patient id;<br>
                                            Please use only letters, numbers, hyphens, and underscores
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-4">
                                    <div class="col-7">
                                        <label>Receive Email Reminders For This Patient</label>
                                        <div class="tag-cloud" *ngIf="assignedClinicians?.length > 0">
                                            <ul>
                                                <li *ngFor="let clinician of assignedClinicians">
                                                    <a>{{ clinician.display }}</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <button class="btn btn-primary" *ngIf="!isSubscribed()"
                                            (click)="subscribeToPatient()">
                                            Opt In
                                        </button>
                                        <button class="btn btn-primary" *ngIf="isSubscribed()"
                                            (click)="unsubscribeFromPatient()">
                                            Opt Out
                                        </button>
                                    </div>
                                </div>
                                <div class="row mb-4">
                                    <div class="col-6">
                                        <label for="height">Height</label>
                                        <div class="form-horizontal">
                                            <div class="form-group">
                                                <input type="number" maxlength="1" formControlName="feet" required
                                                    id="feet" step="1" class="col-3 height" />
                                                ft
                                                <input type="number" maxlength="2" formControlName="inches" id="inches"
                                                    required step="1" class="col-3 height" />
                                                in
                                                <div *ngIf="patientForm.get('feet').invalid && (patientForm.get('feet').dirty && patientForm.get('feet').touched)"
                                                    class="mt-1 text-danger">
                                                    Height must be between 3ft - 7ft
                                                </div>
                                                <div *ngIf="patientForm.get('inches').invalid && (patientForm.get('inches').dirty && patientForm.get('inches').touched)"
                                                    class="mt-1 text-danger">
                                                    Inches must be between 0 - 11in
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <label for="weight">Weight in lbs</label>
                                        <input type="number" formControlName="weight" id="weight" required min="50"
                                            max="500" step="1" />
                                        <div *ngIf="patientForm.get('weight').invalid && (patientForm.get('weight').dirty && patientForm.get('weight').touched)"
                                            class="mt-1 text-danger">
                                            Weight must be between 50 lbs and 500 lbs
                                        </div>
                                    </div>
                                </div>
                                <!-- Hide this block for now; the default value of Unknowkn is set in manage-patient-dialog.component.ts -->
                                <div class="row mb-4" *ngIf="false">
                                    <div class="col-12">
                                        <label>Race(s)</label>
                                        <mat-select formControlName="race" multiple id="race"
                                            (selectionChange)="clearSelection(patientForm.get('race').value)"
                                            required>
                                            <mat-option *ngFor="let race of races"
                                                [disabled]="patientForm.get('race').value !== null && (patientForm.get('race').value.includes('Unknown') || patientForm.get('race').value.includes('Prefer Not to Answer'))"
                                                [value]="race" multiple="multiple">{{ race }}</mat-option>
                                            <mat-option value="Prefer Not to Answer"
                                                [disabled]="patientForm.get('race').value !== null && (patientForm.get('race').value.includes('Unknown'))">
                                                Prefer Not to Answer</mat-option>
                                            <mat-option value="Unknown"
                                                [disabled]="patientForm.get('race').value !== null && (patientForm.get('race').value.includes('Prefer Not to Answer'))">
                                                Unknown</mat-option>
                                        </mat-select>
                                        <div *ngIf="patientForm.get('race').invalid && (patientForm.get('race').dirty && patientForm.get('race').touched)"
                                            class="mt-1 text-danger">
                                            Please select race(s)
                                        </div>
                                    </div>
                                </div>
                                <!-- Hide this block for now; the default value of Unknowkn is set in manage-patient-dialog.component.ts -->
                                <div class="row mb-4" *ngIf="false">
                                    <div class="col-12">
                                        <label for="ethnicity">Ethnicity</label>
                                        <mat-select class="form-control" formControlName="ethnicity" class="mb-0"
                                            id="ethnicity" required>
                                            <mat-option *ngFor="let ethnicity of ethnicities" [value]="ethnicity">
                                                {{ ethnicity }}</mat-option>
                                        </mat-select>
                                        <div *ngIf="patientForm.get('ethnicity').invalid && (patientForm.get('ethnicity').dirty || patientForm.get('ethnicity').touched)"
                                            class="mt-1 text-danger">
                                            Please select ethnicity
                                        </div>
                                    </div>
                                </div>
                                <!-- Hide this block for now; the default value of Unknowkn is set in manage-patient-dialog.component.ts -->
                                <div class="row mb-4" *ngIf="false">
                                    <div class="col-12">
                                        <label for="maritalStatus">Marital Status</label>
                                        <mat-select class="form-control" id="marital-status" class="mb-0"
                                            formControlName="maritalStatus" required>
                                            <mat-option *ngFor="let status of maritalStatuses" [value]="status"
                                                appearance="fill">{{ status }}</mat-option>
                                        </mat-select>
                                        <div *ngIf="patientForm.get('maritalStatus').invalid && (patientForm.get('maritalStatus').dirty || patientForm.get('maritalStatus').touched)"
                                            class="mt-1 text-danger">
                                            Please select maritial status
                                        </div>
                                    </div>
                                </div>
                                <!-- Hide this block for now; the default value of Unknowkn is set in manage-patient-dialog.component.ts -->
                                <div class="row mb-4" *ngIf="false">
                                    <div class="col-6">
                                        <label for="militaryStatus">Military Status</label>
                                        <mat-select class="form-control mb-0" id="military-status"
                                            formControlName="militaryStatus" required
                                            (selectionChange)="clearSelection(patientForm.get('militaryStatus').value); validateMilitaryStatus();">
                                            <mat-option *ngFor="let status of militaryStatuses" [value]="status">
                                                {{ status }}</mat-option>
                                        </mat-select>
                                        <div *ngIf="patientForm.get('militaryStatus').invalid && (patientForm.get('militaryStatus').dirty || patientForm.get('militaryStatus').touched)"
                                            class="mt-1 text-danger">
                                            Please select military status
                                        </div>
                                    </div>
                                    <div class="col-6"
                                        [hidden]="patientForm.get('militaryStatus').invalid || patientForm.get('militaryStatus').value == 'Not Applicable' || patientForm.get('militaryStatus').value == 'Unknown'">
                                        <label for="branch">Branch</label>
                                        <mat-select class="form-control mb-0" id="branch" formControlName="branch">
                                            <mat-option *ngFor="let branch of branches" [value]="branch">{{ branch }}
                                            </mat-option>
                                        </mat-select>
                                        <div *ngIf="patientForm.get('branch').invalid && (patientForm.get('branch').dirty || patientForm.get('branch').touched)"
                                            class="mt-1 text-danger">
                                            Please select branch
                                        </div>
                                    </div>
                                </div>
                                <!-- Hide this block for now; the default value of Unknowkn is set in manage-patient-dialog.component.ts -->
                                <div class="row mb-4" *ngIf="false">
                                    [hidden]="patientForm.get('militaryStatus').invalid || patientForm.get('militaryStatus').value == 'Not Applicable' || patientForm.get('militaryStatus').value == 'Unknown'">
                                    <div class="col-6">
                                        <label for="rank">Rank</label>
                                        <mat-select class="form-control mb-0" id="rank" formControlName="rank">
                                            <mat-option *ngFor="let rank of ranks" [value]="rank">{{ rank }}
                                            </mat-option>
                                        </mat-select>
                                        <div *ngIf="patientForm.get('rank').invalid && (patientForm.get('rank').dirty || patientForm.get('rank').touched)"
                                            class="mt-1 text-danger">
                                            Please select rank
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="isExistingUser || cliniciansGroups.length > 1" class="row mb-4">
                                    <div class="col-5">
                                        <label for="groups">Group</label>
                                        <mat-select class="form-control mb-1" formControlName="groups" id="groups">
                                            <mat-option *ngFor="let group of cliniciansGroups" [value]="group.id">
                                                {{ group.display }} </mat-option>
                                        </mat-select>
                                        <button class="btn btn-primary" (click)="addGroup(cliniciansGroups, patientForm.get('groups').value)"> Add Group </button>
                                        <div *ngIf="(patientForm.get('groups').invalid && (patientForm.get('groups').dirty || patientForm.get('groups').touched) || (patientForm.get('groups').valid && userGroups.length < 1 && displayErrorMessage)) && !isExistingUser"
                                            class="mt-1 mb-1 text-danger">
                                            A patient must have at least 1 group
                                        </div>
                                        <div class="mt-1 mb-1 text-danger" *ngIf="userGroups.length < 1 && isExistingUser" >
                                            A patient must have at least 1 group
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <label>Current Groups</label>
                                        <div class="tag-cloud" *ngIf="userGroups.length > 0">
                                            <ul>
                                                <li *ngFor="let group of userGroups">
                                                    <a (click)="deleteGroup(group.id)">{{ group.display }}</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <!-- Diagnostic code(s) -->
                                <div class="row mb-4">
                                    <div class="col-12">
                                        <label>Diagnostic Code(s)</label>
                                        <mat-select formControlName="diagnosticCodes" multiple id="diagnosticCodes"
                                            (selectionChange)="clearSelection(patientForm.get('diagnosticCodes').value)" required>
                                            <mat-option value="N/A">N/A
                                            </mat-option>
                                            <mat-option *ngFor="let dc of diagnosticCodes"
                                                [disabled]="patientForm.get('diagnosticCodes').value !== null && patientForm.get('diagnosticCodes').value.includes('N/A')"
                                                [value]="dc" multiple="multiple">{{ dc }}</mat-option>
                                        </mat-select>
                                        <div *ngIf="patientForm.get('diagnosticCodes').invalid && (patientForm.get('diagnosticCodes').dirty && patientForm.get('diagnosticCodes').touched)"
                                            class="mt-1 text-danger">
                                            Please select diagnostic code(s)
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-4" *ngIf="isExistingUser">
                                    <div class="col-12 medsInput">
                                        <div [formGroup]="medForm" class="medSelect form-group">
                                            <label for="newMed">Concurrent Treatment(s)</label>
                                            <mat-select class="form-control"  id="newMed" formControlName="newMed">
                                                <ng-container *ngFor="let thisMed of state.medicineList" >
                                                    <mat-option *ngIf="!this.prescribedMedications.includes(thisMed.name) || thisMed.name == 'Other'" [value]="thisMed.id">
                                                        {{ thisMed.name }}</mat-option>
                                                </ng-container>
                                            </mat-select>
                                            <input type="text" placeholder="Dose" id="newMedDose" formControlName="newMedDose" />
                                            <input type="text" placeholder="Description" id="newMedDesc" formControlName="newMedDesc" />
                                            <button
                                                class="btn btn-primary"
                                                (click)="
                                                    patientStateService.addPrescribedMed(
                                                    medForm.get('newMedDose').value,
                                                    medForm.get('newMedDesc').value,
                                                    medForm.get('newMed').value);
                                                    addPrescribedMed(medForm.get('newMed').value);
                                                    medForm.get('newMedDose').setValue('');
                                                    medForm.get('newMedDesc').setValue('')"
                                                [disabled]="medForm.get('newMed').value === null">
                                                <i class="fas fa-plus-square"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-12 medsTable">
                                        <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Concurrent Treatment</th>
                                                    <th scope="col">Dose</th>
                                                    <th scope="col">Description</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let prescribedMed of state.prescribedMedicineList">
                                                    <th scope="row">{{ prescribedMed.medicine.display }}</th>
                                                    <td>{{ prescribedMed.dose }}</td>
                                                    <td>{{ prescribedMed.instruction }}</td>
                                                    <td>
                                                        <button class="btn btn-primary"
                                                            (click)="patientStateService.delPrescribedMed(prescribedMed.__i.guid); removePrescribedMed(prescribedMed)">
                                                            X
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="row mb-4" *ngIf="isExistingUser">
                                    <div class="col-6">
                                        <label>Is Active</label>
                                        <mat-select class="form-control" id="isActive" formControlName="isActive">
                                            <mat-option
                                                *ngFor="let b of [{display:'Yes', value: true}, {display:'No', value: false}]"
                                                [value]="b.value">{{ b.display }}</mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="col-6" *ngIf="patientForm.get('isActive').value === false">
                                        <label for="disabledReason">Deactivate Reason</label>
                                        <mat-select class="form-control" id="disabledReason" formControlName="disabledReason">
                                            <mat-option *ngFor="let b of disabledReasons" [value]="b">{{ b }}
                                            </mat-option>
                                        </mat-select>
                                        <div class="text-danger"
                                            *ngIf="patientForm.get('disabledReason').invalid && (patientForm.get('disabledReason').dirty || patientForm.get('disabledReason').touched) && patientForm.get('isActive').value === 'false'">
                                            Deactivate Reason is required!
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="row mb-4" *ngIf="isExistingUser">
                                    <div class="col-12">
                                        <label>Access Code</label>
                                        <h2>{{ state.patientInfo.pin }}</h2>
                                        <p class="caseSens">
                                            Case Sensitive
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </mat-tab>
                <mat-tab label="Emergency Contacts">
                    <div class="container modal-body">
                        <div class="row mt-3">
                            <div [formGroup]="EC1Form" class="col-9">
                                <h4>Emergency Contact 1</h4>
                                <div class="row mt-2">
                                    <div class="col-6">
                                        <input type="text" placeholder="First Name" id="firstName"
                                            formControlName="firstName" />
                                    </div>
                                    <div class="col-6">
                                        <input type="text" placeholder="Last Name" id="lastName"
                                            formControlName="lastName" />
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-6">
                                        <input type="text" placeholder="Email or Phone" id="email"
                                            formControlName="email" />
                                    </div>
                                    <div class="col-6">
                                        <input type="text" placeholder="Relation" id="relation"
                                            formControlName="relation" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div [formGroup]="EC2Form" class="col-9">
                                <h4>Emergency Contact 2</h4>
                                <div class="row mt-2">

                                    <div class="col-6">
                                        <input type="text" placeholder="First Name" id="firstName"
                                            formControlName="firstName" />
                                    </div>
                                    <div class="col-6">
                                        <input type="text" placeholder="Last Name" id="lastName"
                                            formControlName="lastName" />
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-6">
                                        <input type="text" placeholder="Email or Phone" id="email"
                                            formControlName="email" />
                                    </div>
                                    <div class="col-6">
                                        <input type="text" placeholder="Relation" id="relation"
                                            formControlName="relation" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngFor="let provider of healthProviders; let i = index" class="row mt-5">
                            <div class="col-9">
                                <div style="display: flex; justify-content: space-between;">
                                    <h4 *ngIf="i == 0">Treating Physician / Referral Source</h4>
                                    <h4 *ngIf="i > 0">Treating Physician / Referral Source {{ i + 1}}</h4>
                                    <button *ngIf="i > 0" mat-button class="close btn-close pull-right"
                                        (click)="removeProvider(i)">X</button>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-6">
                                        <input type="text" placeholder="First Name"
                                            [(ngModel)]="provider.HpFirstName" />
                                    </div>
                                    <div class="col-6">
                                        <input type="text" placeholder="Last Name" [(ngModel)]="provider.HpLastName" />
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-6">
                                        <input type="text" placeholder="Email or Phone"
                                            [(ngModel)]="provider.HpEmail" />
                                    </div>
                                    <div class="col-6">
                                        <input type="text" placeholder=" Facility / Organization"
                                            [(ngModel)]="provider.HpOrg" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button class="mt-5 btn btn-primary" (click)="addNewProvider()">Add Additional Provider</button>
                    </div>
                </mat-tab>
            </div>
        </mat-tab-group>
    </div>
    <div class="modal-footer">
        <div *ngIf="((!patientForm.valid || !userGroups.length) || (userGroups.length < 1 && isExistingUser)) && displayErrorMessage" class="mt-4 text-danger">
            Please correct errors indicated above
        </div>
        <div mat-dialog-actions>
            <button mat-button class="btn btn-secondary" [disabled]="isLoading" (click)="onClose()">Close</button>
            <button *ngIf="!isExistingUser" mat-button class="btn btn-primary" [disabled]="isLoading" (click)="onSubmit()"> Submit </button>
            <button *ngIf="isExistingUser" mat-button class="btn btn-primary" (click)="onSubmit()">Update</button>
        </div>
    </div>
</div>
