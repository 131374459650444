import { AssetDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';
import { controlJetLag } from '../questions/control-jet-lag/control-jet-lag';

export const controlJetLagDef: AssetDefinition = {
  id: 2,
  name: 'control-jet-lag',
  label: 'Control Jet Lag',
  description: `Our bodies take about 1 day per time zone travelled to fully adjust to local time.
                Help your Soldiers control jet lag by following tips for optimal exposure to light and timing of caffeine and naps.`,
  categories: ['all', 'sleep-and-fatigue'],
  showInLibrary: true,
  readOnly: false,
  assessments: [
    controlJetLag
  ],
  url: 'control-jet-lag'
};
