import * as _ from 'lodash';
import { Injectable, OnDestroy, Inject } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';
import { AuthService } from './auth.service';
import { UserState, IUserStateService, ANON_TOKEN } from './contracts';
import { Subscription, Subject, Observable, forkJoin, timer } from 'rxjs';
import { takeUntil, tap, mapTo, filter, map, take } from 'rxjs/operators';
import { UserService } from './user.service';
import { NetworkService } from '../../../lib/services/network-service';
import { UserSettings, Role, Organization } from '../../../core/contracts/models';
import { ModelFactory, Model, ApplicationContext } from '../../../core/web-ng';

@Injectable()
export abstract class BaseUserStateService<T extends UserSettings, TState extends UserState<T>> implements IUserStateService, Resolve<boolean>, OnDestroy {
    protected destroy$: Subject<boolean> = new Subject<boolean>();
    public onUserLoggedOut$: Subject<boolean> = new Subject();
    public onUserLoaded$: Subject<TState> = new Subject();
    public onError$: Subject<TState> = new Subject();
    public onEULACompleted$:Subject<boolean> = new Subject();
    private validateTokenTimerSeconds = 30;
    private isValidateTokenRunning = false;
    private timerSubscription: Subscription;
    private lastUser: T;
    private lastSubscription: Subscription;
    private lastUpdate: Date = null;
    public model: Model<TState>;
    state$: Observable<TState>;
    protected get defaultState(): TState {
        return {} as TState;
    }
    constructor(protected userService: UserService<T>, @Inject(ANON_TOKEN) private anonToken: string,
                protected authService: AuthService, private applicationContext: ApplicationContext,
                private modelFactory: ModelFactory<TState>, protected networkService: NetworkService,
                private router: Router
                ) {
        if (!localStorage.getItem('token')) {
            localStorage.setItem('token', anonToken);
        }

        this.model = this.modelFactory.create(this.defaultState);

        this.requestUser();
    }

    public requestUser(): Observable<TState> {
        return this.state$ = this.model.data$.pipe(takeUntil(this.destroy$), map(state => {
            if (!state.IsLoggedIn) {
                this.isValidateTokenRunning = false;
            }
            return state;
        }));
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        // Now let's also unsubscribe from the subject itself:
        this.destroy$.unsubscribe();
    }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.resolveCurrentUser().pipe(tap(data => {
            const userState = this.model.get();
            userState.User = data.User;
            userState.Roles = data.Roles;
            userState.IsLoading = false;
            userState.IsLoggedIn = !!userState.User;
            this.setModel(userState);
        }), takeUntil(this.destroy$), mapTo(true));
    }
    public initialize() {
        const token = localStorage.getItem('token');
        this.setToken(token);
    }
    public setToken(token: string) {
        const state = this.model.get();
        state.Token = token ? token : this.anonToken;
        if (state.Token === this.anonToken) {
            state.IsLoggedIn = false;
        }
        this.setModel(state);
        if (state.Token && !state.User) {
            this.getCurrentUser();
        }
    }
    public expire(): void {
        let state = this.model.get();
        // state.IsUserInitialized = false;
        // NEVER expire the anon token
        if (this.anonToken && (state.Token === this.anonToken)) {
            return;
        }

        this.authService.logout(state.Token).subscribe(res => {
            if (res) {
                state = this.defaultState;
                state.IsLoggedIn = false;
                state.Token = this.anonToken;
                this.setModel(state);
                localStorage.setItem('token', this.anonToken);
                this.onUserLoggedOut$.next(true);
            }
        })
    }
    public authenticate(username: string, password: string, clientId: string = 'sys', isCustomer = true): Promise<any> {
        const state = this.model.get();
        state.Errors = null;
        state.IsLoading = true;
        this.setModel(state);
        // If we're on a device, authenticate through the device
        // Otherwise authenticate directly
        const authPromise = this.authService.authenticate(username, password, clientId, isCustomer).toPromise();
        return authPromise.then(response => {
            let token = '';
            if (response.success) {
                this.applicationContext.Token = response.Token;
                state.Token = response.Token;
                token = response.Token;
                localStorage.setItem('token', token);
                this.setModel(state);
                this.getCurrentUser();
            } else {
                state.IsLoading = false;
                state.Errors = response.messages ? response.messages.join(', ') : '';
                state.IsLoggedIn = false;
                this.setModel(state);
                this.onError$.next(state);
                // this.expire();
                // this.appStateService.logout();
            }
        }, err => {
            state.IsLoading = false;
            state.Errors = err;
            this.setModel(state);
            this.onError$.next(state);
        });
    }
    public updatePassword(currentPassword: string, newPassword: string, confirmPassword: string) {
        const state = this.model.get();
        state.ChangePasswordState = { Errors: null, HasPasswordChanged: false };
        if (newPassword !== confirmPassword) {
            state.ChangePasswordState.Errors = 'Passwords must match';
            this.model.set(state);
            return;
        }
        state.IsLoading = true;
        this.model.set(state);
        this.authService.updatePassword(state.Token, currentPassword, newPassword).toPromise().then(response => {
            if (response.success) {
                state.IsLoading = false;
                state.ChangePasswordState = { Errors: null, HasPasswordChanged: true };
                this.model.set(state);
            } else {
                state.IsLoading = false;
                state.ChangePasswordState = { Errors: response.messages.join(', '), HasPasswordChanged: false };
                this.model.set(state);
            }
        });
    }
    public getCurrentUser(): void {
        // Note: this causes an ExpressionChangedAfterItHasBeenChecked error given the authenticate function (eventually) setting authPromise.then.state.isLoading. Need to rewrite or track multiple isLoading variations.
        const state = this.model.get();
        state.IsLoading = true;
        this.setModel(state);
        // We have 2 tokens basically going around.  The user state one, nd the one the
        // auth service got from app context
        if (state.Token) {
            this.resolveCurrentUser()
                .toPromise()
                .then(userInfo => {
                    if (userInfo) {
                        state.User = userInfo.User;
                        state.Roles = userInfo.Roles;
                        state.Organizations = userInfo.Organizations;
                        state.IsLoggedIn = true;
                        this.lastUpdate = new Date();
                        this.applicationContext.User = userInfo.User;
                        if (state.Token && !this.isValidateTokenRunning) {
                            this.isValidateTokenRunning = true;
                            // Begin verifying the token every n seconds
                            this.timerSubscription = timer(0, this.validateTokenTimerSeconds * 1000).pipe(takeUntil(this.state$.pipe(filter(s => !s.IsLoggedIn)))).subscribe(timer => {
                              this.networkService.initialize().then(() => {
                                if (this.networkService.isOnline()) {
                                    this.authService.validate(state.Token).pipe(take(1)).subscribe(isValid => {
                                        // NOTE: this functionality ws moved to the HTTP interceptor.
                                        // if (!isValid) {
                                        //     const latestState = this.model.get();
                                        //     latestState.IsLoggedIn = false;
                                        //     latestState.User = null;
                                        //     latestState.Token = this.anonToken;
                                        //     this.setModel(latestState);
                                        //     this.applicationContext.User = userInfo.User;
                                        //     this.expire();
                                        //     if(this.router.url !== '/login') {
                                        //       this.router.navigateByUrl('/login').then(() => {
                                        //           setTimeout(() => { alert('Your session has expired. Please login again.'); }, 200);
                                        //       });
                                        //     }
                                        // }
                                    });
                                }
                              });
                            });
                        }
                    } else {
                        if (this.timerSubscription) {
                            this.timerSubscription.unsubscribe();
                        }
                        state.IsLoggedIn = false;
                        localStorage.setItem('token', this.anonToken);
                    }
                    state.IsUserInitialized = true;
                    state.IsLoading = false;
                    this.onUserLoaded$.next(state);

                    this.setModel(state);
                }, err => {
                    {
                        state.IsLoggedIn = false;
                        // TODO: this is poorly named.  Maybe IsInitialized?
                        state.IsUserInitialized = true;
                        state.IsLoading = false;
                        this.setModel(state);
                        this.onUserLoaded$.next(state);


                    }
                });
        }
    }
    public setCurrentUser(user: T) {
        const state = this.model.get();
        state.User = user;
        if (!state.User) {
            this.expire();
            state.Token = null;
        }
        this.setModel(state);
    }
    public save(user: T): Observable<T> {
        const state = this.model.get();
        state.IsLoading = true;
        this.setModel(state);
        return this.userService.save(user as any).pipe(map(userResponse => {
            this.lastUpdate = new Date();
            state.User = userResponse;
            // TODO: revisit
            // This is tripping up the diff below
            delete state.User.__i.auditing;
            delete (state.User.__i as any).acl;
            delete state.User.__i.tags;
            delete state.User.__i.model.version;
            this.setModel(state);
            return user;
        }));
    }
    public updateNotificationToken(token: string): Observable<T> {
        return this.userService.updateNotificationsToken(token);
    }
    private resolveCurrentUser(): Observable<{
        User: T;
        Roles: Role[];
        Organizations: Organization[]
    }> {
        return this.userService.getCurrentUserInfo();
    }
    protected setModel(state: TState, force?: boolean) {
        if (!state.User || force) {
            state.IsLoggedIn = !!state.User;
            this.lastUser = state.User;
            this.model.set(state);
            return;
        }
        if (this.lastUser && state.User) {
            // We also need to load if token changed
            // For debugging diff this.difference(state.User, this.lastUser);
            const isSame = _.isEqual(state.User, this.lastUser);
            if (isSame) {
                return;
            }
        }
        this.lastUser = state.User;
        state = this.updateModel(state);
        this.model.set(state);
    }
    protected updateModel(state: TState): TState {
        return state;
    }
    private difference(object, base) {
        function changes(object1, base1) {
            return _.transform(object1, function(result, value, key) {
                if (!_.isEqual(value, base1[key])) {
                    result[key] = (_.isObject(value) && _.isObject(base1[key])) ? changes(value, base1[key]) : value;
                }
            });
        }
        return changes(object, base);
    }
}
