import { AssessmentDefinition } from '../../../../../noctem-lib/src/core/contracts/models.calculations';


export const quickstart: AssessmentDefinition = {
  name: 'quickstart',
  description: 'Quickstart',
  instructions: null,
  questions: [
    {
      id: 0,
      questionText: '',
      questionEmphasis: ``,
      subText: `<h5>Quick Start Guide </h5>

      <p>Welcome to the AIRE Soldier App!</p>

      <p>This guide will support your use of the AIRE Soldier tool to assist you in optimizing performance.</p>

      <p>3 Actions for Successful Use of the App:</br>1. Complete Check-ins</br>2. Read Modules and practice recommendations</br>3. Track Your Stats</p>

      <p>Continue to read about each action.</p>
      `,
      questionType: 'module',

      uniqueAnswerId: 'QS_1',
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: true
    },
    {
        id: 1,
        questionText: '',
        questionEmphasis: ``,
        subText: `<h5>Quick Start Guide</h5>

        <h5><b>Action #1: Complete Check-ins</b></h5>
        <h5><b>WHAT?</b></h5>

        <p>Check-ins are a group of questionnaires that take around 2-3 minutes to complete each time. They will ask about your sleep, stress levels, and team communication.</p>

        <h5><b>HOW OFTEN?</b></h5>
        <p>At least one daily.</p>

        <h5><b>WHERE?</b></h5>
        <p>At the top of the to-do list in blue.</p>

        <h5><b>WHY?</b></h5>
        <p>Information you provide in the check-ins helps the Soldier App and your supervisor make recommendations to boost your performance.</p>
        `,
        questionType: 'module',

        uniqueAnswerId: 'QS_2',
        autofill: false,
        answerOptions: null,
        conditionalQuestions: [],
        isCheckInQuestion: true
    },
    {
        id: 2,
        questionText: '',
        questionEmphasis: ``,
        subText: `<h5>Quick Start Guide</h5>

        <h5><b>Action #2: Read Modules and Practice Recommendations</b></h5>
        <h5><b>WHAT?</b></h5>

        <p>Modules are brief explanations of performance improvement techniques. You will read the module and then practice the technique described.</p>

        <h5><b>HOW OFTEN?</b></h5>
        <p>Varies. The number and frequency in which you receive modules will be based on your check-in responses.</p>

        <h5><b>WHERE?</b></h5>
        <p>All recommended modules will appear on the bottom half of your to do list. Modules recommended by your supervisor will have a plus sign. Modules recommended by the Soldier App will have an “A” icon.</p>

        <h5><b>WHY?</b></h5>
        <p>Each module is designed to assist you in boosting performance and readiness.</p>
        `,
        questionType: 'module',

        uniqueAnswerId: 'QS_3',
        autofill: false,
        answerOptions: null,
        conditionalQuestions: [],
        isCheckInQuestion: true
    },
    {
        id: 3,
        questionText: '',
        questionEmphasis: ``,
        subText: `<h5>Quick Start Guide</h5>

        <h5><b>Action #3: Track Your Stats</b></h5>
        <h5><b>WHAT?</b></h5>

        <p>Your stats are graph-based summaries for sleep, stress, and teamwork. Data is based on your check-in responses.</p>

        <h5><b>HOW OFTEN?</b></h5>
        <p>The frequency in which you review your stats is up to you.</p>

        <h5><b>WHERE?</b></h5>
        <p>All your stats are located in the Stats page on the app. Tap on sleep, stress, or team to view your personal ratings over time.</p>

        <h5><b>WHY?</b></h5>
        <p>View your changes over time on sleep, stress, and teamwork to track improvements and lapses.</p>
        `,
        questionType: 'module',

        uniqueAnswerId: 'QS_4',
        autofill: false,
        answerOptions: null,
        conditionalQuestions: [],
        isCheckInQuestion: true
    },
    {
        id: 4,
        questionText: '',
        questionEmphasis: ``,
        subText: `<h5>Quick Start Guide</h5>

        <h5><b>How is information sent to my supervisor?</b></h5>

        <p>Your app’s connectivity status is displayed at the top right of your app.</p>

        <p>If in online mode, your information is sent through a secure cloud to your supervisor's AIRE App.</p>
        `,
        questionType: 'module',

        uniqueAnswerId: null,
        autofill: false,
        answerOptions: null,
        conditionalQuestions: [],
        isCheckInQuestion: true
    },
    {
        id: 5,
        questionText: '',
        questionEmphasis: ``,
        subText: `<h5>Quick Start Guide</h5>

        <h5><b>Questions?</b></h5>

        <p>For questions about navigating the app or if you encounter any bugs or errors, use the report a bug feature found in the menu.</p>

        <p>For questions about implementing any of the modules, please reach out to your supervisor.</p>

        <p>To learn more about sleep, stress, or teamwork, check out modules in the Learn page of your app.</p>
        `,
        questionType: 'module',

        uniqueAnswerId: 'QS_5',
        autofill: false,
        answerOptions: null,
        conditionalQuestions: [],
        isCheckInQuestion: true
    },
  ]
};
