<div class="form-group mb-0" [formGroup]="timepickerFormGroup" *ngIf="timepickerFormGroup">
  <label class="mb-0">
      {{labelText}}
    <input
      type="text"
      class="form-control m-0 p-0 text-center"
      formControlName="timepickerControl"
      placeholder="{{inputPlaceholder}}"
      maxlength="{{inputLength}}"
      minlength="{{inputLength}}"
      [(ngModel)]="value"
    />
  </label>

  <div class="text-danger mb-0 mt-1" *ngIf="timePickerControl.errors?.format && (timePickerControl.dirty && timePickerControl.touched)">
    Please use "{{inputPlaceholder}}" format.
  </div>

  <div class="text-danger mb-0 mt-1" *ngIf="timePickerControl.errors?.required && (timePickerControl.dirty && timePickerControl.touched)">
    Required
  </div>
</div>
