import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { BaseStateService } from './base-state.service';
import { CalendarEventService } from '../services/services';
import { CalendarEvent, NoctemUser, SimpleInstance, UserSimpleInstance } from '../services/models';
import { getAllActionTypes } from '../services';
import { ModelFactory } from '../../core/web-ng';
import { TranslationService } from '../translations';

export class CalendarState {
    public IsLoading = false;
    events: any = [];
    patientList: UserSimpleInstance[] = [];
    isViewAll = false;
    selectedPatient: any;
    userActions;
    allActions;
    startDate: any;
    endDate: any;
}

@Injectable({
    providedIn: 'root'
})
export class CalendarStateService extends BaseStateService<CalendarState> {
    public onLoadingChange$: Subject<boolean> = new Subject();
    public onEventsLoaded$: Subject<any[]> = new Subject();
    public onPatientsLoaded$: Subject<any[]> = new Subject();

    constructor(
        stateFactory: ModelFactory<CalendarState>,
        public eventService: CalendarEventService,
        public translationService: TranslationService
    ) {
        super(new CalendarState(), stateFactory, translationService);
    }

    public initialize() {
        // this.getEvents(40493);
    }

    public setIsLoading(isLoading: boolean) {
        const state = this.stateModel.get();
        state.IsLoading = isLoading;
        this.stateModel.set(state);
    }

    public getAllEvents(start?, end?) {
        this.getAndTransformCalendarEvents(start, end);
    }

    public getEvents(patient?: any, start?, end?) {
        this.getAndTransformCalendarEvents(start, end, patient);
    }

    private getAndTransformCalendarEvents(start?, end?, patient?: UserSimpleInstance) {
        this.setIsLoading(true);
        const state = this.stateModel.get();
        state.startDate = start;
        state.endDate = end;
        state.isViewAll = false;
        if (!!patient) {
            state.selectedPatient = patient;
            this.getActions(patient.id);
        } else {
            state.selectedPatient = null;

        }

        // TODO: use start and end dates
        // this.apiService.getEvent(start, end, patient.patientId)
        const andConditions: any[] = [
            {'Payload.phaseName' : { $ne : 0 }
        }];
        if (patient) {
            andConditions.push({ 'Payload.user.id': patient.id });
        }

        if (start) {
            andConditions.push({ 'Payload.startDate': { $gte: new Date(start).toISOString() } });
        }
        if (end) {
            andConditions.push(
                {
                    $or: [
                        { 'Payload.endDate': null },
                        { 'Payload.endDate': { $lte: new Date(end).toISOString() } }
                    ]
                });

        }
        const query = andConditions.length > 0 ? {
            $and: andConditions
        } : {};
        this.eventService.getAll(query, 999).subscribe(res => {
            state.events = [];
            this.stateModel.set(state);
            for (const event of res) {
                const eventInstance = this.transformCalendarEvent(event);
                state.events.push(eventInstance);
            }
            this.stateModel.set(state);
            this.onEventsLoaded$.next(state.events);
            this.setIsLoading(false);
        });
    }

    private transformCalendarEvent(event: CalendarEvent) {
        return {
            title: event.title,
            start: new Date(event.startDate),
            end: new Date(event.endDate),
            backgroundColor: event.color ? event.color : '',
            borderColor: event.color ? event.color : '',
            owner: event.user,
            id: event.id,
            repeatTimes: event.repeatTimes,
            repeatType: event.repeatType,
            phaseName: event.phaseName,
            event,
        };
    }



    // Patient List and Actions are universal concepts for the app.  Set from service bus
    public setPatientList(users: NoctemUser[]) {

        // TODO: this is group list.
        // We are doing this in ChatStateService as well.
        // Refactor to a common place, then set each state service that needs it on load

        const state = this.stateModel.get();
        state.patientList = users.map(u => ({ model: 'UserSettings', display: `${u.profile?.firstName} ${u.profile?.lastName}`, id: u.UserId }));
        this.stateModel.set(state);
        this.onPatientsLoaded$.next(state.patientList);
    }

    public getActions(patientId) {
        const state = this.stateModel.get();
        state.userActions = [];
        state.allActions = [];
        state.userActions.push(
            { Id: 1, ActionType: 1, Description: 'Sleep Diary' },
            { Id: 2, ActionType: 2, Description: 'Wake Up Alarm' },
            { Id: 7, ActionType: 9, Description: 'Check In' },
            { Id: 999, ActionType: 999, Description: 'Other' },
        );
        getAllActionTypes().subscribe(res => {

            // TODO: this needs fixed.  It's a collection, so we shouldn't need to append below
            // Need to update collection
            for (const action of res) {
                state.allActions.push(action);
            }
            state.allActions.push(
                { Id: 1, ActionType: 1, Description: 'Sleep Diary' },
                { Id: 2, ActionType: 2, Description: 'Wake Up Alarm' },
                { Id: 999, ActionType: 999, Description: 'Other' }
            );
            this.stateModel.set(state);
        });

    }

    public saveEvent(event: CalendarEvent) {
        const state = this.stateModel.get();
        // this.apiService.saveEvent(event).subscribe(res => {
        this.eventService.save(event).subscribe(calendarEvent => {
            if (state.isViewAll) {
                this.getAllEvents();
            } else {
                // Maintain start and end dates
                this.getEvents(state.selectedPatient, state.startDate, state.endDate);
            }
        });
    }
}
