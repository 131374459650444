export const TBIWHODAS2Def = {
  name: 'tbi-whodas2',
  description: 'World Health Organization Disability Assessment Schedule 2.0',
  instructions: '',
  /*
  Note: the questions property must be empty here (but available in the database) or this assessment will be made available for all patients

  questions: [
    {
      id: 0,
      questionText: 'In the last 30 days, how much difficulty did you have in:',
      questionEmphasis: 'Concentrating on doing something for ten minutes?',
      questionType: 'radio',
      uniqueAnswerId: 'WHODAS2_CONCENTRATING',
      autofill: false,
      answerOptions: [
        { display: 'None', value: 1 },
        { display: 'Moderate', value: 2 },
        { display: 'Mild', value: 3 },
        { display: 'Severe', value: 4 },
        { display: 'Extreme or cannot do', value: 5 }
      ],
      conditionalQuestions: []
    },
    {
      id: 1,
      questionText: 'In the last 30 days, how much difficulty did you have in:',
      questionEmphasis: 'Remembering to do important things?',
      questionType: 'radio',
      uniqueAnswerId: 'WHODAS2_REMEMBERING',
      autofill: false,
      answerOptions: [
        { display: 'None', value: 1 },
        { display: 'Moderate', value: 2 },
        { display: 'Mild', value: 3 },
        { display: 'Severe', value: 4 },
        { display: 'Extreme or cannot do', value: 5 }
      ],
      conditionalQuestions: []
    },
    {
      id: 2,
      questionText: 'In the last 30 days, how much difficulty did you have in:',
      questionEmphasis: 'Analyzing and finding solutions to problems in day-to-day life?',
      questionType: 'radio',
      uniqueAnswerId: 'WHODAS2_ANALYZING',
      autofill: false,
      answerOptions: [
        { display: 'None', value: 1 },
        { display: 'Moderate', value: 2 },
        { display: 'Mild', value: 3 },
        { display: 'Severe', value: 4 },
        { display: 'Extreme or cannot do', value: 5 }
      ],
      conditionalQuestions: []
    },
    {
      id: 3,
      questionText: 'In the last 30 days, how much difficulty did you have in:',
      questionEmphasis: 'Learning a new task, for example, learning how to get to a new place?',
      questionType: 'radio',
      uniqueAnswerId: 'WHODAS2_LEARNING',
      autofill: false,
      answerOptions: [
        { display: 'None', value: 1 },
        { display: 'Moderate', value: 2 },
        { display: 'Mild', value: 3 },
        { display: 'Severe', value: 4 },
        { display: 'Extreme or cannot do', value: 5 }
      ],
      conditionalQuestions: []
    },
    {
      id: 4,
      questionText: 'In the last 30 days, how much difficulty did you have in:',
      questionEmphasis: 'Generally understanding what people say?',
      questionType: 'radio',
      uniqueAnswerId: 'WHODAS2_UNDERSTANDING_SPEACH',
      autofill: false,
      answerOptions: [
        { display: 'None', value: 1 },
        { display: 'Moderate', value: 2 },
        { display: 'Mild', value: 3 },
        { display: 'Severe', value: 4 },
        { display: 'Extreme or cannot do', value: 5 }
      ],
      conditionalQuestions: []
    },
    {
      id: 5,
      questionText: 'In the last 30 days, how much difficulty did you have in:',
      questionEmphasis: 'Starting and maintaining a conversation?',
      questionType: 'radio',
      uniqueAnswerId: 'WHODAS2_CONVERSATION',
      autofill: false,
      answerOptions: [
        { display: 'None', value: 1 },
        { display: 'Moderate', value: 2 },
        { display: 'Mild', value: 3 },
        { display: 'Severe', value: 4 },
        { display: 'Extreme or cannot do', value: 5 }
      ],
      conditionalQuestions: []
    },
    {
      id: 6,
      questionText: 'In the last 30 days, how much difficulty did you have in:',
      questionEmphasis: 'Standing for long periods, such as 30 minutes?',
      questionType: 'radio',
      uniqueAnswerId: 'WHODAS2_STANDING_LONG',
      autofill: false,
      answerOptions: [
        { display: 'None', value: 1 },
        { display: 'Moderate', value: 2 },
        { display: 'Mild', value: 3 },
        { display: 'Severe', value: 4 },
        { display: 'Extreme or cannot do', value: 5 }
      ],
      conditionalQuestions: []
    },
    {
      id: 7,
      questionText: 'In the last 30 days, how much difficulty did you have in:',
      questionEmphasis: 'Standing up from sitting down?',
      questionType: 'radio',
      uniqueAnswerId: 'WHODAS2_STANDING_UP',
      autofill: false,
      answerOptions: [
        { display: 'None', value: 1 },
        { display: 'Moderate', value: 2 },
        { display: 'Mild', value: 3 },
        { display: 'Severe', value: 4 },
        { display: 'Extreme or cannot do', value: 5 }
      ],
      conditionalQuestions: []
    },
    {
      id: 8,
      questionText: 'In the last 30 days, how much difficulty did you have in:',
      questionEmphasis: 'Moving around inside your home?',
      questionType: 'radio',
      uniqueAnswerId: 'WHODAS2_MOVINGAROUND_INSIDE',
      autofill: false,
      answerOptions: [
        { display: 'None', value: 1 },
        { display: 'Moderate', value: 2 },
        { display: 'Mild', value: 3 },
        { display: 'Severe', value: 4 },
        { display: 'Extreme or cannot do', value: 5 }
      ],
      conditionalQuestions: []
    },
    {
      id: 9,
      questionText: 'In the last 30 days, how much difficulty did you have in:',
      questionEmphasis: 'Getting out of your home?',
      questionType: 'radio',
      uniqueAnswerId: 'WHODAS2_MOVINGAROUND_OUTSIDE',
      autofill: false,
      answerOptions: [
        { display: 'None', value: 1 },
        { display: 'Moderate', value: 2 },
        { display: 'Mild', value: 3 },
        { display: 'Severe', value: 4 },
        { display: 'Extreme or cannot do', value: 5 }
      ],
      conditionalQuestions: []
    },
    {
      id: 10,
      questionText: 'In the last 30 days, how much difficulty did you have in:',
      questionEmphasis: 'Walking a long distance, such as a kilometer (or equivalent?)',
      questionType: 'radio',
      uniqueAnswerId: 'WHODAS2_WALKING_LONG',
      autofill: false,
      answerOptions: [
        { display: 'None', value: 1 },
        { display: 'Moderate', value: 2 },
        { display: 'Mild', value: 3 },
        { display: 'Severe', value: 4 },
        { display: 'Extreme or cannot do', value: 5 }
      ],
      conditionalQuestions: []
    },
    {
      id: 11,
      questionText: 'In the last 30 days, how much difficulty did you have in:',
      questionEmphasis: 'Washing your whole body?',
      questionType: 'radio',
      uniqueAnswerId: 'WHODAS2_WASHING_BODY',
      autofill: false,
      answerOptions: [
        { display: 'None', value: 1 },
        { display: 'Moderate', value: 2 },
        { display: 'Mild', value: 3 },
        { display: 'Severe', value: 4 },
        { display: 'Extreme or cannot do', value: 5 }
      ],
      conditionalQuestions: []
    },
    {
      id: 12,
      questionText: 'In the last 30 days, how much difficulty did you have in:',
      questionEmphasis: 'Getting dressed?',
      questionType: 'radio',
      uniqueAnswerId: 'WHODAS2_DRESSING',
      autofill: false,
      answerOptions: [
        { display: 'None', value: 1 },
        { display: 'Moderate', value: 2 },
        { display: 'Mild', value: 3 },
        { display: 'Severe', value: 4 },
        { display: 'Extreme or cannot do', value: 5 }
      ],
      conditionalQuestions: []
    },
    {
      id: 13,
      questionText: 'In the last 30 days, how much difficulty did you have in:',
      questionEmphasis: 'Eating?',
      questionType: 'radio',
      uniqueAnswerId: 'WHODAS2_EATING',
      autofill: false,
      answerOptions: [
        { display: 'None', value: 1 },
        { display: 'Moderate', value: 2 },
        { display: 'Mild', value: 3 },
        { display: 'Severe', value: 4 },
        { display: 'Extreme or cannot do', value: 5 }
      ],
      conditionalQuestions: []
    },
    {
      id: 14,
      questionText: 'In the last 30 days, how much difficulty did you have in:',
      questionEmphasis: 'Staying by yourself for a few days?',
      questionType: 'radio',
      uniqueAnswerId: 'WHODAS2_BEING_ALONE',
      autofill: false,
      answerOptions: [
        { display: 'None', value: 1 },
        { display: 'Moderate', value: 2 },
        { display: 'Mild', value: 3 },
        { display: 'Severe', value: 4 },
        { display: 'Extreme or cannot do', value: 5 }
      ],
      conditionalQuestions: []
    },
    {
      id: 15,
      questionText: 'In the last 30 days, how much difficulty did you have in:',
      questionEmphasis: 'Dealing with people you do not know?',
      questionType: 'radio',
      uniqueAnswerId: 'WHODAS2_DEALING',
      autofill: false,
      answerOptions: [
        { display: 'None', value: 1 },
        { display: 'Moderate', value: 2 },
        { display: 'Mild', value: 3 },
        { display: 'Severe', value: 4 },
        { display: 'Extreme or cannot do', value: 5 }
      ],
      conditionalQuestions: []
    },
    {
      id: 16,
      questionText: 'In the last 30 days, how much difficulty did you have in:',
      questionEmphasis: 'Maintaining a friendship?',
      questionType: 'radio',
      uniqueAnswerId: 'WHODAS2_FRIENDSHIP',
      autofill: false,
      answerOptions: [
        { display: 'None', value: 1 },
        { display: 'Moderate', value: 2 },
        { display: 'Mild', value: 3 },
        { display: 'Severe', value: 4 },
        { display: 'Extreme or cannot do', value: 5 }
      ],
      conditionalQuestions: []
    },
    {
      id: 17,
      questionText: 'In the last 30 days, how much difficulty did you have in:',
      questionEmphasis: 'Getting along with people who are close to you?',
      questionType: 'radio',
      uniqueAnswerId: 'WHODAS2_GETTING_ALONG_PEOPLE_CLOSE',
      autofill: false,
      answerOptions: [
        { display: 'None', value: 1 },
        { display: 'Moderate', value: 2 },
        { display: 'Mild', value: 3 },
        { display: 'Severe', value: 4 },
        { display: 'Extreme or cannot do', value: 5 }
      ],
      conditionalQuestions: []
    },
    {
      id: 18,
      questionText: 'In the last 30 days, how much difficulty did you have in:',
      questionEmphasis: 'Making new friends?',
      questionType: 'radio',
      uniqueAnswerId: 'WHODAS2_MAKING_FRIENDS',
      autofill: false,
      answerOptions: [
        { display: 'None', value: 1 },
        { display: 'Moderate', value: 2 },
        { display: 'Mild', value: 3 },
        { display: 'Severe', value: 4 },
        { display: 'Extreme or cannot do', value: 5 }
      ],
      conditionalQuestions: []
    },
    {
      id: 19,
      questionText: 'In the last 30 days, how much difficulty did you have in:',
      questionEmphasis: 'Sexual activities?',
      questionType: 'radio',
      uniqueAnswerId: 'WHODAS2_SEX_ACTS',
      autofill: false,
      answerOptions: [
        { display: 'None', value: 1 },
        { display: 'Moderate', value: 2 },
        { display: 'Mild', value: 3 },
        { display: 'Severe', value: 4 },
        { display: 'Extreme or cannot do', value: 5 }
      ],
      conditionalQuestions: []
    },
  ]
  */
};
