import { AssetDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';
import { compartmentalization } from '../questions/compartmentalization/compartmentalization';

export const prioritizeThoughtsDef: AssetDefinition = {
  id: 19,
  name: 'prioritize-thoughts',
  label: 'Prioritize Thoughts',
  description: `Soldiers perform best with focused attention.
                Help them identify their target task, as well as how to manage distracting thoughts.`,
  categories: ['all', 'stress'],
  showInLibrary: true,
  readOnly: false,
  assessments: [
    compartmentalization
  ],
  url: 'prioritize-thoughts'
};
