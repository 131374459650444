import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { timeFormatValidator } from './time-format.validator';

const PLACEHOLDER_TIME = 'HH:MM';
const PLACEHOLDER_MERIDIAN = 'am';

@Component({
  selector: 'noc-timepicker',
  templateUrl: './noc-timepicker.component.html',
  styles: [
    `
      .form-control:disabled {
        cursor: not-allowed;
      }

      label {
        text-align: left;
        color: #01070a;
      }
    `
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NocTimepickerComponent),
      multi: true
    }
  ]
})
export class NocTimepickerComponent implements ControlValueAccessor, OnInit {
  inputPlaceholder: string = PLACEHOLDER_TIME;
  inputLength = 5;
  timepickerFormGroup: FormGroup;
  errorCount = 0;
  onChange: any = () => {};
  onTouch: any = () => {};

  @Input() disabled = false;
  @Input() showMeridian = false;
  @Input() labelText: string;
  @Input() _value: string;

  @Output() isValid = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit() {
    if (this.showMeridian) {
      this.inputPlaceholder += ` ${PLACEHOLDER_MERIDIAN}`;
      this.inputLength = 8; // "HH:MM AM"
    }

    this.timepickerFormGroup = new FormGroup({
      timepickerControl: new FormControl(this.value, {
        validators: [timeFormatValidator(this.showMeridian)],
        updateOn: 'blur'
      })
    });
  }

  get timePickerControl(): AbstractControl {
    return this.timepickerFormGroup.get('timepickerControl');
  }

  get value() {
    return this._value;
  }
  set value(val: string) {
    if (this._value !== val) {
      this._value = val;
      this.onChange(val);
      this.onTouch(val);
      this.errorCount = this.checkForErrors();
      this.isValid.emit(!this.errorCount);
    }
  }

  writeValue(value: any): void {
    this.value = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.timepickerFormGroup.controls.timepickerControl.disable();
    } else {
      this.timepickerFormGroup.controls.timepickerControl.enable();
    }
  }

  checkForErrors(): number {
    const errors = this.timepickerFormGroup.get('timepickerControl')?.errors;
    if (errors) {
      return Object.keys(errors).length;
    }
    return 0;
  }
}
