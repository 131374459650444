import { IRepository } from '../contracts/interfaces';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class BaseService<T> implements IRepository<T> {
  protected _repo:IRepository<T>;

  constructor(repo:IRepository<T>) {
    if(!repo) {
      throw Error('Repository must be set');
    }
    this._repo = repo;
  }

  public getAsync(id:string):Promise<T> {
    return this._repo.getAsync(id);
  };

  public getOneAsync(query:Object):Promise<T> {
    return this.getAll(query, 1).pipe(
      map(r => {
      return r && r.length > 0 ? r[0] : null;
      })
    ).toPromise();
  }

  public getAllAsync(query?:any, size?:number):Promise<Array<T>> {
    return this._repo.getAllAsync(query, size);
  }

  public saveAsync(tObject:T):Promise<T> {
    return this._repo.saveAsync(tObject);
  }

  public sequenceAsync():Promise<number> {
    return this._repo.sequenceAsync();
  }

  public removeAsync(id:string):Promise<any> {
    return this._repo.removeAsync(id);
  }

  /*public removeAllAsync(ids:string[], chunkSize:number):Promise<any> {
      return this._repo.removeAllAsync(ids, chunkSize);
  }*/

  public get(id:string):Observable<T> {
    return this._repo.get(id);
  };

  public getAll(query?:any, size?:number):Observable<Array<T>> {
    return this._repo.getAll(query, size);
  }

  public save(tObject:T):Observable<T> {
    return this._repo.save(tObject);
  }

  public sequence():Observable<number> {
    return this._repo.sequence();
  }

  public remove(id:string):Observable<any> {
    return this._repo.remove(id);
  }

  /*public removeAll(ids:string[], chunkSize:number):Observable<any> {
      return this._repo.removeAll(ids, chunkSize);
  }*/
}