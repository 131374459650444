import { Component, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import BaseInputComponent from '../base/base-input';

@Component({
  selector: 'noctem-text-input',
  templateUrl: './text-input.component.html', 
  styleUrls: ['./text-input.component.scss',
              '../base/base-input.scss'],
  })
  export class TextInputComponent extends BaseInputComponent {
  constructor(private ngControl: NgControl) {
    super(ngControl);
  }

  @Input() placeHolderText = '';
  @Input() type = 'text';
}
