import { AssetDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';
import { quickstart } from '../questions/quickstart/quickstart';

export const quickstartDef: AssetDefinition = {
  id: 30,
  name: 'quickstart',
  label: 'Quickstart',
  categories: [],
  showInLibrary: false,
  readOnly: false,
  assessments: [
    quickstart
  ],
  url: 'quickstart'
};
