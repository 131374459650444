export const TBIPostConcussionDef = {
  name: 'tbi-post-concussion',
  description: 'Post Concussion Symptoms Questionnaire',
  instructions: 'After a head injury or accident some people experience symptoms that can cause worry or nuisance. We would like to know if you suffer from any of the symptoms given below.',
  /*
  Note: the questions property must be empty here (but available in the database) or this assessment will be made available for all patients

  questions: [
    {
      id: 0,
      questionText: 'Compared with before the accident, do you now (e.g. over the past 24 hours) suffer from:',
      questionEmphasis: 'Headaches',
      questionType: 'radio',
      uniqueAnswerId: 'POST-CONCUSSION_HEADACHES',
      autofill: false,
      answerOptions: [
        { display: 'Not experienced', value: 0 },
        { display: 'No more of a problem', value: 1 },
        { display: 'Mild problem', value: 2 },
        { display: 'Moderate problem', value: 3 },
        { display: 'Severe problem', value: 4 }
      ],
      conditionalQuestions: []
    },
    {
      id: 1,
      questionText: 'Compared with before the accident, do you now (e.g. over the past 24 hours) suffer from:',
      questionEmphasis: 'Feelings of dizziness',
      questionType: 'radio',
      uniqueAnswerId: 'POST-CONCUSSION_DIZZINESS',
      autofill: false,
      answerOptions: [
        { display: 'Not experienced', value: 0 },
        { display: 'No more of a problem', value: 1 },
        { display: 'Mild problem', value: 2 },
        { display: 'Moderate problem', value: 3 },
        { display: 'Severe problem', value: 4 }
      ],
      conditionalQuestions: []
    },
    {
      id: 2,
      questionText: 'Compared with before the accident, do you now (e.g. over the past 24 hours) suffer from:',
      questionEmphasis: 'Nausea and/or vomiting',
      questionType: 'radio',
      uniqueAnswerId: 'POST-CONCUSSION_NAUSIAVOMITING',
      autofill: false,
      answerOptions: [
        { display: 'Not experienced', value: 0 },
        { display: 'No more of a problem', value: 1 },
        { display: 'Mild problem', value: 2 },
        { display: 'Moderate problem', value: 3 },
        { display: 'Severe problem', value: 4 }
      ],
      conditionalQuestions: []
    },
    {
      id: 3,
      questionText: 'Compared with before the accident, do you now (e.g. over the past 24 hours) suffer from:',
      questionEmphasis: 'Noise sensitivity (easily upset by loud noise)',
      questionType: 'radio',
      uniqueAnswerId: 'POST-CONCUSSION_NOISESENSITIVITY',
      autofill: false,
      answerOptions: [
        { display: 'Not experienced', value: 0 },
        { display: 'No more of a problem', value: 1 },
        { display: 'Mild problem', value: 2 },
        { display: 'Moderate problem', value: 3 },
        { display: 'Severe problem', value: 4 }
      ],
      conditionalQuestions: []
    },
    {
      id: 4,
      questionText: 'Compared with before the accident, do you now (e.g. over the past 24 hours) suffer from:',
      questionEmphasis: 'Sleep disturbance',
      questionType: 'radio',
      uniqueAnswerId: 'POST-CONCUSSION_SLEEPDISTURBANCE',
      autofill: false,
      answerOptions: [
        { display: 'Not experienced', value: 0 },
        { display: 'No more of a problem', value: 1 },
        { display: 'Mild problem', value: 2 },
        { display: 'Moderate problem', value: 3 },
        { display: 'Severe problem', value: 4 }
      ],
      conditionalQuestions: []
    },
    {
      id: 5,
      questionText: 'Compared with before the accident, do you now (e.g. over the past 24 hours) suffer from:',
      questionEmphasis: 'Fatigue, tiring more easily',
      questionType: 'radio',
      uniqueAnswerId: 'POST-CONCUSSION_FATIGUE',
      autofill: false,
      answerOptions: [
        { display: 'Not experienced', value: 0 },
        { display: 'No more of a problem', value: 1 },
        { display: 'Mild problem', value: 2 },
        { display: 'Moderate problem', value: 3 },
        { display: 'Severe problem', value: 4 }
      ],
      conditionalQuestions: []
    },
    {
      id: 6,
      questionText: 'Compared with before the accident, do you now (e.g. over the past 24 hours) suffer from:',
      questionEmphasis: 'Being irritable, easily angered',
      questionType: 'radio',
      uniqueAnswerId: 'POST-CONCUSSION_IRRITABLE',
      autofill: false,
      answerOptions: [
        { display: 'Not experienced', value: 0 },
        { display: 'No more of a problem', value: 1 },
        { display: 'Mild problem', value: 2 },
        { display: 'Moderate problem', value: 3 },
        { display: 'Severe problem', value: 4 }
      ],
      conditionalQuestions: []
    },
    {
      id: 7,
      questionText: 'Compared with before the accident, do you now (e.g. over the past 24 hours) suffer from:',
      questionEmphasis: 'Feeling depressed or tearful',
      questionType: 'radio',
      uniqueAnswerId: 'POST-CONCUSSION_DEPRESSED',
      autofill: false,
      answerOptions: [
        { display: 'Not experienced', value: 0 },
        { display: 'No more of a problem', value: 1 },
        { display: 'Mild problem', value: 2 },
        { display: 'Moderate problem', value: 3 },
        { display: 'Severe problem', value: 4 }
      ],
      conditionalQuestions: []
    },
    {
      id: 8,
      questionText: 'Compared with before the accident, do you now (e.g. over the past 24 hours) suffer from:',
      questionEmphasis: 'Feeling frustrated or impatient',
      questionType: 'radio',
      uniqueAnswerId: 'POST-CONCUSSION_FRUSTRATED',
      autofill: false,
      answerOptions: [
        { display: 'Not experienced', value: 0 },
        { display: 'No more of a problem', value: 1 },
        { display: 'Mild problem', value: 2 },
        { display: 'Moderate problem', value: 3 },
        { display: 'Severe problem', value: 4 }
      ],
      conditionalQuestions: []
    },
    {
      id: 9,
      questionText: 'Compared with before the accident, do you now (e.g. over the past 24 hours) suffer from:',
      questionEmphasis: 'Forgetfullness, poor memory',
      questionType: 'radio',
      uniqueAnswerId: 'POST-CONCUSSION_FORGETFULLNESS',
      autofill: false,
      answerOptions: [
        { display: 'Not experienced', value: 0 },
        { display: 'No more of a problem', value: 1 },
        { display: 'Mild problem', value: 2 },
        { display: 'Moderate problem', value: 3 },
        { display: 'Severe problem', value: 4 }
      ],
      conditionalQuestions: []
    },
    {
      id: 10,
      questionText: 'Compared with before the accident, do you now (e.g. over the past 24 hours) suffer from:',
      questionEmphasis: 'Poor concentration',
      questionType: 'radio',
      uniqueAnswerId: 'POST-CONCUSSION_CONCENTRATION',
      autofill: false,
      answerOptions: [
        { display: 'Not experienced', value: 0 },
        { display: 'No more of a problem', value: 1 },
        { display: 'Mild problem', value: 2 },
        { display: 'Moderate problem', value: 3 },
        { display: 'Severe problem', value: 4 }
      ],
      conditionalQuestions: []
    },
    {
      id: 11,
      questionText: 'Compared with before the accident, do you now (e.g. over the past 24 hours) suffer from:',
      questionEmphasis: 'Taking longer to think',
      questionType: 'radio',
      uniqueAnswerId: 'POST-CONCUSSION_LONGERTOTHINK',
      autofill: false,
      answerOptions: [
        { display: 'Not experienced', value: 0 },
        { display: 'No more of a problem', value: 1 },
        { display: 'Mild problem', value: 2 },
        { display: 'Moderate problem', value: 3 },
        { display: 'Severe problem', value: 4 }
      ],
      conditionalQuestions: []
    },
    {
      id: 12,
      questionText: 'Compared with before the accident, do you now (e.g. over the past 24 hours) suffer from:',
      questionEmphasis: 'Blurred vision',
      questionType: 'radio',
      uniqueAnswerId: 'POST-CONCUSSION_BLURREDVISION',
      autofill: false,
      answerOptions: [
        { display: 'Not experienced', value: 0 },
        { display: 'No more of a problem', value: 1 },
        { display: 'Mild problem', value: 2 },
        { display: 'Moderate problem', value: 3 },
        { display: 'Severe problem', value: 4 }
      ],
      conditionalQuestions: []
    },
    {
      id: 13,
      questionText: 'Compared with before the accident, do you now (e.g. over the past 24 hours) suffer from:',
      questionEmphasis: 'Light sensitivity (easily upset by bright light)',
      questionType: 'radio',
      uniqueAnswerId: 'POST-CONCUSSION_LIGHTSENSITIVITY',
      autofill: false,
      answerOptions: [
        { display: 'Not experienced', value: 0 },
        { display: 'No more of a problem', value: 1 },
        { display: 'Mild problem', value: 2 },
        { display: 'Moderate problem', value: 3 },
        { display: 'Severe problem', value: 4 }
      ],
      conditionalQuestions: []
    },
    {
      id: 14,
      questionText: 'Compared with before the accident, do you now (e.g. over the past 24 hours) suffer from:',
      questionEmphasis: 'Double vision',
      questionType: 'radio',
      uniqueAnswerId: 'POST-CONCUSSION_DOUBLEVISION',
      autofill: false,
      answerOptions: [
        { display: 'Not experienced', value: 0 },
        { display: 'No more of a problem', value: 1 },
        { display: 'Mild problem', value: 2 },
        { display: 'Moderate problem', value: 3 },
        { display: 'Severe problem', value: 4 }
      ],
      conditionalQuestions: []
    },
    {
      id: 15,
      questionText: 'Compared with before the accident, do you now (e.g. over the past 24 hours) suffer from:',
      questionEmphasis: 'Restlessness',
      questionType: 'radio',
      uniqueAnswerId: 'POST-CONCUSSION_RESTLESSNESS',
      autofill: false,
      answerOptions: [
        { display: 'Not experienced', value: 0 },
        { display: 'No more of a problem', value: 1 },
        { display: 'Mild problem', value: 2 },
        { display: 'Moderate problem', value: 3 },
        { display: 'Severe problem', value: 4 }
      ],
      conditionalQuestions: []
    },
  ]
  */
};
