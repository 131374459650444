import { AssessmentDefinition } from '../../../../../noctem-lib/src/core/contracts/models.calculations';


export const twoInternalSystems: AssessmentDefinition = {
  name: 'two-internal-sleep-systems',
  description: 'Two Internal Sleep Systems',
  instructions: null,
  questions: [
    {
      id: 0,
      questionText: 'Two Sleep Systems',
      questionEmphasis: ``,
      subText: `<p>Sleep Drive and Biological Clock</p>
                <p><img src='./assets/img/module-images/two-internal-sleep-systems/graph.png'/></p>
                <p>We have two internal systems that work together to control sleep. This includes <b>sleep drive</b> and the <b>biological clock</b>.</p>
                <p>When these systems are aligned and working together, you will sleep best. </p>

      `,
      questionType: 'module',

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 1,
      questionText: 'System 1: Sleep Drive',
      questionEmphasis: ``,
      subText: `<p>The sleep drive keeps track of how long you have been awake.</p>
                <p>The longer you are awake the stronger it will push for sleep to occur. </p>
                <p><img src='./assets/img/module-images/two-internal-sleep-systems/sleep-drive-graph.png'/></p>
                <p>We need to build up enough sleep drive during wakeful hours to be able to fall asleep quickly.</p>
                <p>The only way to get rid of accumulated sleep drive is to sleep. </p>
      `,
      questionType: 'module',

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 2,
      questionText: 'System 2: Sleep Drive',
      questionEmphasis: ``,
      subText: `<p>The biological clock keeps track of the time of day.  </p>
                <p>This system allows our brain to tell time even without an external clock. </p>
                <p><img src='./assets/img/module-images/two-internal-sleep-systems/biological-clock.svg' /></p>
                <p>During daylight hours it signals to the brain that it is time for wakefulness.</p>
                <p>During nighttime hours it signals to the brain that it is time for sleep. </p>
                <p>If doing shift work and expected to be alert in the nighttime, you are working against the signaling of your biological clock. </p>
      `,
      questionType: 'module',

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 3,
      questionText: 'How do these systems work together?',
      questionEmphasis: ``,
      subText: `<p>As your sleep drive builds during the day, wake signals from your biological clock help to keep you awake and alert.</p>
                <p>As your sleep drive declines during sleep, sleep signals from your biological clock help to keep you asleep.</p>
                <p>These two systems make it easiest to fall and stay asleep if you have been awake all day and attempt sleep during nighttime hours.</p>
      `,
      questionType: 'module',

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    }
  ]
};
