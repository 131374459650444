
import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { Model } from '../../../core/web-ng';
import { UserSettings, Role, Organization } from '../../../core/contracts';

export const ANON_TOKEN = 'ANON_TOKEN';
export const CLIENT_ID = 'CLIENT_ID';
export const HOST = 'HOST';
export const USER_SERVICE = 'USER_SERVICE';
export const APPLICATION_CONTEXT = 'APPLICATION_CONTEXT';
export const AUTH_SERVICE = 'AUTH_SERVICE';
export const HTTP_CLIENT = 'HTTP_CLIENT';
export const CALC_CONFIG = 'CALC_CONFIG';
export const LOG_CACHE = 'LOG_CACHE';
export const DATA_DICT = 'DATA_DICT';


export class UserState<T extends UserSettings> {
    public User: T;
    public Roles: Role[];
    public Organizations: Organization[];
    public IsLoggedIn: boolean;
    public IsLoading: boolean;
    public IsUserInitialized: boolean;
    public Token: string;
    public Errors: string;

    public ChangePasswordState: ChangePasswordState;
}

export class ChangePasswordState {
    public HasPasswordChanged: boolean;
    public Errors: string;
}



export interface IUserStateService {
    model: Model<UserState<UserSettings>>;
    state$: Observable<UserState<UserSettings>>;
    authenticate(username: string, password: string): void;
    expire(): void;
}
