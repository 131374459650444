import {
  SearchSleepDailyOutcome,
  Assessment,
  AssessmentGroup,
  AssessmentAnswer,
  AssessmentTemplate
} from './models';
import {
  find as _find,
  pull as _pull,
  orderBy as _orderBy,
  filter as _filter,
  some as _some
} from 'lodash';
import { LocalModuleProgress } from '../../core/contracts/models';
import { ApplicationContext } from '../../core';
import { DatePipe } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import {
  CalendarEventService,
  SleepPrescriptionService,
  AssessmentService,
  SentModuleService, 
  AssessmentDefinitionService 
} from './services';
import { __core_private_testing_placeholder__ } from '@angular/core/testing';
import moment, { Moment } from 'moment';
import {
  CalculationsValueContainer,
  Condition,
  Calculation,
  OperationType,
  CalculatedFieldDefinition,
  AssessmentFieldDefinition,
  AssessmentGroupScoreFieldDefinition,
  FieldDefinitionBase,
  AssessmentValueDefinition,
  FieldType,
  UnitType,
  Result,
  SleepModuleDefinition,
  EqualityType,
  DataSummationType,
  BaseCalculationDefinition,
  AssetDefinition
} from '../../core/contracts/models.calculations';

import { cloneDeep as _cloneDeep } from 'lodash';
import { CALC_CONFIG, LOG_CACHE } from '../state/user';
import { logDefinitions } from '../../constants/constants';
import {
  MAXIMUM_LATENCY_OR_WASO,
  minimumCompletedAssessmentsToShowData
} from '../../../../aire/constant-definitions/constants';
import { LegacyService } from './legacy.service';
import { Platform } from '@ionic/angular';
import { NetworkService } from './network-service';
import { CacheService } from './cache-service';
import { LogCacheServiceInterface } from '../state/log-cache-service.interface';
@Injectable({
  providedIn: 'root'
})
export class CalculationService {
  constructor(
    private sleepPrescriptionService: SleepPrescriptionService,
    private calendarEventService: CalendarEventService,
    private assessmentService: AssessmentService,
    @Inject(CALC_CONFIG) public calculationsDefinitions: BaseCalculationDefinition,
    private legacyService: LegacyService,
    private platform: Platform,
    private networkService: NetworkService,
    private cacheService: CacheService,
    private sentModuleService: SentModuleService,
    private applicationContext: ApplicationContext,
    private logCacheService: LogCacheServiceInterface,
    private assessmentDefinitionService: AssessmentDefinitionService
  ) {}
  pipe = new DatePipe('en-US');

  public readonly ASSESSMENT_CACHE = 'cache:assessments';
  private readonly _LOG_DATE: string = 'log-date';
  private assessments;

  public async getCalculationsFromAPI(
    startDate: moment.Moment,
    endDate: moment.Moment,
    fields: Array<string>,
    patientIds: Array<string>,
    includeIndividualStatistics = false,
    treatmentPhases?: Array<string>,
    calculationOptions?: any
  ): Promise<CalculationsValueContainer> {
    return new Promise<CalculationsValueContainer>(async resolve => {
      await this.assessmentService.getCalculations(
        startDate.toString(),
        endDate.toString(),
        fields.join(','),
        patientIds.join(','),
        includeIndividualStatistics,
        treatmentPhases.join(',')
      ).subscribe(async data => {
        const calculationsContainer = new CalculationsValueContainer();
        await data.individualFields.assessmentValues.forEach(v => {
          if(v[1].values) {
            v[1].values = new Map(v[1].values);
          }
        });

        data.individualFields.assessmentValues = new Map(data.individualFields.assessmentValues);
        calculationsContainer.individualFields = data.individualFields;

        const requestedValues = _cloneDeep(calculationsContainer);
        requestedValues.individualFields.assessmentValues.forEach((value, key) => {
          if (fields.indexOf(key) === -1 && key !== this._LOG_DATE) {
            requestedValues.individualFields.assessmentValues.delete(key);
          } else {
            value.values.forEach((v, k, m) => {
              if (moment(k).isBefore(startDate) || moment(k).isAfter(endDate)) {
                requestedValues.individualFields.assessmentValues.get(key).values.delete(key);
              }
            });
          }
        });
        resolve(requestedValues);
      });
    });
  }

  public async getCalculations(
    startDate: moment.Moment,
    endDate: moment.Moment,
    fields: Array<string>,
    patientIds: Array<string>,
    includeIndividualStatistics = false,
    treatmentPhases?: Array<string>,
    calculationOptions?: any
  ): Promise<CalculationsValueContainer> {
    const calculationsContainer = await this.getCoreCalculations(
      startDate,
      endDate,
      fields,
      patientIds,
      includeIndividualStatistics,
      treatmentPhases,
      calculationOptions
    );
    const requestedValues = _cloneDeep(calculationsContainer);
    requestedValues.individualFields.assessmentValues.forEach((value, key) => {
      if (fields.indexOf(key) === -1 && key !== this._LOG_DATE) {
        requestedValues.individualFields.assessmentValues.delete(key);
      } else {
        value.values.forEach((v, k, m) => {
          if (moment(k).isBefore(startDate) || moment(k).isAfter(endDate)) {
            requestedValues.individualFields.assessmentValues.get(key).values.delete(key);
          }
        });
      }
    });
    return new Promise<CalculationsValueContainer>(resolve => {
      resolve(requestedValues);
    });
  }

  public async getChartDataForThresholds(
    startDate: moment.Moment,
    endDate: moment.Moment,
    fields: Array<string>,
    patients: Array<string>,
    includeIndividualStatistics = false,
    enforceMinimum = true
  ): Promise<Map<string, { average: number; data: Map<string, Array<number>> }>> {
    const data = new Map<string, { average: number; data: Map<string, Array<number>> }>();
    const calcs = await this.getCalculations(
      startDate,
      endDate,
      fields,
      patients,
      includeIndividualStatistics
    );

    calcs.individualFieldsByPatient.forEach((patientMap, fieldName) => {
      if (fields.indexOf(fieldName) !== -1) {
        const dateCountArray = new Array<number>();
        const patientsMap = new Map<string, Array<number>>();

        let count = 0;
        let sum = 0;
        patientMap.forEach((dateMap, patientId) => {
          const valArray = new Array<number>();
          let dateIndex = 0;
          dateMap.forEach(val => {
            if (dateCountArray.length <= dateIndex) {
              dateCountArray.push(0);
            }
            if (val.rawValue != null) {
              valArray.push(val.rawValue as number);
              count++;
              sum += val.rawValue as number;

              dateCountArray[dateIndex]++;
            } else {
              valArray.push(null);
            }
            dateIndex++;
          });
          patientsMap.set(patientId, valArray);
        });

        let hasData = false;
        const exceptionFields = ['disrupted-sleep', 'prevented-sleep'];

        const radarFields = [
          'team-left-out',
          'team-angry',
          'team-work',
          'team-communication',
          'team-handle-conflict',
          'team-expression'
        ];
        const isRadar = radarFields.indexOf(fieldName) !== -1;
        const isException = exceptionFields.indexOf(fieldName) !== -1;
        if (enforceMinimum && !exceptionFields) {
          dateCountArray.forEach((dateCount, dateIndex) => {
            patientsMap.forEach(v => {
              if (
                dateCount <=
                minimumCompletedAssessmentsToShowData - minimumCompletedAssessmentsToShowData
              ) {
                //reverting to 0, due to the requirement for separate label for 1 entry in charts
                if (v[dateIndex] != null) {
                  sum -= v[dateIndex];
                }
                v[dateIndex] = null;
              } else {
                hasData = true;
              }
            });
          });
        } else if (!isRadar && enforceMinimum) {
          hasData =
            count > minimumCompletedAssessmentsToShowData - minimumCompletedAssessmentsToShowData;
        } else if (isRadar) {
          hasData =
            count > minimumCompletedAssessmentsToShowData - minimumCompletedAssessmentsToShowData;
        } else {
          hasData = true;
        }

        let average = 0;
        if (count > 0) {
          average = sum / count;
        }
        data.set(fieldName, {
          average: hasData ? average : null,
          data: hasData ? patientsMap : null
        });
        if (isRadar && !data.get('radar-count')) {
          data.set('radar-count', {
            average: count,
            data: null
          });
        }
      }
    });
    return Promise.resolve(data);
  }

  public async getChartData(
    startDate: moment.Moment,
    endDate: moment.Moment,
    fields: Array<string>,
    patientIds: Array<string>
  ): Promise<Map<string, Array<number>>> {
    const data = new Map<string, Array<number>>();
    const container = new CalculationsValueContainer();
    const calcs = await this.getCalculations(
      startDate.startOf('day'),
      endDate.endOf('day'),
      fields,
      patientIds,
      false
    );
    fields.forEach(field => {
      if (field === undefined) {
        return;
      }
      const valArray = new Array<number>();
      calcs.individualFields.assessmentValues?.get(field)?.values.forEach(val => {
        valArray.unshift(val.rawValue as number);
      });

      data.set(field.toString(), valArray);
    });

    return Promise.resolve(data);
  }

  public async getSleepModuleRecommendations(
    startDate: moment.Moment,
    endDate: moment.Moment,
    userIds: Array<string>,
    isMedic: boolean
  ): Promise<Array<AssetDefinition>> {
    const definitions: Array<SleepModuleDefinition> = this.getSleepModuleDefinitions();
    const fields: Array<string> = this.getFieldsFromTactic(definitions);
    const calcs = await this.getCalculations(startDate, endDate, fields, userIds, false);
    const recommendedModules = this.getRecommendedModules(calcs, definitions, isMedic);
    return new Promise<Array<AssetDefinition>>(resolve => {
      resolve(recommendedModules);
    });
  }

  private checkIfDataRefresh(patientIds: Array<string>): boolean {
    return true;
    // if (patientIds === this.calculationsContainer.patientIds) { return false; }
    // if (patientIds == null || this.calculationsContainer.patientIds == null) { return true; }
    // if (patientIds.length !== this.calculationsContainer.patientIds.length) { return true; }

    // for (let i = 0; i < patientIds.length; ++i) {
    //   if (patientIds[i] !== this.calculationsContainer.patientIds[i]) { return false; }
    // }
    // return false;
  }

  private getFieldTemplateDefinitions(
    fields: Array<string>,
    calculatedFieldTemplates: Set<FieldDefinitionBase>,
    assessmentFieldTemplates: Set<FieldDefinitionBase>,
    assessmentGroupScoreFieldTemplates: Set<FieldDefinitionBase>,
    assessmentTypes: Set<string>
  ): void {
    this.calculationsDefinitions.AssessmentDefinitions.sort(this.sortFieldDefinitions)
      .filter(d => fields.indexOf(d.name) !== -1 && d._type === FieldType.Assessment)
      .forEach(assessmentFieldTemplates.add, assessmentFieldTemplates);

    this.calculationsDefinitions.CalculatedFieldDefinitions.filter(
      d => fields.indexOf(d.name) !== -1 && d._type === FieldType.Calculated
    ).forEach(calculatedFieldTemplates.add, calculatedFieldTemplates);

    this.calculationsDefinitions.AssessmentDefinitions.filter(
      d => fields.indexOf(d.name) !== -1 && d._type === FieldType.AssessmentGroupScore
    ).forEach(assessmentFieldTemplates.add, assessmentFieldTemplates);

    assessmentFieldTemplates.forEach(b => {
      assessmentTypes.add((b as AssessmentFieldDefinition).assessmentType);
    });

    assessmentGroupScoreFieldTemplates.forEach(b => {
      assessmentTypes.add((b as AssessmentGroupScoreFieldDefinition).assessmentType);
    });
  }

  private sortFieldDefinitions(a: AssessmentFieldDefinition, b: AssessmentFieldDefinition) {
    return b.assessmentType === a.assessmentType ? -1 : 1;
  }

  private processRecommendationCondition(
    condition: Condition,
    calculations: CalculationsValueContainer,
    isMedic: boolean
  ): boolean {
    if (!condition) {
      return true;
    }
    let recommended = false;
    const fieldType = this.getFieldType(condition.fieldName);
    const fieldValue: number | Array<string | number> = this.getSummationValue(
      calculations,
      condition,
      fieldType,
      isMedic
    );
    if (fieldValue && !recommended) {
      switch (condition.operator) {
        case EqualityType.GreaterThan:
          if (fieldValue > condition.valueToCompare) {
            recommended = true;
          }
          break;
        case EqualityType.LessThan:
          if (fieldValue < condition.valueToCompare) {
            recommended = true;
          }
          break;
        case EqualityType.Equal:
          if (fieldValue === condition.valueToCompare) {
            recommended = true;
          }
          break;
        case EqualityType.NotEqual:
          if (fieldValue !== condition.valueToCompare) {
            recommended = true;
          }
          break;
        case EqualityType.Contains:
          const valueArray = fieldValue as Array<string | number>;
          const compareArray = condition.valueToCompare as Array<string | number>;
          recommended = valueArray.some(element => {
            return (
              compareArray.indexOf((element as any)?.value) !== -1 && (element as any)?.isChecked
            );
          });
          break;
      }
    }
    return recommended;
  }

  private async getRecommendedModules(
    calculations: CalculationsValueContainer,
    definitions: Array<SleepModuleDefinition>,
    isMedic: boolean
  ): Promise<AssetDefinition[]> {
    const recommendedModules = new Array<AssetDefinition>();
    const localStorageItem = localStorage.getItem('moduleProgress');
    let previousModules = new Map<string, LocalModuleProgress>(),
      sentModules: Map<string, AssetDefinition>,
      moduleRecommendedAgain: Boolean;

    if (this.networkService.isOnline()) {
      // If network is online get their previously completed modules and modules recently sent to them from the database
      //Also need to sort them from the latest to the oldest
      let assessments: Array<Assessment> = (
        await this.getPatientAssessments(this.applicationContext.User.UserId)
      ).sort((a, b) =>
        moment
          .utc(b.assessmentDate)
          .utcOffset(b.timeZoneOffset ?? 240)
          .diff(a.assessmentDate)
      );

      // Convert assessment to local module
      assessments.forEach(asmt => {
        //only get the letest!
        if (!previousModules.get(asmt.phaseName)) {
          const newPrevModule = new LocalModuleProgress();
          newPrevModule.id = asmt.Id;
          newPrevModule.dateRecommended = new Date(asmt.assessmentDate);
          newPrevModule.lastPage = asmt.lastPage;
          newPrevModule.percentComplete = asmt.percentageComplete;
          newPrevModule.dateStarted = asmt.startTime;
          newPrevModule.dateCompleted = asmt.endTime;
          newPrevModule.medicRecommended = asmt.isProviderRecommended;
          newPrevModule.name = asmt.phaseName;
          previousModules.set(asmt.phaseName, newPrevModule);
        }
      });

      if (typeof this.logCacheService.retrieveToDoAssessment === 'function') {
        //medic's logCacheService doesn't have retrieveToDoAssessment()
        sentModules = await this.logCacheService.retrieveToDoAssessment();
      }
    } else if (!localStorageItem || localStorageItem === '{}') {
      previousModules = new Map<string, LocalModuleProgress>();
    } else {
      previousModules = new Map<string, LocalModuleProgress>(JSON.parse(localStorageItem));
    }

    definitions.forEach(d => {
      if ((!isMedic && d.isMedic == null) || d.isMedic === isMedic) {
        // If the module was previously completed
        const previousModule = previousModules?.get(d.name);

        // Find last time module was sent
        const moduleResent = sentModules?.get(d.name);

        if (previousModule && moduleResent) {
          // If the module was sent after the last time it was completed, add it
          moduleRecommendedAgain =
            moment(previousModule.dateRecommended) < moment(moduleResent.dateSent);
        }
        let recommended = false;
        const isComplete = previousModule && previousModule.percentComplete === 100;
        const inProgress = previousModule && previousModule.percentComplete < 100;
        d.formula.forEach(f => {
          if (!recommended) {
            recommended = this.processRecommendationCondition(f, calculations, isMedic);
            if (recommended && f.additionalCondition) {
              recommended = this.processRecommendationCondition(
                f.additionalCondition,
                calculations,
                isMedic
              );
            }
          }
        });
        if ((recommended || inProgress) && !recommendedModules.find(m => m.name === d.name)) {
          const assetDef = this.calculationsDefinitions.ModuleTemplates.find(
            m => m.name === d.name
          );
          assetDef.recommendedBy = 'aire';
          recommendedModules.push(assetDef);
        }
        // If the module has been recommended and it either has not been completed or was recommended again
        if (recommended && (!previousModule || moduleRecommendedAgain)) {
          const newModule = new LocalModuleProgress();
          newModule.dateRecommended = new Date();
          newModule.lastPage = 0;
          newModule.percentComplete = 0;
          previousModules.set(d.name, newModule);
          localStorage.setItem(
            'moduleProgress',
            JSON.stringify(Array.from(previousModules.entries()))
          );
        }
      }
    });
    return recommendedModules;
  }

  private getFieldType(field: string): FieldType {
    const fieldTemplate = this.getAssessessmentFieldTemplate(field);
    return fieldTemplate?._type;
  }

  private getSummationValue(
    calculations: CalculationsValueContainer,
    condition: Condition,
    type: FieldType,
    isMedic: boolean
  ): number | Array<string | number> {
    let assessmentData: any = null;
    switch (type) {
      case FieldType.AssessmentGroupScore:
        assessmentData = calculations.individualFields.assessmentValues.get(condition.fieldName);
        break;
      case FieldType.Calculated:
      case FieldType.SleepDiary:
      case FieldType.Assessment:
        assessmentData = calculations.individualFields.assessmentValues.get(condition.fieldName);
        break;
      default:
        return null;
    }
    if (!assessmentData) {
      return null;
    }
    let returnValue = null;
    let summationType = condition.summationType;
    if (!isMedic && summationType !== DataSummationType.Concat) {
      summationType = DataSummationType.LatestValue;
    }
    switch (summationType) {
      case DataSummationType.Average:
        returnValue = assessmentData.average.rawValue;
        break;
      case DataSummationType.LatestValue:
        assessmentData.values.forEach(value => {
          if (returnValue == null && value.rawValue != null) {
            returnValue = value.rawValue;
          }
        });

        break;
      case DataSummationType.NumberOfOccurances:
        returnValue = assessmentData.numberOfOccurances;
        break;
      case DataSummationType.Sum:
        returnValue = assessmentData.sum;
        break;
      case DataSummationType.BaseLineValue:
        if (typeof assessmentData.baselineValue === 'number') {
          returnValue = assessmentData.baselineValue;
        } else {
          returnValue = this.isTrue(assessmentData.baselineValue) ? 1 : 0;
        }
        break;
      case DataSummationType.Concat:
        returnValue = new Array<string | number>();
        Array.from(assessmentData.values).map(arr => {
          const val = arr[1].rawValue;
          if (val == null) {
            returnValue.push(null);
          } else {
            returnValue.push(...(arr[1].rawValue as Array<string | number>));
          }
        });
        break;
    }
    return returnValue;
  }

  private getSleepModuleDefinitions(): Array<SleepModuleDefinition> {
    return this.calculationsDefinitions.AlgorithmDefinitions;
  }

  private getFieldsFromTactic(definitions: Array<SleepModuleDefinition>): Array<string> {
    const fields = new Array<string>();
    definitions.forEach(d => {
      d.formula.map(f => fields.push(f.fieldName));
    });
    return fields;
  }

  private getAssessmentQuery(
    startDate: moment.Moment,
    endDate: moment.Moment,
    assessmentTypes: Set<string>,
    personIds: Array<string>,
    assessmentPhases?: Array<string>
  ) {
    if (!assessmentPhases) {
      assessmentPhases = ['baseline'];
    } else if (assessmentPhases.indexOf('baseline') === -1) {
      assessmentPhases.push('baseline');
    }
    if (assessmentPhases) {
      const sDate = startDate.toISOString();
      const eDate = endDate.toISOString();
      return {
        $and: [
          { 'Payload.groups.type': { $in: Array.from(assessmentTypes) } },
          { 'Payload.user.id': { $in: personIds } },
          {
            $or: [
              {
                $and: [
                  { 'Payload.assessmentDate': { $gte: sDate } },
                  { 'Payload.assessmentDate': { $lte: eDate } }
                ]
              },
              {
                'Payload.phaseName': { $in: assessmentPhases }
              }
            ]
          },
          {
            $or: [
              { 'Payload.percentageComplete': { $exists: false } },
              { 'Payload.percentageComplete': 100 }
            ]
          }
        ]
      };
    } else {
      //why this even exist? will this ever be called --WP
      if (assessmentPhases) {
        return {
          $and: [
            { 'Payload.groups.type': { $in: Array.from(assessmentTypes) } },
            { 'Payload.user.id': { $in: personIds } },
            {
              $or: [
                {
                  $and: [
                    { 'Payload.assessmentDate': { $gte: startDate.toISOString() } },
                    { 'Payload.assessmentDate': { $lte: endDate.toISOString() } }
                  ]
                }
              ]
            }
          ]
        };
      }
    }
  }

  private async getCoreCalculations(
    startDate: moment.Moment,
    endDate: moment.Moment,
    fields: Array<string>,
    patientIds: Array<string>,
    includeIndividualStatistics: boolean,
    treatmentPhases: Array<string>,
    calculationOptions?: any
  ) {
    const calculationsContainer = new CalculationsValueContainer();

    // TODO: Minimum of X number of days before tactics are assigned
    const isGroupCalc: boolean = patientIds.length > 1;
    // foreach date, check if data already exists, and requested fields exist
    const dates: Array<moment.Moment> = this.getDateArray(moment(startDate), moment(endDate));

    // get field template definitions
    const calculatedFields: Set<CalculatedFieldDefinition> = new Set<CalculatedFieldDefinition>();
    const assessmentFields: Set<AssessmentFieldDefinition> = new Set<AssessmentFieldDefinition>();
    const assessmentGroupScoreFields: Set<AssessmentGroupScoreFieldDefinition> =
      new Set<AssessmentGroupScoreFieldDefinition>();
    const assessmentTypes: Set<string> = new Set<string>();

    this.getFieldTemplateDefinitions(
      fields,
      calculatedFields,
      assessmentFields,
      assessmentGroupScoreFields,
      assessmentTypes
    );
    this.getCalculatedFields(
      calculatedFields,
      assessmentFields,
      assessmentTypes,
      calculationsContainer
    );
    this.getAssessmentFields(assessmentFields, calculationsContainer);
    // get list of basic fields to pull from assessment

    const assessments = await this.getAssessments(
      startDate,
      endDate,
      assessmentTypes,
      patientIds,
      treatmentPhases
    );

    if (!calculationsContainer.individualFields.assessmentValues.has(this._LOG_DATE)) {
      calculationsContainer.individualFields.assessmentValues.set(
        this._LOG_DATE,
        new AssessmentValueDefinition(UnitType.Date)
      );
    }

    // daily logs
    let hasLog = false;
    const adherenceTally: any = {};
    let resetCounts = true;
    dates.forEach(assessmentDate => {
      calculationsContainer.individualFields.assessmentValues
        .get(this._LOG_DATE)
        .values.set(
          assessmentDate.format(this.calculationsDefinitions.GlobalFlags.dateKeyFormat),
          new Result(assessmentDate, UnitType.Date)
        );
      let assessmentType = null;

      // need to initialize values
      if (includeIndividualStatistics) {
        patientIds.forEach(id => {
          fields.forEach(field => {
            this.addAssessmentValueForIndividual(
              id,
              field,
              null,
              assessmentDate,
              calculationsContainer
            );
          });

          assessmentFields.forEach(assessmentField => {
            this.addAssessmentValueForIndividual(
              id,
              assessmentField.name,
              null,
              assessmentDate,
              calculationsContainer
            );
          });

          calculatedFields.forEach(calculatedField => {
            this.addAssessmentValueForIndividual(
              id,
              calculatedField.name,
              null,
              assessmentDate,
              calculationsContainer
            );
          });
        });
      }
      assessmentFields.forEach(lt => {
        const assessmentTemplate: AssessmentFieldDefinition = lt;

        const assessmentValueContainer =
          calculationsContainer.individualFields.assessmentValues.get(assessmentTemplate.name);
        if (resetCounts) {
          assessmentValueContainer.average = new Result(null, lt.unit);
          assessmentValueContainer.numberOfOccurances = null;
          assessmentValueContainer.sum = new Result(0, lt.unit);
        }

        if (assessmentValueContainer === undefined) {
          return;
        }
        //if (!assessmentValueContainer.values.has(assessmentDate.format(this.calculationsDefinitions.GlobalFlags.dateKeyFormat))) {
        // get assessment

        const assessmentsForDate: Array<Assessment> =
          assessments
            ?.filter(assess =>
              moment
                .utc(assess.assessmentDate)
                .subtract(assess.timeZoneOffset, 'm')
                .isSame(moment.utc(assessmentDate).subtract(assess.timeZoneOffset, 'm'), 'd')
            )
            .sort((a, b) => (a.user?.id === b.user?.id ? -1 : 1)) ?? [];
        let assessmentValue = null;
        let fieldSumForGroup = 0;
        let fieldCountForGroup = 0;
        let fieldValue = 0;
        let currentUserId = null;
        assessmentsForDate.forEach(assessmentForDate => {
          if (assessmentForDate.groups.find(g => g.type === lt.assessmentType)) {
            if (assessmentForDate) {
              this.cacheAssessment(assessmentForDate);
            }

            // count adherence for assessment Type
            let newType = false;
            let newUser = false;
            if (lt.assessmentType !== assessmentType) {
              assessmentType = lt.assessmentType;
              newType = true;
            } else {
              newType = false;
            }

            if (currentUserId !== assessmentForDate.user?.id) {
              currentUserId = assessmentForDate.user?.id;
              newUser = true;
              if (adherenceTally[assessmentType]) {
                adherenceTally[assessmentType].users =
                  adherenceTally[assessmentType].users ?? 0 + 1;
              }
            } else {
              newUser = false;
            }

            if (currentUserId !== assessmentForDate.user?.id) {
              currentUserId = assessmentForDate.user?.id;
              newUser = true;
              if (adherenceTally[assessmentType]) {
                adherenceTally[assessmentType].users =
                  adherenceTally[assessmentType].users ?? 0 + 1;
              }
            } else {
              newUser = false;
            }

            if ((newType || newUser) && adherenceTally[assessmentType]) {
              adherenceTally[assessmentType].assessments =
                adherenceTally[assessmentType].assessments ?? 0 + 1;
            }

            hasLog = true;

            assessmentValue = this.getAssessmentValue(
              assessmentForDate,
              assessmentTemplate,
              lt._type
            );

            if (includeIndividualStatistics) {
              this.addAssessmentValueForIndividual(
                assessmentForDate.user.id,
                lt.name,
                assessmentValue,
                assessmentDate,
                calculationsContainer
              );
            }

            if (assessmentValue != null) {
              if (assessmentValueContainer.numberOfOccurances == null) {
                assessmentValueContainer.numberOfOccurances = 0;
              }
              switch (assessmentTemplate.unit) {
                case UnitType.EveningTime:
                  if (assessmentValue < 12 * 60) {
                    assessmentValue += 24 * 60;
                  }
                  assessmentValueContainer.sum.rawValue += assessmentValue;
                  assessmentValueContainer.numberOfOccurances++;
                  fieldCountForGroup++;
                  fieldSumForGroup += assessmentValue;
                  fieldValue = fieldSumForGroup / assessmentValue;
                  break;
                case UnitType.Time:
                case UnitType.MorningTime:
                case UnitType.Number:
                case UnitType.Minutes:
                case UnitType.Days:
                case UnitType.Percent:
                  assessmentValueContainer.sum.rawValue += assessmentValue;
                  assessmentValueContainer.numberOfOccurances++;
                  fieldCountForGroup++;
                  fieldSumForGroup += assessmentValue;
                  fieldValue = fieldSumForGroup / assessmentValue;
                  break;
                case UnitType.Hours:
                  assessmentValueContainer.sum.rawValue += assessmentValue;
                  assessmentValueContainer.numberOfOccurances++;
                  fieldCountForGroup++;
                  fieldSumForGroup += assessmentValue;
                  fieldValue = fieldSumForGroup / assessmentValue;
                case UnitType.YesNo:
                  if (assessmentValue === true) {
                    assessmentValueContainer.numberOfOccurances++;
                  }
                  fieldValue = assessmentValue;
                  break;
                case UnitType.Multi:
                  assessmentValueContainer.numberOfOccurances++;
                  fieldCountForGroup++;
              }
            }
          }
        });
        if (
          (lt.name === 'sleep-latency' || lt.name === 'sleep-interruption-total') &&
          assessmentValue &&
          assessmentValue > MAXIMUM_LATENCY_OR_WASO
        ) {
          assessmentValue = MAXIMUM_LATENCY_OR_WASO;
        }
        assessmentValueContainer.values.set(
          assessmentDate.format(this.calculationsDefinitions.GlobalFlags.dateKeyFormat),
          new Result(assessmentValue ?? null, lt.unit)
        );
      });
      resetCounts = false;

      if (includeIndividualStatistics) {
        this.setCalculatedValuesForIndividuals(
          calculatedFields,
          assessmentDate,
          calculationsContainer
        );
      } else {
        this.setCalculatedValues(calculatedFields, assessmentDate, calculationsContainer);
      }
    });

    // calc averages and adherence
    calculationsContainer.individualFields.assessmentValues.forEach(v => {
      v.average.rawValue = this.calculateAverage(
        v.sum.rawValue as number,
        v.numberOfOccurances,
        calculationOptions
      );
      if (calculationOptions?.includeStandardDeviation) {
        v.standardDeviation.rawValue = this.calculateStandardDeviation(v);
      }
    });

    // Adherence
    Object.keys(adherenceTally).forEach(a => {
      // get frequency of assessment
      const frequency = (this.assessmentDefinitionService.getByName(a)).frequency;
      const maxDays = moment().diff(startDate, 'days');
      const maxAssessments = Math.floor(maxDays / frequency) * patientIds.length;
      adherenceTally[a].adherence = (
        (adherenceTally[a].assessments / maxAssessments) *
        100
      ).toFixed(2);
    });

    calculationsContainer.adherences = adherenceTally;
    return calculationsContainer;
  }

  private calculateStandardDeviation(v) {
    if (v.numberOfOccurances == null) {
      return null;
    }

    const differences = new Array<number>();
    v.values.forEach(result => {
      if (result.rawValue != null) {
        const difference = (result.rawValue as number) - (v.average.rawValue as number);
        differences.push(Math.pow(difference, 2));
      }
    });
    if (differences.length === 0) return 0;
    const variance = differences.reduce((a, b) => a + b) / v.numberOfOccurances;
    const standardDeviation = Math.sqrt(variance);
    return Math.round((standardDeviation + Number.EPSILON) * 100) / 100;
  }

  private calculateAverage(sum: number, numberOfOccurances: number, calculationOptions: any) {
    if (sum == null || !numberOfOccurances) {
      return null;
    }
    let average = null;
    switch (calculationOptions?.averageType ?? 'mean') {
      case 'mean':
        average = parseFloat((sum / numberOfOccurances).toFixed(2));
        break;
      case 'median':
        average = null;
        break;
      case 'mode':
        average = null;
        break;
    }
    return average;
  }

  private addAssessmentValueForIndividual(
    userId: string,
    assessmentField: string,
    value: number,
    assessmentDate: moment.Moment,
    calculationsContainer: CalculationsValueContainer
  ) {
    if (!calculationsContainer.individualFieldsByPatient.has(assessmentField)) {
      calculationsContainer.individualFieldsByPatient.set(
        assessmentField,
        new Map<string, Map<string, Result>>()
      );
    }
    if (!calculationsContainer.individualFieldsByPatient.get(assessmentField).has(userId)) {
      calculationsContainer.individualFieldsByPatient
        .get(assessmentField)
        .set(userId, new Map<string, Result>());
    }
    if (
      !calculationsContainer.individualFieldsByPatient
        .get(assessmentField)
        .get(userId)
        .has(assessmentDate.format(this.calculationsDefinitions.GlobalFlags.dateKeyFormat)) ||
      calculationsContainer.individualFieldsByPatient
        .get(assessmentField)
        .get(userId)
        .get(assessmentDate.format(this.calculationsDefinitions.GlobalFlags.dateKeyFormat))
        .rawValue == null
    ) {
      calculationsContainer.individualFieldsByPatient
        .get(assessmentField)
        .get(userId)
        .set(
          assessmentDate.format(this.calculationsDefinitions.GlobalFlags.dateKeyFormat),
          new Result(value, UnitType.Number)
        );
    }
  }

  private convertAssessmentTimeValueToMinutes(assessmentValue: string): number {
    if (assessmentValue == null) {
      return null;
    }

    const hours: number = parseInt(assessmentValue.split(':')[0], 10);
    const minutes: number = parseInt(assessmentValue.split(':')[1], 10);
    return hours * 60 + minutes;
  }

  //#region "Retrieve Field Definitions"
  private getAssessmentFields(
    assessmentFields: Set<AssessmentFieldDefinition>,
    calculationsContainer: CalculationsValueContainer
  ): void {
    // for each in basic list
    assessmentFields.forEach(basicFieldTemplate => {
      this.addField(basicFieldTemplate, calculationsContainer);
    });
  }

  private getCalculatedFields(
    calculatedFields: Set<CalculatedFieldDefinition>,
    assessmentFields: Set<AssessmentFieldDefinition>,
    assessmentTypes: Set<string>,
    calculationsContainer: CalculationsValueContainer
  ): void {
    const tmpList: Set<CalculatedFieldDefinition> = new Set(calculatedFields);
    calculatedFields.forEach(calculatedField => {
      this.processCalculatedField(
        calculatedField,
        calculatedFields,
        assessmentFields,
        tmpList,
        assessmentTypes,
        calculationsContainer
      );
    });

    tmpList.forEach(tmpList.add, calculatedFields);
  }

  private async getAssessments(
    startDate: moment.Moment,
    endDate: moment.Moment,
    assessmentTypes: Set<string>,
    patientIds: Array<string>,
    treatmentPhases: Array<string>
  ): Promise<Array<Assessment>> {
    let assessments: Array<Assessment>;
    if (this.networkService.isOnline()) {
      assessments = await this.assessmentService.getAllAsync(
        this.getAssessmentQuery(startDate, endDate, assessmentTypes, patientIds, treatmentPhases)
      );
    } else {
      let assessmentsCache = this.cacheService.get(this.ASSESSMENT_CACHE);
      if (assessmentsCache) {
        assessments = assessmentsCache?.filter(
          assessment =>
            assessment.groups.find(g => assessmentTypes.has(g.type)) &&
            moment(assessment.assessmentDate).isSameOrAfter(moment(startDate)) &&
            assessment.percentageComplete === 100
        );
      }
    }
    return assessments;
  }

  private processCalculatedField(
    calculatedField: CalculatedFieldDefinition,
    calculatedFields: Set<CalculatedFieldDefinition>,
    assessmentFields: Set<AssessmentFieldDefinition>,
    returnList: Set<CalculatedFieldDefinition>,
    assessmentTypes: Set<string>,
    calculationsContainer: CalculationsValueContainer
  ): void {
    this.addField(calculatedField, calculationsContainer);
    calculatedField.calculations.forEach(calc => {
      calc.dataFields.forEach(df => {
        const template = this.getAssessessmentFieldTemplate(df.dataFieldName);
        this.addField(template, calculationsContainer);
        if (template._type === FieldType.Calculated) {
          const calcTemplate = template as CalculatedFieldDefinition;
          returnList.add(calcTemplate);
          if (calcTemplate.calculations.length > 0) {
            this.processCalculatedField(
              calcTemplate,
              calculatedFields,
              assessmentFields,
              returnList,
              assessmentTypes,
              calculationsContainer
            );
          }
        } else if (
          template._type === FieldType.Assessment ||
          template._type === FieldType.AssessmentGroupScore
        ) {
          assessmentFields.add(template as AssessmentFieldDefinition);
          assessmentTypes.add((template as AssessmentFieldDefinition).assessmentType);
        } else {
          throw new Error('Type not supported for this operation!');
        }
      });
    });
  }

  private addField(field: FieldDefinitionBase, calculationsContainer: CalculationsValueContainer) {
    if (!calculationsContainer.individualFields.assessmentValues.has(field.name)) {
      const fieldValueContainer = new AssessmentValueDefinition(field.unit);
      calculationsContainer.individualFields.assessmentValues.set(field.name, fieldValueContainer);
    } else {
      //const fieldContainer = (this.calculationsContainer.individualFields.assessmentValues.get(field.name) as AssessmentValueDefinition);
      // fieldContainer.sum = 0;
      // fieldContainer.average = 0;
      // fieldContainer.numberOfOccurances = 0;
    }
  }

  //#endregion

  private getAssessmentGroupScore(
    assessment: Assessment,
    groupName: string,
    template?: AssessmentGroupScoreFieldDefinition
  ): number {
    if (!assessment) {
      return null;
    }

    const group = assessment.groups.find(g => g.type === groupName);
    if (!group || !group.answers || group.answers.length === 0) {
      return null;
    }
    let score = 0;
    let notAnsweredCount = 0;
    let lowAnswer = Number.MAX_SAFE_INTEGER;
    group.answers.map(a => {
      if (typeof a.value === 'boolean') {
        if (a.value) {
          score++;
        }
      } else if (a.value != null && a.value !== 'N/A') {
        score += parseInt(a.value, 10);
        if (a.value < lowAnswer) {
          lowAnswer = a.value;
        }
      } else {
        notAnsweredCount++;
      }
    });
    if (template?.aggregateType === 'lowest') {
      return lowAnswer;
    } else {
      return notAnsweredCount > 0 ? null : score;
    }
  }

  private getAssessmentValue(
    assessment: Assessment,
    template: AssessmentFieldDefinition,
    fieldType: FieldType
  ) {
    const group = assessment.groups.find(grp => grp.type === template.assessmentType);
    if (!group) {
      return null;
    }

    let val: boolean | number | string | Array<number>;
    if (fieldType === FieldType.AssessmentGroupScore) {
      val = this.getAssessmentGroupScore(assessment, template.assessmentType, template);
    } else {
      let answer = group.answers.find(answer => answer.uniqueAnswerId === template.answerId);

      if (!answer) {
        // Look for answer in conditional questions if there are any
        const potentialAnswers = group.answers.reduce((acc, ans, index) => {
          if (ans.conditionalQuestionAnswers) {
            return [...acc, ...ans.conditionalQuestionAnswers];
          }
          return acc;
        }, []);

        answer = potentialAnswers.find(ans => ans.uniqueAnswerId === template.answerId);
      }

      if (!answer || answer.value == null || answer.value === '') {
        return null;
      }

      if (answer.value == null) {
        val = null;
      } else {
        switch (template.unit) {
          case UnitType.Time:
          case UnitType.MorningTime:
          case UnitType.EveningTime:
            if (!isNaN(answer.value)) {
              val = answer.value * 60;
            } else {
              val = answer.value as string;
              val = this.convertAssessmentTimeValueToMinutes(val);
            }
            break;
          case UnitType.Number:
          case UnitType.Minutes:
            val = Number.parseInt(answer.value);
            break;
          case UnitType.Hours:
            val = Number.parseFloat(answer.value) * 60;
            break;
          // store all time values in minutes
          case UnitType.Text:
            val = answer.value;
            break;
          case UnitType.YesNo:
            val = this.getTrueFalse(answer.value);
            break;
          case UnitType.Percent:
            val = 1;
          case UnitType.Multi:
            if (answer.value) val = answer.value as Array<any>;
            break;
        }
      }
    }
    if (val === '0') val = 0;
    return val;
  }

  private setCalculatedValue(
    fieldDef: FieldDefinitionBase,
    assessmentDate: moment.Moment,
    calculationsContainer: CalculationsValueContainer
  ) {
    // basic fields are populated, so let's get values we need
    const calcFieldDef = fieldDef as CalculatedFieldDefinition;
    const dependencyFieldValues: Array<any> = [];
    calcFieldDef.calculations.forEach(calculatedField => {
      calculatedField.dataFields.forEach(field => {
        let value: any = null;
        if (field.dataFieldName != null) {
          value = calculationsContainer.individualFields.assessmentValues
            .get(field.dataFieldName)
            .values.get(
              assessmentDate.format(this.calculationsDefinitions.GlobalFlags.dateKeyFormat)
            )?.rawValue;
        } else {
          value = field.value;
        }
        if (value != null) {
          dependencyFieldValues.push(value);
        }
      });

      const finalValue: number = this.getCalculatedValue(
        calculatedField,
        dependencyFieldValues,
        calcFieldDef
      );
      if (
        finalValue != null &&
        (finalValue !== 0 || calcFieldDef.includeZeroValuesInAggregates !== false)
      ) {
        (calculationsContainer.individualFields.assessmentValues.get(calcFieldDef.name).sum
          .rawValue as number) += finalValue;

        calculationsContainer.individualFields.assessmentValues.get(calcFieldDef.name)
          .numberOfOccurances++;
      }
      calculationsContainer.individualFields.assessmentValues
        .get(calcFieldDef.name)
        .values.set(
          assessmentDate.format(this.calculationsDefinitions.GlobalFlags.dateKeyFormat),
          new Result(finalValue, fieldDef.unit)
        );
    });
  }

  private setCalculatedValuesForIndividuals(
    calculatedFields: Set<CalculatedFieldDefinition>,
    assessmentDate: moment.Moment,
    calculationsContainer: CalculationsValueContainer
  ) {
    const assessmentDateString = assessmentDate.format(
      this.calculationsDefinitions.GlobalFlags.dateKeyFormat
    );
    Array.from(calculatedFields)
      .sort((a, b) => (a.priority > b.priority ? 1 : -1))
      .forEach(calcField => {
        // field > patient > date
        calculationsContainer.individualFieldsByPatient
          .get(calcField.name)
          .forEach((patientMap, patientId) => {
            const dependencyFieldValues = [];
            calcField.calculations.forEach(calc => {
              calc.dataFields.forEach(dataField => {
                dependencyFieldValues.push(
                  calculationsContainer.individualFieldsByPatient
                    .get(dataField.dataFieldName)
                    .get(patientId)
                    .get(assessmentDateString).rawValue
                );
              });
              let finalAnswer: number = null;
              if (dependencyFieldValues.find(v => v != null)) {
                switch (calc.operation) {
                  case OperationType.Add:
                    finalAnswer = dependencyFieldValues.reduce((a, b) => a + b);
                    break;
                  case OperationType.Subtract:
                    finalAnswer = dependencyFieldValues.reduce((a, b) => a - b);
                    break;
                  case OperationType.Divide:
                    try {
                      finalAnswer = dependencyFieldValues.reduce((a, b) => a / b);
                    } catch {
                      finalAnswer = 0;
                    }
                    break;
                }
              }
              if (finalAnswer < 0) {
                if (
                  calcField.name !== 'total-sleep-minus-latency' &&
                  calcField.name !== 'sleep-efficiency'
                ) {
                  //don't add this with 24x60
                  finalAnswer = 24 * 60 + finalAnswer;
                }
              }
              calculationsContainer.individualFieldsByPatient
                .get(calcField.name)
                .get(patientId)
                .set(assessmentDateString, new Result(finalAnswer, UnitType.Number));
            });
          });
      });
  }

  private setCalculatedValues(
    calculatedFields: Set<CalculatedFieldDefinition>,
    assessmentDate: moment.Moment,
    calculationsContainer: CalculationsValueContainer
  ) {
    // calculated fields
    Array.from(calculatedFields)
      .sort((a, b) => (a.priority > b.priority ? 1 : -1))
      .forEach(calcField => {
        if (
          calculationsContainer.individualFields.assessmentValues
            .get(calcField.name)
            .values.get(
              assessmentDate.format(this.calculationsDefinitions.GlobalFlags.dateKeyFormat)
            ) == null
        ) {
          this.setCalculatedValue(calcField, assessmentDate, calculationsContainer);
        }
      });
  }

  public getAssessessmentFieldTemplate(fieldName: string) {
    let fieldTemplate = this.calculationsDefinitions.AssessmentDefinitions.find(
      a => a.name === fieldName
    );
    if (!fieldTemplate) {
      fieldTemplate = this.calculationsDefinitions.CalculatedFieldDefinitions.find(
        a => a.name === fieldName
      );
    }
    return fieldTemplate;
  }

  private cacheAssessment(assessment: Assessment) {
    if (assessment && this.networkService.isOnline() && this.platform.is('mobile')) {
      let cachedAssessments = <Array<Assessment>>this.cacheService.get(this.ASSESSMENT_CACHE);
      if (cachedAssessments == null) {
        cachedAssessments = [];
      }
      const found = cachedAssessments?.find(assess => {
        // Note we sometimes have "id" which is a guid, but we fallback to "Id"
        const guidIdMatches =
          assessment.hasOwnProperty('id') &&
          assess?.id != undefined &&
          assess?.id === assessment?.id;
        const numIdMatches =
          assess?.Id != undefined &&
          assessment['Id'] != undefined &&
          assess?.Id === assessment['Id'];
        // Or if assessmentDate match, this is to avoid duplicate
        let startDate: string = null;
        if (assessment.startTime) {
          if (typeof assessment.startTime.toISOString === 'function') {
            startDate = assessment.startTime.toISOString().split('.')[0] + 'Z'; //BLE transfer truncated the milliseconds, so ignore it for comparison
          } else {
            startDate = new Date(assessment.startTime).toISOString().split('.')[0] + 'Z';
          }
        }
        let aStartDate = null;
        if (assess.startTime) {
          if (typeof assess.startTime.toISOString === 'function') {
            aStartDate = assess.startTime.toISOString().split('.')[0] + 'Z';
          } else {
            aStartDate = new Date(assess.startTime).toISOString().split('.')[0] + 'Z';
          }
        }
        const dateMatches = startDate && aStartDate && startDate === aStartDate;

        if (guidIdMatches || numIdMatches || dateMatches) {
          return true;
        }
      });
      if (!found) {
        cachedAssessments.push(assessment);
      }
      this.cacheService.set(this.ASSESSMENT_CACHE, cachedAssessments);
    }
  }

  private getTrueFalse(strValue: string | boolean): boolean {
    return !!strValue || strValue === 'true' || strValue === 'yes' || strValue === 'True';
  }

  private getCalculatedValue(
    calculatedField: Calculation,
    dependencyFieldValues: Array<any>,
    fieldDef: CalculatedFieldDefinition
  ): number {
    // prep values for proper operation
    let finalValue: number = null;
    if (dependencyFieldValues.length > 0) {
      if (
        fieldDef.unit === UnitType.Time ||
        fieldDef.unit === UnitType.MorningTime ||
        fieldDef.unit === UnitType.EveningTime ||
        fieldDef.unit === UnitType.Hours
      ) {
        finalValue = dependencyFieldValues.reduce((firstTime, secondTime) => {
          if (calculatedField.operation === OperationType.Subtract) {
            const twelveHour = 12 * 60;
            const totalTime = Math.abs(firstTime - secondTime);
            return totalTime > twelveHour ? twelveHour - (totalTime - twelveHour) : totalTime;
          } else if (calculatedField.operation === OperationType.Add) {
            const twentyFourHour = 24 * 60;
            const totalTime = firstTime + secondTime;
            return totalTime > twentyFourHour ? totalTime - twentyFourHour : totalTime;
          }
        });
        // Note: assume first value being passed in is the morning time
      } else {
        switch (calculatedField.operation) {
          case OperationType.Add:
            finalValue = dependencyFieldValues.reduce((a, b) => a + b);
            break;
          case OperationType.Subtract:
            finalValue = dependencyFieldValues.reduce((a, b) => a - b);
            break;
          case OperationType.Multiply:
            finalValue = dependencyFieldValues.reduce((a, b) => a * b);
            break;
          case OperationType.Divide:
            finalValue = dependencyFieldValues.reduce((a, b) => (b != 0 && b != null ? a / b : 0));
            break;
        }
      }
    }
    if (finalValue < 0) {
      if (fieldDef.name !== 'total-sleep-minus-latency' && fieldDef.name !== 'sleep-efficiency') {
        //don't add this with 24x60
        finalValue = 24 * 60 + finalValue;
      } else {
        finalValue = 0;
      }
    }
    return finalValue;
  }

  private getDateArray(startDate: moment.Moment, endDate: moment.Moment): Array<moment.Moment> {
    const dateArray: Array<moment.Moment> = new Array<moment.Moment>();
    let seedDate = moment(endDate); //.startOf('day');
    do {
      dateArray.push(seedDate);
      seedDate = moment(seedDate).subtract(1, 'day');
    } while (seedDate.isSameOrAfter(startDate, 'd'));
    return dateArray;
  }

  private adjustAllTimesforTimeZone(object: any, fields: string[]) {
    fields?.map(f => {
      if (object[f] !== 'NaN:NaN' && object[f] !== 'Nan') {
        if (object[f] && object[f].indexOf(':') !== 0) {
          const hours = parseInt(object[f].split(':')[0], 10);
          const minutes = parseInt(object[f].split(':')[1], 10);

          object[f] = moment.utc(object.assessmentDate).hours(hours).minutes(minutes);
          object[f].utcOffset(object.timeZoneOffset ?? 240);
        }
      }
    });
  }

  async doSomething(userId: string, sleepDailyOutcomes: any[], treatmentStartDate?: string, timeZoneOffset?: number) {
    let sumGNTh = 0;
    let sumGNTm = 0;
    let sumGMTh = 0;
    let sumGMTm = 0;
    let sumLatency = 0;
    let sumWASO = 0;
    let sumEMA = 0;
    let sumTIB = 0;
    let sumAsleep = 0;
    let sumSE = 0;
    let sumSQ = 0;
    let count = 0;

    const sleepLogs = [];
    const sleepIds = [];
    this.assessments = await this.assessmentService.getAllAsync({
      'Payload.user.id': userId
    });

    let morningLogCount = 0;
    for (const sleepDailyOutcome of sleepDailyOutcomes) {
      if (sleepDailyOutcome.bedTime && sleepDailyOutcome.bedTime !== '') {
        sleepLogs.push(sleepDailyOutcome);
        sleepIds.push(sleepDailyOutcome.id);

        if (sleepDailyOutcome.morning.id !== 0) {
          morningLogCount++;
        }

        let hr = this.calculateTime(sleepDailyOutcome, sleepDailyOutcome.bedTime).hours();
        if (hr <= 12) {
          // adjust for cross days
          hr += 24;
        }
        if (count < 7) {
          if (sleepDailyOutcome.riseTime) {
            sumGMTh += this.calculateTime(sleepDailyOutcome, sleepDailyOutcome.riseTime).hours();
            sumGMTm += this.calculateTime(sleepDailyOutcome, sleepDailyOutcome.riseTime).minutes();
          }

          if (sleepDailyOutcome.goodNightTime) {
            sumGNTm += this.calculateTime(
              sleepDailyOutcome,
              sleepDailyOutcome.goodNightTime
            ).minutes();
          }
          sumGNTh += hr;
          if (sleepDailyOutcome.latency) {
            sumLatency += sleepDailyOutcome.latency;
          }
          if (sleepDailyOutcome.waso) {
            sumWASO += sleepDailyOutcome.waso ?? 0;
          }
          sumTIB +=
            sleepDailyOutcome.asleep ?? 0
            + sleepDailyOutcome.latency ?? 0
            + sleepDailyOutcome.wakeTimeAfterSleepOnset ?? 0
            + sleepDailyOutcome.ema ?? 0;
          if (sleepDailyOutcome.asleep) {
            sumAsleep += sleepDailyOutcome.asleep;
          }
          if (sleepDailyOutcome.sleepEfficiency) {
            sumSE += sleepDailyOutcome.sleepEfficiency;
          }
          if (sleepDailyOutcome.sleepQuality) {
            sumSQ += sleepDailyOutcome.sleepQuality;
          }
          count++;
        }
      }
    }
    const date7DaysPrior: moment.Moment = moment().utc().subtract(8, 'days');

    const firstSevenSleepLogs = sleepLogs
      .filter(a => moment.utc(a.bedTimeDate).isAfter(date7DaysPrior, 'days'))
      .sort((a, b) =>
        moment
          .utc(a.bedTimeDate)
          .utcOffset(a.timeZoneOffset ?? 240)
          .diff(b.bedTimeDate, 'days')
      );

    const diaryAverages = await this.calcAverages(
      sumGNTh,
      sumGNTm,
      sumGMTh,
      sumGMTm,
      sumLatency,
      sumWASO,
      sumEMA,
      sumTIB,
      sumAsleep,
      sumSE,
      sumSQ,
      count,
      firstSevenSleepLogs,
      userId,
      morningLogCount
    );
    // sleepPRescription + sleepids = gets average reccomendations
    // sleepPRescription = gets all prescription entries, have to filter on date
    const prescriptionEntry = {
      weeks: []
    };
    const sleepPrescriptions = await this.getSleepPrescriptions(
      userId,
      sleepLogs,
      treatmentStartDate
    );

    const eventquery = {
      $and: [
        { 'Payload.user.id': userId },
        {
          $and: [
            { 'Payload.phaseName': { $exists: true } },
            { 'Payload.phaseName': { $ne: 'checkin' } }
          ]
        }
      ]
    };
    const weeklyevents = await this.calendarEventService.getAllAsync(eventquery);
    const weekInstances = await this.getWeekInstances(
      userId,
      sleepLogs,
      weeklyevents,
      sleepPrescriptions,
      diaryAverages.sleepTacticRecommendations,
      timeZoneOffset
    );
    weekInstances.sort((a, b) => a.startDate - b.startDate);
    prescriptionEntry.weeks = weekInstances;
    const treatmentPlan = prescriptionEntry;
    const activeWeek = _find(prescriptionEntry.weeks, week => week.isActiveWeek);

    return {
      currentWeek: activeWeek ? activeWeek : prescriptionEntry.weeks[0],
      diaryAverages,
      treatmentPlan,
      weeklyevents
    };
  }

  public calculateTime(assessment: Assessment, date: string): moment.Moment {
    const returnDate: moment.Moment = moment.utc(date);

    returnDate.utcOffset(assessment.timeZoneOffset ?? 240);
    return moment.utc(returnDate); // create new instance to make sure we're not affecting a
  }

  public async getSleepPrescriptions(userId, sleepLogs, treatmentStartDate?: string): Promise<any> {
    const now = new Date();
    const convertedPrescriptions = [];
    const cutoffDate = treatmentStartDate || now.toISOString().split('T')[0];
    const spquery = {
      $and: [{ 'Payload.patient.id': userId }, { 'Payload.endDate': { $gte: cutoffDate } }]
    };
    const sleepPrescriptions = await this.sleepPrescriptionService.getAll(spquery).toPromise(); // TODO: should do getALl and then filter on filter on startDates < now, should be the most current entry
    for (const sleepPrescription of sleepPrescriptions) {
      if (sleepPrescription) {
        let latencyCount = 0;
        let wtasoCount = 0;
        let seSum = 0;
        let seCount = 0;
        let restfulsleepitem = 0;
        let isRacingMind = false;
        let gmthSum = 0;
        let gmtmSum = 0;
        let gmthAvg = 0;
        let gmtmAvg = 0;
        let sumAsleep = 0;
        let avgAsleep = 0;
        for (const sleepLog of sleepLogs) {
          if (sleepLog.atency > 30) {
            latencyCount++;
          }
          if (sleepLog.waso > 30) {
            wtasoCount++;
          }
          seSum += sleepLog.sleepEfficiency;
          seCount++;
          if (seCount === 1) {
            // last night
            const racingMind = _find(
              sleepLog.morning.answers,
              answer => answer.uniqueAnswerId === 'RACING_MIND'
            );
            if (sleepLog.sleepQty != null) {
              restfulsleepitem = sleepLog.sleepQty;
            }
            if (racingMind && racingMind.value != null) {
              isRacingMind = racingMind.value;
            }
          }
          const wakeTime = _find(
            sleepLog.morning.answers,
            answer => answer.uniqueAnswerId === 'RISE_TIME'
          );
          const gmt = new Date();
          if (wakeTime && wakeTime.value) {
            gmt.setHours(wakeTime.value[0] + wakeTime.value[1]);
            gmt.setMinutes(wakeTime.value[3] + wakeTime.value[4]);
            gmthSum += gmt.getHours();
            gmtmSum += gmt.getMinutes();
          }

          sumAsleep += sleepLog.asleep;
        }
        let seAvg = 100;
        if (seCount > 0) {
          seAvg = seSum / seCount;
          gmthAvg = gmthSum / seCount;
          const decGmth = gmthAvg - Math.floor(gmthAvg);
          gmthAvg = Math.floor(gmthAvg);
          gmtmAvg = gmtmSum / seCount + 60 * decGmth;
          if (gmtmAvg >= 60) {
            gmtmAvg -= 60;
            gmthAvg += 1;
          }
          gmtmAvg = Math.round(gmtmAvg);
          avgAsleep = sumAsleep / seCount;
        }

        if (
          latencyCount > 2 ||
          wtasoCount > 2 ||
          seAvg < 85 ||
          (restfulsleepitem < 50 && restfulsleepitem > 0)
        ) {
          sleepPrescription.sDocType = sleepPrescription.sDocType
            ? sleepPrescription.sDocType
            : '1';
        } else {
          sleepPrescription.sDocType = sleepPrescription.sDocType + '1'; // TODO: revisit bitwise
        }
        if (latencyCount > 2 && isRacingMind) {
          sleepPrescription.sDocType = sleepPrescription.sDocType
            ? sleepPrescription.sDocType
            : '2';
        } else {
          sleepPrescription.sDocType = sleepPrescription.sDocType + '2';
        }

        if (sleepPrescription.id === '0') {
          sleepPrescription.sDocType = sleepPrescription.sDocType;
        }
        convertedPrescriptions.push(sleepPrescription);
      }
    }
    return convertedPrescriptions;
  }

  private padTimeStr(i) {
    if (i.toString().length === 1) {
      return '0' + i;
    } else {
      return i;
    }
  }

  // SleepPrescription might need 2 new fields added
  private async getWeekInstances(
    userId: string,
    events: any[],
    allWeeks: any[],
    sleepPrescriptions: any[],
    tacticRecommendations: any,
    timeZoneOffset?: number,
  ) {
    const weekInstances = [];
    const impressions = this.assessments.filter((res) => res.groups.some((group) => group.type === 'cgi'));
    const assessments = this.assessments.filter(res => res.groups.some((group) => (group.type !== 'cgi') && (group.type !== 'morningLog') && (group.type !== 'eveningLog')));
    for (const event of allWeeks) {
      let thisWeek;
      if (sleepPrescriptions.length > 0) {
        thisWeek = _find(sleepPrescriptions, week => week.phaseName === event.phaseName);
        // if (thisWeek) { this.adjustAllTimesforTimeZone(thisWeek, ['riseTime', 'bedTime']); }
      }
      // need to use utc time as the views for startDate and endDate for the weeks all assume it is in UTC for some reasons
      const startDate = moment.utc(event.startDate).startOf('day');
      let endDate = moment.utc(event.endDate).endOf('day');
      const nowDate = moment.utc().add(timeZoneOffset?timeZoneOffset:moment().utcOffset(), 'm');
      let weekInstance: any = {};
      let hasPrescription = true;

      if (!thisWeek) {
        // no prescriptionEntry for this, setup fake one for display\
        hasPrescription = false;
        thisWeek = this.buildWeekEntry();
        thisWeek.startDate = startDate;
        thisWeek.endDate = endDate;
        thisWeek.patient = event.user;
        thisWeek.phaseName = event.phaseName;
        (thisWeek.name = event.title.replace(/\[.*?\]/, '')), //   remove between brackets
          (thisWeek.isUpcoming = nowDate.isBefore(moment(startDate)));
        thisWeek.isActiveWeek =
          nowDate.isSameOrAfter(startDate, 'day') && nowDate.isSameOrBefore(endDate, 'day');
        (thisWeek.isPast = nowDate.isAfter(endDate)), (thisWeek.isYield = thisWeek.isActiveWeek);
        thisWeek.patientFlags = [
          {
            label: 'Sleep Intensity',
            name: 'IsRestless',
            isSelected: false,
            isRecommended: false,
            icon: 'fas fa-bed'
          },
          {
            label: 'Thinking Efficiency',
            name: 'IsThinking',
            isSelected: false,
            isConsiderable: false,
            isRecommended: false,
            icon: 'fab fa-think-peaks'
          },
          {
            label: 'Dream ReTraining',
            name: 'IsNightmare',
            isSelected: false,
            isRecommended: false,
            icon: 'fas fa-brain'
          },
          {
            label: 'Clock ReTraining',
            name: 'IsClockRetraining',
            isSelected: (thisWeek.docType & 8) == 8,
            isRecommended: false,
            isConsiderable: false,
            icon: 'fas fa-hourglass'
          },
          {
            label: 'Fatigue Countermeasures',
            name: 'IsFatgiueCounter',
            isSelected: (thisWeek.docType & 16) == 16,
            isRecommended: false,
            isConsiderable: false,
            icon: 'fas fa-tired'
          },
          // { label: 'Sleep Goals', name: 'IsSleepGoal', isSelected: (thisWeek.thisWeek & 32) == 32, isRecommended: false, icon: 'fas fa-bullseye' },
          {
            label: 'Stress Reduction',
            name: 'IsStressReduction',
            isSelected: (thisWeek.docType & 64) == 64,
            isRecommended: false,
            isConsiderable: false,
            icon: 'fas fa-level-down-alt'
          },
          {
            label: 'Thought Substitution',
            name: 'IsThoughtSubstitution',
            isSelected: false,
            isRecommended: false,
            // TODO: what is the effect of the isConsiderable flag?
            // isConsiderable: false,
            icon: 'fas fa-lightbulb'
          }
          // EW - This requirement could appear in the future { label: 'OSA', name: 'IsOSA', isSelected: false, isRecommended: false },
        ];
      }
      const impressionInfo = _find(
        impressions,
        impression => impression.phaseName === event.phaseName
      );
      const assessmentInfo = _filter(
        assessments,
        assessment => assessment.phaseName === event.phaseName
      );
      let bedTime = '';
      if (thisWeek.bedTime && moment(thisWeek.bedTime, 'HH:mm').isValid()) {
        bedTime = moment.utc(thisWeek.bedTime, 'HH:mm').format('HH:mm');
      }
      let riseTime = '';
      if (thisWeek.riseTime && moment(thisWeek.riseTime, 'HH:mm').isValid()) {
        if (thisWeek.riseTime.length === 3) {
          thisWeek.riseTime = `0${thisWeek.riseTime}`;
        }
        riseTime = moment.utc(thisWeek.riseTime, 'HH:mm').format('HH:mm');
      }
      let timeInBed = '';
      if (
        thisWeek.riseTime &&
        moment(thisWeek.riseTime, 'HH:mm').isValid() &&
        thisWeek.bedTime &&
        moment(thisWeek.bedTime, 'HH:mm').isValid()
      ) {
        timeInBed = this.calculateTIB(bedTime, riseTime);
      }

      // This can be removed when we no longer have legacy events in the database
      if (event?.isLegacy === undefined &&
        (event.phaseName === 'weekFive' ||
          event.phaseName === 'followup' ||
          event.phaseName === 'followup2' ||
          event.phaseName === 'followup3')
      ) {
        endDate = this.legacyService.getLegacyEndDate(event, allWeeks);
      }

      const hasImpressionInfo = !!impressionInfo;

      // Baseline will never have "event.isComplete" as true becuase it'll
      // never have a prescription make it so.
      const isComplete =
        event.phaseName === 'baseline'
          ? hasImpressionInfo && assessmentInfo.length > 0
          : event.isComplete && hasImpressionInfo;
      weekInstance = {
        id: thisWeek.Id,
        eventId: event.Id,
        weekType: event.phaseName,
        phaseName: event.phaseName,
        note: thisWeek.note,
        lastCallLoggedOn: thisWeek.lastCallLoggedOn,
        name: event.title.replace(/\[.*?\]/, '').replace(/^\s+/, ''), // Remove between brackets //hack to rename Week 5
        isComplete,
        isError: (!isComplete || !hasImpressionInfo) && nowDate.isAfter(endDate, 'days'),
        isYield: nowDate.isSameOrAfter(startDate, 'day') && nowDate.isSameOrBefore(endDate, 'day'), // activeWeek
        isOpen: false,
        isUpcoming: nowDate.isBefore(moment(startDate)),
        isActiveWeek:
          nowDate.isSameOrAfter(startDate, 'day') && nowDate.isSameOrBefore(endDate, 'day'),
        isPast: nowDate.isAfter(endDate),
        prescriptionComplete: hasPrescription || thisWeek.LastModified || event.phaseName === 3,
        impressionComplete: hasImpressionInfo,
        assessmentComplete: assessmentInfo.length > 0 ? true : false,
        startDate,
        endDate,
        bedTime,
        bedTimeSuggested: this.isTimeNull(thisWeek.sBedTime) ? '--:--' : thisWeek.sBedTime,
        outOfBed: riseTime,
        outOfBedSuggested: this.isTimeNull(thisWeek.sRiseTime) ? '--:--' : thisWeek.sRiseTime,
        timeInBed,
        timeInBedSuggested:
          this.isTimeNull(thisWeek.sBedTime) || this.isTimeNull(thisWeek.sRiseTime)
            ? '--:--'
            : this.calculateTIB(thisWeek.sBedTime, thisWeek.sRiseTime),
        useWakeAlarm: !!thisWeek.useWakeAlarm,
        clientTimeZoneOffset: thisWeek.clientTimeZoneOffset,
        thisWeek,
        canBeDeleted: event.canBeDeleted
      };
      this.setPatientFlags(weekInstance, tacticRecommendations);
      weekInstance.impressionInfo = impressionInfo ? impressionInfo : {};
      weekInstance.impressionAnswers = impressionInfo
        ? this.impressionSetup(impressionInfo.groups[0].answers)
        : this.impressionSetup([]);
      weekInstance.assessmentInfo = assessmentInfo ? assessmentInfo : {};
      weekInstances.push(weekInstance);
    }

    return weekInstances;
  }

  /**
   * Take the lower level "thisWeek" patient flag data and bring it to
   * the top level with some tweaks
   * @param wkInstance The week
   * @param tacticRecommendations Recommendation values
   */
  public setPatientFlags(wkInstance: any, tacticRecommendations: any): void {
    // Due to the fact that there is a week instance inside of the wkInstance,
    // The real flag data is in that.

    const tweakedPatientFlagData = wkInstance.thisWeek.patientFlags?.map(flag => {
      let isRecommended = false;
      let isConsiderable = false;
      let isSelected = false;

      switch (flag.name) {
        case 'IsRestless':
          isRecommended = tacticRecommendations.sleepIntensity;
          isSelected = flag.isSelected;
          break;
        case 'IsThinking':
          isRecommended = tacticRecommendations.thinkingEfficiency;
          isConsiderable = tacticRecommendations.thinkingEfficiencyC;
          isSelected = flag.isSelected;
          break;
        case 'IsNightmare':
          isRecommended = tacticRecommendations.dreamRetraining;
          isSelected = flag.isSelected;
          break;
        case 'IsClockRetraining':
          isRecommended = tacticRecommendations.clockRetraining;
          isConsiderable = tacticRecommendations.clockRetrainingC;
          isSelected = flag.isSelected || (wkInstance.docType & 8) === 8;
          break;
        case 'IsFatgiueCounter':
          isRecommended = tacticRecommendations.fatigue;
          isConsiderable = tacticRecommendations.fatigueC;
          isSelected = flag.isSelected || (wkInstance.docType & 16) === 16;
          break;
        case 'IsStressReduction':
          isRecommended = tacticRecommendations.stressReduction;
          isConsiderable = tacticRecommendations.stressReductionC;
          isSelected = flag.isSelected || (wkInstance.docType & 64) === 64;
          break;
        case 'IsThoughtSubstitution':
          isRecommended = tacticRecommendations.thoughtSubstitution;
          isConsiderable = tacticRecommendations.thoughtSubstitutionC;
          isSelected = flag.isSelected;
          break;
        // EW - This requirement could appear in the future
        // case('IsOSA'):
        //   isRecommended = tacticRecommendations.osa;
        //   isSelected = flag.isSelected;
        //   break;
      }

      return {
        label: flag.label,
        name: flag.name,
        icon: flag.icon,
        isSelected,
        isRecommended,
        isConsiderable
      };
    });

    wkInstance.patientFlags = tweakedPatientFlagData;
  }

  private async getPatientAssessments(userId?) {
    let assessments;
    //check if userId param is included, this will signify it is being called from getRecommendedModules and that the assessment query has not been ran from loadPatient.
    if(userId){
      const spquery = {
        $and: [
          { 'Payload.user.id': userId },
          { 'Payload.groups.type': { $ne: 'morningLog' } },
          { 'Payload.groups.type': { $ne: 'eveningLog' } }
        ]
      };
      assessments = await this.assessmentService.getAllAsync(spquery);    
    } else {
      assessments = this.assessments.filter(res => res.groups.some((group) => (group.type !== 'morningLog') && (group.type !== 'eveningLog')));
    }
    return assessments;
  }

  private async getPatientFlagRecommendations(
    // NOTE: currently not recommending thoughtSubstitution
    userId: string,
    diaryAverages: any,
    sleepAssessments: any[],
    morningLogCount: number
  ): Promise<any> {
    const returnObj = {
      sleepIntensity: false,
      thinkingEfficiency: false,
      thinkingEfficiencyC: false,
      dreamRetraining: false,
      fatigue: false,
      fatigueC: false,
      stressReduction: false,
      stressReductionC: false,
      clockRetraining: false,
      clockRetrainingC: false
      // EW - This requirement could appear in the future osa: false
    };

    if (morningLogCount < 3) {
      return returnObj;
    }
    
    const assessments = (await this.getPatientAssessments()).sort((a, b) =>
      moment
        .utc(a.assessmentDate)
        .utcOffset(a.timeZoneOffset ?? 240)
        .diff(b.assessmentDate, 'days')
    );

    if (!assessments) {
      return returnObj;
    }

    const morningSleepLogs: Assessment[] = [];
    const eveningSleepLogs: Assessment[] = [];

    sleepAssessments.map(assessment => {
      if (assessment.morning.id && assessment.morning.id !== 0) {
        morningSleepLogs.push(assessment.morning);
      }
      if (assessment.evening.id && assessment.morning.id !== 0) {
        eveningSleepLogs.push(assessment.evening);
      }
    });

    const weeklyAssessments: Assessment[] = assessments.filter(a => a.phaseName !== null);
    const mostRecentFollowUpOrPost: Assessment = weeklyAssessments.find(
      a =>
        a.phaseName.toLowerCase().indexOf('follow') !== -1 ||
        a.phaseName.toLowerCase().indexOf('post') !== -1
    );
    const baselineAssessment: Assessment = weeklyAssessments.find(
      a => a.phaseName === 'baseline'
    );
    const mostRecentPatientAssessment: Assessment =
      weeklyAssessments && weeklyAssessments.length > 0
        ? weeklyAssessments[weeklyAssessments.length - 1]
        : null;

    // from baseline
    const baseshiftWorkCurrent: boolean = this.getAssessmentAnswer(
      baselineAssessment,
      'SHIFT_WORK_CURRENT',
      'boolean',
      'shiftWork'
    );
    const baseshiftWorkTypical: boolean = this.getAssessmentAnswer(
      baselineAssessment,
      'SHIFT_WORK_OUTSIDE_TYPICAL',
      'boolean',
      'shiftWork'
    );
    const baseshiftWorkChange: boolean = this.getAssessmentAnswer(
      baselineAssessment,
      'SHIFT_WORK_CHANGE',
      'boolean',
      'shiftWork'
    );
    const swpsAnswers = this.getAswpsAnswers(baselineAssessment);
    const baseDSWPS = this.checkForDSWPS(swpsAnswers); // new field, logic will be added as part of milestone 13 //no longer used after 1.5 core batteries update
    const baseASWS = this.checkForASWPS(swpsAnswers); // new field, logic will be added as part of milestone 13 //no longer used after 1.5 core batteries update

    const basePCL5Nightmares: number = this.getAssessmentAnswer(
      baselineAssessment,
      'PCL5_DREAMS',
      'number',
      'pcl5'
    );
    const basePCL5TroubleFallingAsleep: number = this.getAssessmentAnswer(
      baselineAssessment,
      'PCL5_SLEEP',
      'number',
      'pcl5'
    );
    const basePCL5HyperAlert: number = this.getAssessmentAnswer(
      baselineAssessment,
      'PCL5_ALERT',
      'number',
      'pcl5'
    );
    const baseJumpy: number = this.getAssessmentAnswer(
      baselineAssessment,
      'PCL5_JUMPY',
      'number',
      'pcl5'
    );
    const basePHQ8SleepProblems: number = this.getAssessmentAnswer(
      baselineAssessment,
      'PHQ_SLEEP',
      'number',
      'phq8'
    );
    const basePHQ8Tired: number = this.getAssessmentAnswer(
      baselineAssessment,
      'PHQ_FATIGUE',
      'number',
      'phq8'
    );
    const baseISIScore: number = this.getAssessmentGroupScore(baselineAssessment, 'isi');
    const baseGAD7Score: number = this.getAssessmentGroupScore(baselineAssessment, 'gad7');
    const baseGAD2Score: number = this.getAssessmentGroupScore(baselineAssessment, 'gad2'); // NOTE: April 2022, 1.5 baseline won't have gad7, switch it with gad2, keeping both for now for backward compatibility 

    // from lastest patient weekly assessment
    const latestWatchful: number = this.getAssessmentAnswer(
      mostRecentPatientAssessment,
      'PCL5_ALERT',
      'number',
      'pcl5'
    );
    const onGuardPCPTSD: boolean = this.getAssessmentAnswer(
      mostRecentPatientAssessment,
      'PCPTSD_ON_GUARD',
      'boolean',
      'pcPtsd5'
    );
    const latestGAD2Score: number = this.getAssessmentGroupScore(
      mostRecentPatientAssessment,
      'gad2'
    );
    const latestPTSD5Nightmares: boolean = this.getAssessmentAnswer(
      mostRecentPatientAssessment,
      'PCPTSD_NIGHTMARE',
      'boolean',
      'pcPtsd5'
    );

    // from followup/Post
    const followUpPCL5Nightmares: number = this.getAssessmentAnswer(
      mostRecentFollowUpOrPost,
      'PCL5_DREAMS',
      'number',
      'pcl5'
    );
    const followUpPCL5TroubleFallingAsleep: number = this.getAssessmentAnswer(
      mostRecentFollowUpOrPost,
      'PCL5_SLEEP',
      'number',
      'pcl5'
    );
    const followUpPHQ8SleepProblems: number = this.getAssessmentAnswer(
      mostRecentFollowUpOrPost,
      'PHQ_SLEEP',
      'number',
      'phq8'
    );
    const followUpPHQ8Tired: number = this.getAssessmentAnswer(
      mostRecentFollowUpOrPost,
      'PHQ_FATIGUE',
      'number',
      'phq8'
    );
    const followUpISIScore: number = this.getAssessmentGroupScore(mostRecentFollowUpOrPost, 'isi');

    let dreamsCount = 0;
    let nervousCount = 0;
    let tiredCount = 0;
    let racingThoughtsCount = 0;
    let dreamsBothered = false;

    morningSleepLogs?.map(l => {
      if (this.getAssessmentAnswer(l, 'HAD_DREAMS', 'boolean') === true) {
        if (this.getAssessmentAnswer(l, 'DREAMS_BOTHERED', 'boolean') === true) {
          dreamsCount++;
          dreamsBothered = true;
        }
      }
      if (this.getAssessmentAnswer(l, 'MORNING_FEEL', 'number') <= 2) {
        tiredCount++;
      }

      if (this.getAssessmentAnswer(l, 'RACING_MIND', 'boolean') === true) {
        racingThoughtsCount++;
      }
    });

    eveningSleepLogs?.map(l => {
      if (this.getAssessmentAnswer(l, 'NERVOUS_STRESSED', 'boolean') === true) {
        nervousCount++;
      }
    });

    // from multiple of previous week's
    const dreamsNightmares3X: boolean = dreamsCount >= 3;
    const nervousOrStressed3x: boolean = nervousCount >= 3;
    const racingThroughts3x: boolean = racingThoughtsCount >= 3;
    const dreamsOrNightmares: boolean = dreamsCount > 0 && dreamsBothered;
    const bedWakeWithin2Hours = false; // bed and wake time are both within 2 hours of socially desired,
    const lessThanTired5x: boolean = tiredCount >= 5; // "less than tired on morning log items at least 5/7 mornings
    // in previous week"

    // average of previous week's answers
    const avgLatency: number = diaryAverages.avgLatency;
    const avgWaso: number = diaryAverages.avgWASO;
    const avgEma: number = diaryAverages.avgEMA;
    const avgEfficiency: number = diaryAverages.avgSE;
    const avgTST: number = diaryAverages.avgAsleep;

    // Kept these values as null in the calculations so we could know they weren't populated and
    // and now need to set to zero for further operations
    if (!diaryAverages.avgLatency) {
      diaryAverages.avgLatency = 0;
    }
    if (!diaryAverages.avgWASO) {
      diaryAverages.avgWASO = 0;
    }
    if (!diaryAverages.avgEMA) {
      diaryAverages.avgEMA = 0;
    }
    if (!diaryAverages.avgSE) {
      diaryAverages.avgSE = 0;
    }
    if (!diaryAverages.avgAsleep) {
      diaryAverages.avgAsleep = 0;
    }

    // from any morning log from the week
    let tiredInMorning = false;
    let sleepyWhenGoingToBed = false;
    let dreamsBotheredMe = false;
    for (const assessment of morningSleepLogs) {
      if (this.getAssessmentAnswer(assessment, 'MORNING_FEEL', 'number') <= 2) {
        tiredInMorning = true;
      }
      if (this.getAssessmentAnswer(assessment, 'WAS_SLEEPY', 'boolean') === true) {
        sleepyWhenGoingToBed = true;
      }

      if (this.getAssessmentAnswer(assessment, 'DREAMS_BOTHERED', 'boolean') === true) {
        dreamsBotheredMe = true;
      }
    }

    let count = 0;

    const isSleepIntensityTacticRecommended = () => {
      const condition1 = baseISIScore >= 10 && followUpISIScore > 7;
      const condition2 = avgLatency && avgLatency >= 31;
      const condition3 = avgWaso && avgWaso >= 31;
      const condition4 = avgEfficiency && avgEfficiency < 85;
      const condition5 = basePCL5TroubleFallingAsleep >= 2 && followUpPCL5TroubleFallingAsleep >= 2;
      const condition6 = basePHQ8SleepProblems >= 1 && followUpPHQ8SleepProblems >= 1;

      if (
        (condition1 || condition2 || condition3 || condition4 || condition5 || condition6) &&
        count < 2
      ) {
        count++;
        return condition1 || condition2 || condition3 || condition4 || condition5 || condition6;
      } else {
        return false;
      }
    };

    const isThinkingEfficiencyTacticRecommended = () => {
      const condition1 = !sleepyWhenGoingToBed && avgLatency && avgLatency >= 30;
      const condition2 = racingThroughts3x;

      if ((condition1 || condition2) && count < 2) {
        count++;
        return condition1 || condition2;
      } else {
        return false;
      }
    };

    const isThinkingEfficiencyTacticConsiderable = () => {
      const condition1 = !sleepyWhenGoingToBed && avgLatency && avgLatency >= 30;
      const condition2 = racingThroughts3x;

      if ((condition1 || condition2) && count == 2 && returnObj.thinkingEfficiency != true) {
        count++;
        return condition1 || condition2;
      } else {
        return false;
      }
    };

    const isDreamRetrainingTacticRecommended = () => {
      const condition1 = dreamsOrNightmares && dreamsBotheredMe;
      const condition2 = dreamsNightmares3X;
      const condition3 = basePCL5Nightmares >= 2 && followUpPCL5Nightmares >= 2;
      const condition4 = latestPTSD5Nightmares;

      if ((condition1 || condition2 || condition3 || condition4) && count < 2) {
        count++;
        return condition1 || condition2 || condition3 || condition4;
      } else {
        return false;
      }
    };

    const isFatigueTacticRecommended = () => {
      const condition1 = avgTST && avgTST / 60 < 6;
      const condition2 = tiredInMorning;
      const condition3 = basePHQ8Tired >= 1 && followUpPHQ8Tired >= 1;

      if ((condition1 || condition2 || condition3) && count < 2) {
        count++;
        return condition1 || condition2 || condition3;
      } else {
        return false;
      }
    };

    const isFatigueTacticConsiderable = () => {
      const condition1 = avgTST && avgTST / 60 < 6;
      const condition2 = tiredInMorning;
      const condition3 = basePHQ8Tired >= 1 && followUpPHQ8Tired >= 1;

      if ((condition1 || condition2 || condition3) && count == 2 && returnObj.fatigue != true) {
        count++;
        return condition1 || condition2 || condition3;
      } else {
        return false;
      }
    };

    const isStressReductionTacticRecommended = () => {
      const condition1 = (baseGAD7Score >= 10 || baseGAD2Score>= 3) && latestGAD2Score >= 3;
      const condition2 = nervousOrStressed3x;
      const condition3 = basePCL5HyperAlert >= 2;
      const condition4 = baseJumpy >= 2 && latestWatchful >= 2;
      const condition5 = onGuardPCPTSD;

      if ((condition1 || condition2 || condition3 || condition4 || condition5) && count < 2) {
        count++;
        return condition1 || condition2 || condition3 || condition4 || condition5;
      } else {
        return false;
      }
    };

    const isStressReductionTacticConsiderable = () => {
      const condition1 = (baseGAD7Score >= 10 || baseGAD2Score>= 3) && latestGAD2Score >= 3;
      const condition2 = nervousOrStressed3x;
      const condition3 = basePCL5HyperAlert >= 2;
      const condition4 = baseJumpy >= 2 && latestWatchful >= 2;
      const condition5 = onGuardPCPTSD;

      if ((condition1 || condition2 || condition3 || condition4 || condition5) && count == 2 && returnObj.stressReduction != true) {
        count++;
        return condition1 || condition2 || condition3 || condition4 || condition5;
      } else {
        return false;
      }
    };

    const isClockRetrainingTacticRecommended = () => {
      const condition1 = baseDSWPS && bedWakeWithin2Hours && lessThanTired5x;
      const condition2 = baseASWS && bedWakeWithin2Hours && lessThanTired5x;
      const condition3 = baseshiftWorkChange || baseshiftWorkCurrent || baseshiftWorkTypical;

      if ((condition1 || condition2 || condition3) && count < 2) {
        count++;
        return condition1 || condition2 || condition3;
      } else {
        return false;
      }
    };

    const isClockRetrainingTacticConsiderable = () => {
      const condition1 = baseDSWPS && bedWakeWithin2Hours && lessThanTired5x;
      const condition2 = baseASWS && bedWakeWithin2Hours && lessThanTired5x;
      const condition3 = baseshiftWorkChange || baseshiftWorkCurrent || baseshiftWorkTypical;

      if (
        (condition1 || condition2 || condition3) &&
        count == 2 &&
        returnObj.clockRetraining != true
      ) {
        count++;
        return condition1 || condition2 || condition3;
      } else {
        return false;
      }
    };

    if (count < 2) {
      returnObj.sleepIntensity = isSleepIntensityTacticRecommended();
      returnObj.dreamRetraining = isDreamRetrainingTacticRecommended();
      returnObj.thinkingEfficiency = isThinkingEfficiencyTacticRecommended();
      returnObj.fatigue = isFatigueTacticRecommended();
      returnObj.clockRetraining = isClockRetrainingTacticRecommended();
      returnObj.stressReduction = isStressReductionTacticRecommended();
    }

    if (count == 2) {
      returnObj.fatigueC = isFatigueTacticConsiderable();
      returnObj.thinkingEfficiencyC = isThinkingEfficiencyTacticConsiderable();
      returnObj.stressReductionC = isStressReductionTacticConsiderable();
      returnObj.clockRetrainingC = isClockRetrainingTacticConsiderable();
    }
    // EW - This requirement could appear in the future returnObj.osa = this.isOSA(assessments);
    return returnObj;
  }

  // Currently not used, this requirement could appear in the future
  private isOSA(assessments: Array<Assessment>): boolean {
    if (assessments.length > 0) {
      // Specific assessment record and answer needed
      const osa = this.getMostRecentAssessmentByType(assessments, 'osa');
      if (osa == undefined) {
        return false;
      } // patient has not been given OSA assessment, so shouldn't have flag
      const osaDaignosis = this.getAssessmentAnswer(
        osa,
        AssessmentTemplate.osa.name,
        'OSA_DIAGNOSED'
      );

      if (osaDaignosis) {
        return true;
      
      } else {
        // If main diagnosis question is false get the false details
        const osaDetailsFalse = this.getOsaDetailsFalse(osa);
        if(!osaDetailsFalse || osaDetailsFalse.lenght===0){ // new version of the OSA does not have osaDetailFalse (COAST-1333)
          return false;
        }
        // Count number of positive answers to specific answers this is nessary for last if condition
        const countSpecificPositiveAnswers: Array<string | number> = osaDetailsFalse
          .filter(ans => ans.name !== 'OSA_GASPING')
          .filter(ans => ans.name !== 'OSA_STOP_BREATHING')
          .filter(ans => ans.name !== 'OSA_SNORE_LOUDLY')
          .filter(ans => ans.name !== 'OSA_DIAGNOSED')
          .filter(ans => ans.value === true || ans.value === 1);

        // Answers to specific questions

        // a
        const osaGasping = osaDetailsFalse.find(ans => ans.name === 'OSA_GASPING');
        // b
        const osaStopBreathing = osaDetailsFalse.find(ans => ans.name === 'OSA_STOP_BREATHING');
        // c
        const osaSnoreLoudly = osaDetailsFalse.find(ans => ans.name === 'OSA_SNORE_LOUDLY');
        // d
        const osaDryMouth = osaDetailsFalse.find(ans => ans.name === 'OSA_DRY_MOUTH');
        // h
        const neckSize = osaDetailsFalse.find(ans => ans.name === 'OSA_NECK_SIZE');
        // g
        const sleepy = osaDetailsFalse.find(ans => ans.name === 'OSA_SLEEPY');

        // (a or b) || (c & g)
        if (osaGasping.value || osaStopBreathing.value || (osaSnoreLoudly.value && sleepy.value)) {
          return true;
        }

        // c & 2x{d|e|f|g|h}
        if (osaSnoreLoudly.value && countSpecificPositiveAnswers.length >= 2) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  }

  private getOsaDetailsFalse(assessment: Assessment): any {
    const uniqueIdArr: Array<string> = [
      'OSA_GASPING',
      'OSA_STOP_BREATHING',
      'OSA_SNORE_LOUDLY',
      'OSA_DRY_MOUTH',
      'OSA_CONGESTION_HEADACHES',
      'OSA_BLOOD_PRESSURE',
      'OSA_SLEEPY',
      'OSA_NECK_SIZE'
    ];

    const answers = [];

    uniqueIdArr.forEach(uId => {
      answers.push({
        name: uId,
        value: this.getAssessmentAnswer(assessment, 'osa-details-false', uId)
      });
    });
    return answers;
  }

  private getMostRecentAssessmentByType(assessments: Array<Assessment>, assessmentType: string) {
    const assessmentsOfType = assessments.filter(a =>
      _some(a.groups, g => g.type === assessmentType)
    );
    return this.getMostRecentAssessment(assessmentsOfType);
  }

  private getMostRecentAssessment(assessments: Array<Assessment>) {
    if (!assessments || assessments.length < 1) {
      return null;
    }
    let max = assessments[0];

    for (let i = 1; i < assessments.length; i++) {
      max = assessments[i].assessmentDate > max.assessmentDate ? assessments[i] : max;
    }

    return max;
  }

  public getAssessmentAnswer(
    assessment: Assessment,
    answerId: string,
    answerType: string,
    groupType?: string
  ): any {
    const returnDefault = () => {
      if (answerType === 'number') {
        return -1;
      } else {
        return false;
      }
    };

    if (!assessment) {
      return returnDefault();
    }

    let group: AssessmentGroup;
    if (groupType) {
      group = assessment.groups.find(g => g.type === groupType);
    } else {
      group = assessment.groups[0];
    }

    if (!group) {
      return returnDefault();
    }

    const answer: AssessmentAnswer = group.answers.find(a => a.uniqueAnswerId === answerId);
    if (!answer) {
      return returnDefault();
    }

    if (answerType === 'boolean') {
      return this.isTrue(answer.value);
    }
    if (answerType === 'number') {
      if (answer.value?.toString().includes('.')) {
        return parseFloat(answer.value);
      }
      return parseInt(answer.value, 10);
    }
  }

  private isTrue(val: any) {
    return val === 'true' || val === 'yes' || val === true;
  }

  private isTimeNull(date: any) {
    return !date || date === '--:--' || date === 0;
  }

  private impressionSetup(answers: any) {
    const setupStruct: any = {
      // TODO: find better name
      ILLNESS: '',
      GLOBAL: '',
      EFFICACY: '',
      COMMENT: ''
    };
    if (answers.length) {
      setupStruct.ILLNESS = _find(answers, answer => answer.uniqueAnswerId === 'ILLNESS').value;
      setupStruct.GLOBAL = _find(answers, answer => answer.uniqueAnswerId === 'GLOBAL').value;
      setupStruct.EFFICACY = _find(answers, answer => answer.uniqueAnswerId === 'EFFICACY').value;
      setupStruct.COMMENT = _find(answers, answer => answer.uniqueAnswerId === 'COMMENT').value;
    }
    return setupStruct;
  }

  public async calcAverages(
    sumGNTh,
    sumGNTm,
    sumGMTh,
    sumGMTm,
    sumLatency,
    sumWASO,
    sumEMA,
    sumTIB,
    sumAsleep,
    sumSE,
    sumSQ,
    count,
    logs,
    userId,
    morningLogCount
  ) {
    let avgGNT;
    let avgGMT;
    let avgLatency;
    let avgWASO;
    let avgEMA;
    let avgTIB;
    let avgAsleep;
    let avgSE;
    let avgSQ;
    let sBedTime;
    let sTIB;

    if (count > 0) {
      avgLatency = sumLatency / count;
      avgWASO = sumWASO / count;
      avgEMA = sumEMA / count;
      avgTIB = sumTIB / count;
      avgAsleep = sumAsleep / count;
      avgSE = sumSE / count;
      avgSQ = sumSQ / count;

      let avgGNTh = sumGNTh ? sumGNTh / count : 0;
      let avgGNTm = sumGNTm ? sumGNTm / count : 0;
      let avgGMTh = sumGMTh ? sumGMTh / count : 0;
      let avgGMTm = sumGMTm ? sumGMTm / count : 0;

      const decGNT = avgGNTh - Math.floor(avgGNTh);
      avgGNTm += decGNT * 60;
      avgGNTh = Math.floor(avgGNTh);
      const decGMT = avgGMTh - Math.floor(avgGMTh);
      avgGMTm += decGMT * 60;
      avgGMTh = Math.floor(avgGMTh);

      if (avgGNTm >= 60) {
        avgGNTm -= 60;
        avgGNTh++;
      }
      if (avgGMTm >= 60) {
        avgGMTm -= 60;
        avgGMTh++;
      }
      if (avgGNTh >= 24) {
        avgGNTh -= 24;
      }
      if (avgGMTh >= 24) {
        avgGMTh -= 24;
      }

      if (Math.round(avgGNTm) === 60) {
        avgGNT = ('0' + avgGNTh).slice(-2) + ':' + ('0' + Math.floor(avgGNTm)).slice(-2);
      } else {
        avgGNT = ('0' + avgGNTh).slice(-2) + ':' + ('0' + Math.round(avgGNTm)).slice(-2);
      }
      if (Math.round(avgGMTm) === 60) {
        avgGMT = ('0' + avgGMTh).slice(-2) + ':' + ('0' + Math.floor(avgGMTm)).slice(-2);
      } else {
        avgGMT = ('0' + avgGMTh).slice(-2) + ':' + ('0' + Math.round(avgGMTm)).slice(-2);
      }

      const tempDate = moment();
      tempDate.hours(avgGMTh);
      tempDate.minutes(avgGMTm);
      // need to set seconds to 0 to make the minute rounding consistent no matter what current second moment() produce
      tempDate.seconds(0);
      tempDate.milliseconds(0);

      if (Math.round(avgAsleep % 60) === 60) {
        // consistent with the logic above for avgGNT and avgGMT
        tempDate.subtract(Math.floor(avgAsleep) + 30, 'minutes');
      } else {
        tempDate.subtract(Math.round(avgAsleep) + 30, 'minutes'); // need to round avgAsleep since HH:MM display always rounds MM
      }
      sBedTime = this.padTimeStr(tempDate.hours()) + ':' + this.padTimeStr(tempDate.minutes());
      sTIB = this.calculateTIB(sBedTime, avgGMT);
    } else {
      avgGNT = 0;
      avgGMT = 0;
      // avgLatency = 0;
      // avgWASO = 0;
      avgTIB = 0;
      // avgAsleep = 0;
      // avgSE = 0;
      avgSQ = 0;
      count = 0;
      sBedTime = null;
    }

    const diaryAverages = {
      avgGNT,
      avgGMT,
      avgLatency,
      avgWASO,
      avgEMA,
      avgTIB,
      avgAsleep,
      avgSE,
      avgSQ,
      count,
      sBedTime,
      morningLogCount,
      sleepTacticRecommendations: null,
      sTIB
    };

    diaryAverages.sleepTacticRecommendations = await this.getPatientFlagRecommendations(
      userId,
      diaryAverages,
      logs,
      morningLogCount
    );

    return diaryAverages;
  }

  public buildWeekEntry() {
    return {
      createdOn: '',
      isComplete: false,
      isError: false,
      isYield: false,
      isOpen: false,
      isUpcoming: true,
      id: 0,
      Id: 0,
      isNightmare: false,
      isRestless: false,
      isThinking: false,
      isSNightmare: false,
      isSRestless: false,
      isSThinking: false,
      lastModified: '',
      note: '',
      lastCallLoggedOn: null,
      prescriber: null,
      sBedTime: null,
      sDocType: 0,
      sRiseTime: null,
      sTIB: null,
      impressionComplete: false,
      assessmentComplete: false
    };
  }

  private getAswpsAnswers(assessment: Assessment): any {
    const answersInfoArr = [
      { id: 'SWPS_BEDTIME_PREFERENCE', type: 'number', camelCase: 'bedtimePreference' },
      { id: 'SWPS_BEDTIME_IS_LATER', type: 'boolean', camelCase: 'bedtimeIsLater' },
      { id: 'SWPS_BEDTIME_IS_EARLIER', type: 'boolean', camelCase: 'bedtimeIsEarlier' },
      { id: 'SWPS_RISETIME_PREFERENCE', type: 'number', camelCase: 'risetimePreference' },
      { id: 'SWPS_RISETIME_IS_LATER', type: 'boolean', camelCase: 'risetimeIsLater' },
      { id: 'SWPS_RISETIME_IS_EARLIER', type: 'boolean', camelCase: 'risetimeIsEarlier' },
      { id: 'SWPS_MISS_MORNING_EVENTS', type: 'boolean', camelCase: 'missMorningEvents' },
      { id: 'SWPS_WAKING_UP_RESTED', type: 'boolean', camelCase: 'wakingUpRested' },
      { id: 'SWPS_WAKE_UP_ASSISTANCE', type: 'boolean', camelCase: 'wakeUpAssistance' },
      { id: 'SWPS_STAYING_AWAKE', type: 'boolean', camelCase: 'stayingAwake' },
      { id: 'SWPS_WORK_TROUBLES', type: 'boolean', camelCase: 'workTroubles' },
      { id: 'SWPS_FAMILY_TENSION', type: 'boolean', camelCase: 'familyTension' },
      { id: 'SWPS_CANCELLED_SOCIAL_EVENTS', type: 'boolean', camelCase: 'cancelledSocialEvents' },
      { id: 'SWPS_MISSED_SOCIAL_EVENTS', type: 'boolean', camelCase: 'missedSocialEvents' }
    ];

    const swpsAnswers: object = {};

    answersInfoArr.forEach(ans => {
      swpsAnswers[ans.camelCase] = this.getAssessmentAnswer(assessment, ans.id, ans.type, 'swps');
    });
    return swpsAnswers;
  }

  private checkForDSWPS(swpsAnswers: any): boolean {
    const swpsBedtimeCond =
      [1, 2, 3, 4].includes(swpsAnswers.bedtimePreference) && swpsAnswers.bedtimeIsLater;
    const swpsRisetimeCond =
      [4, 5, 6].includes(swpsAnswers.risetimePreference) && swpsAnswers.risetimeIsLater;
    const swpsConsequencesCond =
      swpsAnswers.missMorningEvents ||
      swpsAnswers.wakingUpRested ||
      swpsAnswers.wakeUpAssistance ||
      swpsAnswers.stayingAwake ||
      swpsAnswers.workTroubles ||
      swpsAnswers.familyTension ||
      swpsAnswers.cancelledSocialEvents ||
      swpsAnswers.missedSocialEvents;

    return swpsBedtimeCond && swpsRisetimeCond && swpsConsequencesCond;
  }

  private checkForASWPS(swpsAnswers: any): boolean {
    const swpsBedtimeCond =
      [7, 8].includes(swpsAnswers.bedtimePreference) && swpsAnswers.bedtimeIsEarlier;
    const swpsRisetimeCond =
      [1, 8].includes(swpsAnswers.risetimePreference) && swpsAnswers.risetimeIsEarlier;
    const swpsConsequencesCond =
      swpsAnswers.missMorningEvents ||
      swpsAnswers.wakingUpRested ||
      swpsAnswers.wakeUpAssistance ||
      swpsAnswers.stayingAwake ||
      swpsAnswers.workTroubles ||
      swpsAnswers.familyTension ||
      swpsAnswers.cancelledSocialEvents ||
      swpsAnswers.missedSocialEvents;

    return swpsBedtimeCond && swpsRisetimeCond && swpsConsequencesCond;
  }

  /**
   * Calculates total time in bed or delta between bedTime and riseTime
   * Can be used for calculating delta for other purposes as long the parameters
   * following the same format, as also the result
   * @param bedTime Bed time in 'HH:MM' format
   * @param riseTime Rise time in 'HH:MM' format
   * @returns TIB in 'HH:MM' format
   */
  public calculateTIB(bedTime: string, riseTime: string): string {
    const formatRegex = /^\d{2}:{0,1}\d{2}$/;
    if (!bedTime || !riseTime || !formatRegex.test(bedTime) || !formatRegex.test(riseTime)) {
      // test for null and format error
      return '--:--';
    }
    const bedTimeH = parseInt(bedTime.slice(0, 2));
    const bedTimeM = parseInt(bedTime.slice(-2));
    const riseTimeH = parseInt(riseTime.slice(0, 2));
    const riseTimeM = parseInt(riseTime.slice(-2));

    if (isNaN(bedTimeH) || isNaN(bedTimeM) || isNaN(riseTimeH) || isNaN(riseTimeM)) {
      return '--:--';
    }

    const riseTimeMinutes = riseTimeH * 60 + riseTimeM;
    const bedTimeMinutes = bedTimeH * 60 + bedTimeM;
    let delta = riseTimeMinutes - bedTimeMinutes;
    if (delta < 0) {
      delta = delta + 1440;
    }
    if (Math.round(delta % 60) === 60) {
      // consistent with calcAverage
      return (
        this.padTimeStr(Math.floor(delta / 60)) + ':' + this.padTimeStr(Math.floor(delta % 60))
      );
    } else {
      return (
        this.padTimeStr(Math.floor(delta / 60)) + ':' + this.padTimeStr(Math.round(delta % 60))
      );
    }
  }
}
