import { AssessmentDefinition } from '../../../../../noctem-lib/src/core/contracts/models.calculations';


export const strategicNapping: AssessmentDefinition = {
  name: 'strategic-napping',
  description: 'Strategic Napping',
  instructions: null,
  questions: [
    {
      id: 0,
      questionText: 'The only fix for sleepiness and fatigue is sleep',
      questionEmphasis: ``,
      subText: `<p><img src='./assets/img/module-images/strategic-napping/sleeping-soldier.png'/></p>
                <p>Caffeine boosts alertness, but is not a sustainable solution for lack of sleep.</p>
                <p>Consider your nap length and timing to get maximum gains from your nap opportunities.</p>
      `,
      questionType: 'module',

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
        id: 1,
        questionText: 'Nap Length',
        questionEmphasis: ``,
        subText: `<p>A 2-4 hour nap prior to your shift is best for performance and alertness.</p>
                  <h5><b>Even a short nap is better than no sleep</b></h5>
                  <p>Short naps (5-20 minutes) during your shift can increase safety.</p>
        `,
        questionType: 'module',

        uniqueAnswerId: null,
        autofill: false,
        answerOptions: null,
        conditionalQuestions: [],
        isCheckInQuestion: false
    },
    {
        id: 2,
        questionText: 'Nap Timing',
        questionEmphasis: ``,
        subText: `<p>You may find it difficult to<br />nap during these times:</p>
                <p class="call-out">
                    the mid-morning hours <b>(09:00-11:00)</b><br />the late evening <b>(19:00-21:00)</b>
                </p>
                <p>It is easier to nap during these times:</p>
                <p class="dark-call-out">
                    the early morning hours <b>(02:00-04:00)</b><br />the early afternoon hours <b>(13:00-15:00)</b>
                </p>
                <p>While it is easier to get sleep during the early morning and afternoon, it is more difficult to awaken from naps at these times</p>
                <p>It can take longer for sleep inertia, that post-nap grogginess, to dissipate</p>
        `,
        questionType: 'module',

        uniqueAnswerId: null,
        autofill: false,
        answerOptions: null,
        conditionalQuestions: [],
        isCheckInQuestion: false
    },
    {
        id: 3,
        questionText: 'Concerns about sleep inertia is NOT a good reason to avoid napping',
        questionEmphasis: ``,
        subText: `<p>To mitigate post-nap grogginess,<br />try any of the following:</p>
                <p class="call-out">
                    Plan your naps to allow for 15 minutes to 1 hour of wakefulness prior to your shift to ensure full readiness.
                </p>
                <p class="dark-call-out">
                    Use caffeine immediately upon awakening from your nap to decrease post-nap grogginess.
                </p>
                <p class="call-out">
                    For short nap opportunities, try out a caffiene nap. Take 100-200 mg of caffeine and then nap for 10-20 minutes.
                    The end of your nap will align with when the effects of caffeine begin to kick in.
                </p>
        `,
        questionType: 'module',

        uniqueAnswerId: null,
        autofill: false,
        answerOptions: null,
        conditionalQuestions: [],
        isCheckInQuestion: false
    },
  ]
};
