export const TBIPhq8Def = {
  name: 'tbi-phq8',
  description: 'Patient Health Questionnaire',
  instructions: '',
  /*
  Note: the questions property must be empty here (but available in the database) or this assessment will be made available for all patients

  questions: [
    {
      id: 0,
      questionText: 'Over the last 2 weeks, how often have you been bothered by:',
      questionEmphasis: 'Little interest or pleasure in doing things',
      questionType: 'radio',
      uniqueAnswerId: 'PHQ_INTEREST',
      autofill: false,
      answerOptions: [
        { display: 'Not at all', value: 0 },
        { display: 'Several Days', value: 1 },
        { display: 'More than half the days', value: 2 },
        { display: 'Nearly every day', value: 3 },
      ],
      conditionalQuestions: []
    },
    {
      id: 1,
      questionText: 'Over the last 2 weeks, how often have you been bothered by:',
      questionEmphasis: 'Feeling down, depressed, or hopeless',
      questionType: 'radio',
      uniqueAnswerId: 'PHQ_DEPRESSION',
      autofill: false,
      answerOptions: [
        { display: 'Not at all', value: 0 },
        { display: 'Several Days', value: 1 },
        { display: 'More than half the days', value: 2 },
        { display: 'Nearly every day', value: 3 },
      ],
      conditionalQuestions: []
    },
    {
      id: 2,
      questionText: 'Over the last 2 weeks, how often have you been bothered by:',
      questionEmphasis: 'Trouble falling or staying asleep, or sleeping too much',
      questionType: 'radio',
      uniqueAnswerId: 'PHQ_SLEEP',
      autofill: false,
      answerOptions: [
        { display: 'Not at all', value: 0 },
        { display: 'Several Days', value: 1 },
        { display: 'More than half the days', value: 2 },
        { display: 'Nearly every day', value: 3 },
      ],
      conditionalQuestions: []
    },
    {
      id: 3,
      questionText: 'Over the last 2 weeks, how often have you been bothered by:',
      questionEmphasis: 'Feeling tired or having little energy',
      questionType: 'radio',
      uniqueAnswerId: 'PHQ_FATIGUE',
      autofill: false,
      answerOptions: [
        { display: 'Not at all', value: 0 },
        { display: 'Several Days', value: 1 },
        { display: 'More than half the days', value: 2 },
        { display: 'Nearly every day', value: 3 },
      ],
      conditionalQuestions: []
    },
    {
      id: 4,
      questionText: 'Over the last 2 weeks, how often have you been bothered by:',
      questionEmphasis: 'Poor appetite or overeating',
      questionType: 'radio',
      uniqueAnswerId: 'PHQ_APPETITE',
      autofill: false,
      answerOptions: [
        { display: 'Not at all', value: 0 },
        { display: 'Several Days', value: 1 },
        { display: 'More than half the days', value: 2 },
        { display: 'Nearly every day', value: 3 },
      ],
      conditionalQuestions: []
    },
    {
      id: 5,
      questionText: 'Over the last 2 weeks, how often have you been bothered by:',
      questionEmphasis:
        'Feeling bad about yourself – or that you are a failure or have let yourself or family down',
      questionType: 'radio',
      uniqueAnswerId: 'PHQ_FAILURE',
      autofill: false,
      answerOptions: [
        { display: 'Not at all', value: 0 },
        { display: 'Several Days', value: 1 },
        { display: 'More than half the days', value: 2 },
        { display: 'Nearly every day', value: 3 },
      ],
      conditionalQuestions: []
    },
    {
      id: 6,
      questionText: 'Over the last 2 weeks, how often have you been bothered by:',
      questionEmphasis:
        'Trouble concentrating on things, such as reading the news or watching television',
      questionType: 'radio',
      uniqueAnswerId: 'PHQ_CONCENTRATION',
      autofill: false,
      answerOptions: [
        { display: 'Not at all', value: 0 },
        { display: 'Several Days', value: 1 },
        { display: 'More than half the days', value: 2 },
        { display: 'Nearly every day', value: 3 },
      ],
      conditionalQuestions: []
    },
    {
      id: 7,
      questionText: 'Over the last 2 weeks, how often have you been bothered by:',
      questionEmphasis:
        'Moving or speaking so slowly that other people could have noticed. Or the opposite - being so fidgety or restless that you have been moving around more than usual',
      questionType: 'radio',
      uniqueAnswerId: 'PHQ_SLOWLY',
      autofill: false,
      answerOptions: [
        { display: 'Not at all', value: 0 },
        { display: 'Several Days', value: 1 },
        { display: 'More than half the days', value: 2 },
        { display: 'Nearly every day', value: 3 },
      ],
      conditionalQuestions: []
    }
  ]
  */
};
