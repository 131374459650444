import { AssetDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';
import { strategicCaffeine } from '../questions/strategic-caffeine/strategic-caffeine';


export const strategicCaffeineDef: AssetDefinition = {
  id: 13,
  name: 'strategic-caffeine',
  label: 'Strategic Caffeine',
  description: `
  Help your Soldiers get the most out of their caffeine use by strategically distributing caffeine throughout the day and stopping caffeine 6 hours before sleep. Remember: Caffeine does not eliminate the need for sleep.
  `,
  categories: ['all', 'sleep-and-fatigue'],
  showInLibrary: true,
  readOnly: false,
  assessments: [
    strategicCaffeine
  ],
  url: 'strategic-caffeine'
};
