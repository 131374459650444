import { Injectable } from '@angular/core';
import moment, { Moment } from 'moment';

/**
 * Date format values
 * @readonly
 * @enum {string}
 */
enum DateFormats {
  Short = 'MM/DD/YYYY',
  Long = 'MMMM D, YYYY',
  Time = 'HH:mm',
  Datetime = 'MMMM D, YYYY HH:mm'
};

@Injectable({
  providedIn: 'root'
})
export class DateTimeWrapper {
  /**
   * @private
   * @param d Date input
   * @param useLocale Format for the locale
   * @param display The format string to display
   */
  format(d: any, useLocale: boolean = false, display: string) {
    const m = moment.utc(d);

    if (!m.isValid()) {
      throw new Error('Invalid input for moment date conversion');
    }

    if (useLocale) {
      m.local(); // switch moment context from UTC to local
    }

    return m.format(display);
  }

  /**
   * Format input to a date string
   * @param d Date input
   * @param useLocale - Use locale
   */
  toShortDate(d: any, useLocale: boolean = false): string {
    return this.format(d, useLocale, DateFormats.Short);
  }

  /**
   * Format input to a date string
   * @param d Date input
   * @param useLocale - Use locale
   */
  toLongDate(d: any, useLocale: boolean = false): string {
    return this.format(d, useLocale, DateFormats.Long);
  }

  /**
   * Format input to a time string
   * @param t Time input
   * @param useLocale - Use locale
   */
  toTime(t: any, useLocale: boolean = false): string {
    return this.format(t, useLocale, DateFormats.Time);
  }
}
