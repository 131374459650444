<div class="form-group" [class.row]="layoutDirection === 'horizontal'">
    <label for="{{id}}" class="col-form-label pt-3">{{labelText}}</label>
    <div class="d-flex" [class.flex-row-reverse]="layoutDirection === 'horizontal'">
        <input [id]="id" [name]="id" [value]="value" [attr.type]="type" [ngClass]="cssClasses"
            [class.is-invalid]="errorMessages?.length > 0" [class.is-valid]="errorMessages?.length === 0"
            (change)="onChange($event)" [placeholder]="placeHolderText" class="form-control"
            aria-describedby="inputHelp" *ngIf="!readOnly" />
        <span *ngIf="readOnly">{{value | date:'short'}}</span>
    </div>
</div>
<div class="row">
    <small id="inputHelp" class="form-text text-muted">{{inputHelpText}}</small>
</div>
    <div class="w-100" *ngIf="errorMessages?.length > 0">
        <p class="alert alert-danger w-100" *ngFor="let error of errorMessages">
            &#8226;&nbsp;{{error}}
        </p>
</div>