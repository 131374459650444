import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChatStateService, UserStateService } from '@noctem/web';
import { AutoCompleteComponent } from '../auto-complete/auto-complete.component';

@Component({
    selector: 'patient-search-dialog',
    styleUrls: ['patient-search-dialog.scss'],
    templateUrl: './patient-search-dialog.component.html',
})
export class PatientSearchDialog implements OnInit {
    userId: string;


    constructor(
        public dialogRef: MatDialogRef<PatientSearchDialog>,
        public stateService: ChatStateService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    exitDialog(): void {
        this.dialogRef.close();
    }

    ngOnInit() {
    }

    userFilterSelected(event:any) {
        this.exitDialog();
        this.data.userFilterSelected(event);
    }
}
