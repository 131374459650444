<div class="sendMessageModal" *ngIf="stateService.state$ | async as state">
    <div>
        <div mat-dialog-content>
            <div class="modal-header">
                <div class="search-container">
                    <h2 class="modal-title">Choose Recipient</h2><br />
                    <app-auto-complete context="messages" (itemSelected)="userFilterSelected($event)"
                        [filterFields]="['profile.firstName','profile.lastName']"></app-auto-complete>
                </div>
            </div>
            <div class="modal-body">

            </div>

        </div>
        <div mat-dialog-actions>
            <div class="modal-footer">
                <button mat-button class="btn btn-secondary" (click)="exitDialog()">Close</button>
            </div>
        </div>
    </div>
</div>