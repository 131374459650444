import { AssessmentDefinition } from '../../../../../noctem-lib/src/core/contracts/models.calculations';


export const dreamRetraining: AssessmentDefinition = {
  name: 'dream-retraining',
  description: 'Dream Retraining',
  instructions: null,
  questions: [
    {
      id: 0,
      questionText: 'Occasional bad dreams or nightmares are normal',
      questionEmphasis: ``,
      subText: `<p>Deployment related factors such as stress and sleep loss can increase the likelihood of bad dreams.</p>
                <p>Bad dreams or nightmares are not a problem unless they keep you awake or are bothersome to you. </p>`,
      questionType: 'module',

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 1,
      questionText: 'Try it out!',
      questionEmphasis: ``,
      subText: `<p>Practice desirable dream scenarios during wakeful hours to train your brain to dream in a way that does not bother you and does not wake you up. </p>
                <p class='content-image'><img src='./assets/img/module-images/dream-retraining/tropical-beach-paradise.png' /></p>`,
      questionType: 'module',

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 2,
      questionText: `Step 1`,
      questionEmphasis: ``,
      subText: `<p>To start, pick a bad dream with a  bothersome ranking of 4⁠–6 out of 10.</p>
                <p class='module-emphasis'>10 = extremely bothersome.</p>
                <p>Do not pick dreams that are extremely upsetting or exact replays of  real-life events.</p>
                <p>Strengthen your skill with this technique before tackling harder dreams.</p>
                <p>Give your dream a bothersome ranking:</p>`,
      questionType: 'number',
      uniqueAnswerId: "DREAM_BOTHERSOME" ,
      autofill: false,
      validation: {
        maxLength: 10
      },
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 2,
      questionText: `Step 1`,
      questionEmphasis: ``,
      subText: `Type your bad dream below:`,
      questionType: 'textarea',

      uniqueAnswerId: "DREAM_BOTHERED_DESCRIPTION" ,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 3,
      questionText: 'Step 2',
      questionEmphasis: ``,
      subText: `<p>Build a new dream by changing some or all parts of your bad dream any way you like. Create an entirely new dream if you like.</p>
                <p>Make sure to use “I” statements when you write your new dream.  For example:</p>
                <p class='module-emphasis'>I'm sitting in the chow hall talking with my buddy Dan...</p>
                <p class='module-emphasis'>Build a dream that is pleasant, but not so exciting that it may wake you up.</p>
                <p>Type your pleasant dream below:</p>`,
      questionType: 'textarea',

      uniqueAnswerId: 'DREAM_PLEASANT',
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 4,
      questionText: 'Step 3',
      questionEmphasis: ``,
      subText: `<p>During practice, use your imagination to make your new dream as dream-like as possible. Use your 5 senses to connect with all components of your new dream.</p>
                <p>Rehearse your new dream in your mind at least 3 times during your wakeful hours.</p>
                <p>Practice 3-5 minutes each time. Repeat your dream scenario in your mind until time runs out.</p>
      `,
      questionType: 'module',
      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 5,
      questionText: 'Make it stick',
      questionEmphasis: ``,
      subText: `<p>Rehearse your new dream to train up your new, desired dreaming habit.</p>
                <p>Practice makes perfect.  Repeated training makes behaviors automatic.</p>`,
      questionType: 'module',
      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    }
  ]
};
