import { AssessmentDefinition } from '../../../../../noctem-lib/src/core/contracts/models.calculations';


export const buildConfidence: AssessmentDefinition = {
  name: 'build-confidence',
  description: 'Build Confidence',
  instructions: null,
  questions: [
    {
      id: 0,
      questionText: '',
      questionEmphasis: ``,
      subText: `
      <p>Self-talk has a very powerful effect on performance. Our thoughts directly influence our feelings and physical state, both of which highly affect performance.</p>
      <p><img src='./assets/img/module-images/build-confidence/build-confidence.svg' class='w-100'/></p>
      <h3>By controlling your thoughts you can approach any situation with confidence.</h3>
      <p>To do so you need to deliberately focus on certain thoughts, control the way you talk to yourself and restructure negative thoughts.</p>
      `,
      questionType: 'module',
      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 1,
      questionText: '',
      questionEmphasis: `Practice`,
      subText: `
      <h3>Practice</h3>
      <h5>Power Statements</h5>
      <p>You can enhance confidence by developing self-talk that will help you approach your performance with confidence. These thoughts should meet the P3 Model:</p>
      <div class="call-out">Be <b>purposeful</b> (to motivate or instruct)</div>
      <div class="call-out">Be <b>productive</b> (focused on the present and what you can control) </div>
      <div class="call-out">Reflect on <b>possibility</b> (no limits, focus on what you want to happen). </div>
      <p>Example: “I’ve trained really hard for this, trust my and my buddies’ abilities, I know we will accomplish our mission.”</p>
      <div class="call-out">
      <b>Create Power Statements</b>
      <p>Think about your personal strengths and capabilities. Generate 3 Power Statements relevant to your current work. </p>
      </div>
      `,
      questionType: 'textarea',
      uniqueAnswerId: 'CONFIDENCE_POWER_STATEMENT',
      autofill: true,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 2,
      questionText: '',
      questionEmphasis: `Practice`,
      subText: `
        <h3>Practice</h3>
        <h5>Thought Replacement</h5>
        <p>Ineffective thoughts will inevitably pop up. These thoughts include  random ideas not related to your task, things you can’t control and self-limiting (“I can’t”) attitudes. Be aware when you experience a thought such as this, interrupt it, and replace it with a P3 statement.</p>
        <div class="call-out">
        <b>Thought Replacement</b><br />
        Think about a recent situation in which you under-performed. What ineffective thoughts did you experience?
        </div>
      `,
      questionType: 'textarea',
      uniqueAnswerId: 'CONFIDENCE_INEFFECTIVE',
      autofill: true,
      answerOptions: null,
      isCheckInQuestion: false,
      conditionalQuestions: []
    },
    {
      id: 3,
      questionText: 'Now write out a P3 statement to replace each ineffective thought.',
      questionType: 'textarea',
      uniqueAnswerId: 'CONFIDENCE_P3',
      autofill: true,
      answerOptions: null,
      isCheckInQuestion: false,
      conditionalQuestions: []
    },
    {
      id: 4,
      questionText: '',
      questionEmphasis: ``,
      subText: `
        <h3>Extra Learning about Confidence</h3>
        <p>There are 4 general sources of evidence that influence confidence</p>
        <div class="call-out"><b>Personal Experience</b> (your past accomplishments & training) </div>
        <div class="call-out"><b>Physical State</b> (interpretation of your level of arousal, being physically ready and primed vs. exhausted, injured, or nervous)</div>
        <div class="call-out"><b>Vicarious Experience</b> (observing others performance & comparing it to your own)</div>
        <div class="call-out"><b>Persuasion</b> (motivational self-talk).</div>
        <h3>
          They share a common theme, <b>thoughts matter!</b>
        </h3>
        <p>Confident performers think deliberately about these sources:</p>
        <div class="call-out">Interpretation of success/failure – failure is not global and permanent, but rather isolated and temporary</div>
        <div class="call-out">Interpret physical state accurately (ex: not nervous, but amped up) & give 100%</div>
        <div class="call-out">Think “If he can do it, so can I” or “If he can do it, I can do it better”</div>
        <div class="call-out">Use persuasive self-talk to motivate & build confidence</div>
      `,
      questionType: 'module',
      uniqueAnswerId: null,
      autofill: true,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
  ]
};
