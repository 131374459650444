import { AssessmentDefinition } from "../../../../noctem-lib/src/core/contracts/models.calculations";

export const pcl5Short: AssessmentDefinition = {
  name: 'pcl5Short',
  description: 'Stress Check-In',
  instructions: null,
  questions: [
    {
      id: 0,
      questionText: 'Please answer to the best of your ability for the best results',
      questionEmphasis: `<p>The next few pages contain problems that people sometimes have in response to a very stressful experience.</p>
                         <p>Please read each problem carefully and drag the selector to the
                        response that best describes your experience.</p>`,
      questionType: null,
      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: true
    },
    {
      id: 1,
      questionText: 'Over the <b>past few days</b>, how much were you bothered by:',
      questionEmphasis: `Suddenly feeling or acting as if the stressful experience were actually happening again?`,
      questionType: 'slider',
      uniqueAnswerId: 'STRESS_RELIVE',
      autofill: false,
      answerOptions: [
        { display: 'Not at All', value: 0 },
        { display: 'A Little Bit', value: 1 },
        { display: 'Moderately', value: 2 },
        { display: 'Quite a Bit', value: 3 },
        { display: 'Extremely', value: 4 }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: true
    },
    {
      id: 2,
      questionText: 'Over the <b>past few days</b>, how much were you bothered by:',
      questionEmphasis: `Avoiding external reminders of the stressful experience (for example,
                          people, places, conversations, activities, objects, or situations)?`,
      questionType: 'slider',
      uniqueAnswerId: 'STRESS_AVOID',
      autofill: false,
      answerOptions: [
        { display: 'Not at All', value: 0 },
        { display: 'A Little Bit', value: 1 },
        { display: 'Moderately', value: 2 },
        { display: 'Quite a Bit', value: 3 },
        { display: 'Extremely', value: 4 }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: true
    },
    {
      id: 3,
      questionText: 'Over the <b>past few days</b>, how much were you bothered by:',
      questionEmphasis: 'Feeling distant or cut off from other people?',
      questionType: 'slider',
      uniqueAnswerId: 'STRESS_DISTANT',
      autofill: false,
      answerOptions: [
        { display: 'Not at All', value: 0 },
        { display: 'A Little Bit', value: 1 },
        { display: 'Moderately', value: 2 },
        { display: 'Quite a Bit', value: 3 },
        { display: 'Extremely', value: 4 }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: true
    },
    {
      id: 4,
      questionText: 'Over the <b>past few days</b>, how much were you bothered by:',
      questionEmphasis: 'Irritable behavior, angry outbursts, or acting aggresively?',
      questionType: 'slider',
      uniqueAnswerId: 'STRESS_IRRITABLE',
      autofill: false,
      answerOptions: [
        { display: 'Not at All', value: 0 },
        { display: 'A Little Bit', value: 1 },
        { display: 'Moderately', value: 2 },
        { display: 'Quite a Bit', value: 3 },
        { display: 'Extremely', value: 4 }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: true
    }
  ]
};
