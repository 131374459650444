import { AssessmentDefinition } from '../../../../../noctem-lib/src/core/contracts/models.calculations';


export const tacticalBreathing: AssessmentDefinition = {
  name: 'tactical-breathing',
  description: 'Tactical Breathing',
  instructions: null,
  questions: [
    {
      id: 0,
      questionText: '',
      questionEmphasis: ``,
      subText: `
      <h5>Learn</h5>
      <p>To perform at your highest level you need to deliberately manage energy expenditure and recover after energy loss. When you are stressed or panicked you take short, shallow breaths. </p>

      <p><b>By taking slow breaths you can produce a physiological change that improves focus and decreases anxiety.</b></p>

      <p>Tactical breathing enhances oxygen uptake by conserving carbon dioxide and promotes recovery processes.</p>
      <h5><b>What to do</b></h5>
      <div class='call-out'>
      Place 1 hand on your chest and 1 hand on your stomach.
      </div>
      <div class='call-out'>
      Count to <b>4</b> while breathing in.
      </div>
      <div class='call-out'>
      Count to <b>6</b> while breathing out.
      </div>
      <div class='call-out'>
      Take in a normal amount of air and breath out very slowly. Inhale when you feel ready.
      </div>
      <div class='call-out'>
      You should feel your stomach expand, not your chest. Think "low" and "slow".
      </div>
      `,
      questionType: 'module',
      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 1,
      questionText: '',
      questionEmphasis: ``,
      subText: `
      <div class='call-out'>
        <h5 class='module'><b>Practice</b></h5>
        <p>You can practice this slow breathing during a high stress, high performance situation.</p>
        <p><b>Take a tactical pause to take 1-2 slow breaths before executing the task. This will slow your heart rate and promote good decision making.</b></p>
        <p>You can also practice this breathing for longer periods of time to promote relaxation and recovery.</p>
        <p>Daily practice, even just a few minutes here and there, will help your body respond more rapidly when used in a stressful situation.</p>
      </div>
      <div class='call-out'>
        <h5 class='module'><b>Activity 1</b></h5>
        <p>
        Practice this technique for 2 full minutes. You may adjust the count if needed, staying in the 4-7 count range for each inhale and exhale.
        To help with form, you may place your hands as indicated.
        </p>
      </div>
      <div class='call-out'>
        <h5 class='module'><b>Activity 2</b></h5>
        <p>Listen to this audio-guided tactical breathing exercise.</p>
      </div>
      <audio class="w-100" controls>
        <source src="./assets/audio/tactical_breathing.mp3" type="audio/mp3">
        Your browser does not support the audio element.
      </audio>
      `,
      questionType: 'module',
      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
  ]
};
