export const TBIBriefFatigueInventoryDef = {
  name: 'tbi-brief-fatigue-inventory',
  description: 'Brief Fatigue Inventory',
  instructions: '',
  /*
  Note: the questions property must be empty here (but available in the database) or this assessment will be made available for all patients

  questions: [
    {
      id: 0,
      questionText: 'Througout our lives, most of us have times when we feel very tired or fatigued.',
      questionEmphasis: 'Have you felt unusually tired or fatigued in the last week?',
      questionType: 'radio',
      uniqueAnswerId: 'BFI_FATIGUE_CONFIRM',
      autofill: false,
      answerOptions: [
        { display: 'Yes', value: 1 },
        { display: 'No', value: 0 },
      ],
      conditionalQuestions: []
    },
    {
      id: 1,
      questionText: 'On a scale of 0 (no fatigue) to 10 (as bad as you can imagine), what number best describes:',
      questionEmphasis: 'Your fatigue (weariness, tiredness) right NOW?',
      questionType: 'radio',
      uniqueAnswerId: 'BFI_FATIGUE_NOW',
      autofill: false,
      answerOptions: [
        { display: '0', value: 0 },
        { display: '1', value: 1 },
        { display: '2', value: 2 },
        { display: '3', value: 3 },
        { display: '4', value: 4 },
        { display: '5', value: 5 },
        { display: '6', value: 6 },
        { display: '7', value: 7 },
        { display: '8', value: 8 },
        { display: '9', value: 9 },
        { display: '10', value: 10 },
      ],
      conditionalQuestions: []
    },
    {
      id: 2,
      questionText: 'On a scale of 0 (no fatigue) to 10 (as bad as you can imagine), what number best describes:',
      questionEmphasis: 'Your USUAL level fatigue during the past 24 hours?',
      questionType: 'radio',
      uniqueAnswerId: 'BFI_FATIGUE_USUAL',
      autofill: false,
      answerOptions: [
        { display: '0', value: 0 },
        { display: '1', value: 1 },
        { display: '2', value: 2 },
        { display: '3', value: 3 },
        { display: '4', value: 4 },
        { display: '5', value: 5 },
        { display: '6', value: 6 },
        { display: '7', value: 7 },
        { display: '8', value: 8 },
        { display: '9', value: 9 },
        { display: '10', value: 10 },
      ],
      conditionalQuestions: []
    },
    {
      id: 3,
      questionText: 'On a scale of 0 (no fatigue) to 10 (as bad as you can imagine), what number best describes:',
      questionEmphasis: 'Your WORST level fatigue during the past 24 hours?',
      questionType: 'radio',
      uniqueAnswerId: 'BFI_FATIGUE_WORST',
      autofill: false,
      answerOptions: [
        { display: '0', value: 0 },
        { display: '1', value: 1 },
        { display: '2', value: 2 },
        { display: '3', value: 3 },
        { display: '4', value: 4 },
        { display: '5', value: 5 },
        { display: '6', value: 6 },
        { display: '7', value: 7 },
        { display: '8', value: 8 },
        { display: '9', value: 9 },
        { display: '10', value: 10 },
      ],
      conditionalQuestions: []
    },
    {
      id: 4,
      questionText: 'On a scale of 0 (does not interfere) to 10 (completely interferes), what number best describes how, during the past 24 hours, fatigue has interfered with your:',
      questionEmphasis: 'General activity?',
      questionType: 'radio',
      uniqueAnswerId: 'BFI_INTERFERED_GENERAL',
      autofill: false,
      answerOptions: [
        { display: '0', value: 0 },
        { display: '1', value: 1 },
        { display: '2', value: 2 },
        { display: '3', value: 3 },
        { display: '4', value: 4 },
        { display: '5', value: 5 },
        { display: '6', value: 6 },
        { display: '7', value: 7 },
        { display: '8', value: 8 },
        { display: '9', value: 9 },
        { display: '10', value: 10 },
      ],
      conditionalQuestions: []
    },
    {
      id: 5,
      questionText: 'On a scale of 0 (does not interfere) to 10 (completely interferes), what number best describes how, during the past 24 hours, fatigue has interfered with your:',
      questionEmphasis: 'Mood?',
      questionType: 'radio',
      uniqueAnswerId: 'BFI_INTERFERED_MOOD',
      autofill: false,
      answerOptions: [
        { display: '0', value: 0 },
        { display: '1', value: 1 },
        { display: '2', value: 2 },
        { display: '3', value: 3 },
        { display: '4', value: 4 },
        { display: '5', value: 5 },
        { display: '6', value: 6 },
        { display: '7', value: 7 },
        { display: '8', value: 8 },
        { display: '9', value: 9 },
        { display: '10', value: 10 },
      ],
      conditionalQuestions: []
    },
    {
      id: 6,
      questionText: 'On a scale of 0 (does not interfere) to 10 (completely interferes), what number best describes how, during the past 24 hours, fatigue has interfered with your:',
      questionEmphasis: 'Walking ability?',
      questionType: 'radio',
      uniqueAnswerId: 'BFI_INTERFERED_WALKING',
      autofill: false,
      answerOptions: [
        { display: '0', value: 0 },
        { display: '1', value: 1 },
        { display: '2', value: 2 },
        { display: '3', value: 3 },
        { display: '4', value: 4 },
        { display: '5', value: 5 },
        { display: '6', value: 6 },
        { display: '7', value: 7 },
        { display: '8', value: 8 },
        { display: '9', value: 9 },
        { display: '10', value: 10 },
      ],
      conditionalQuestions: []
    },
    {
      id: 7,
      questionText: 'On a scale of 0 (does not interfere) to 10 (completely interferes), what number best describes how, during the past 24 hours, fatigue has interfered with your:',
      questionEmphasis: 'Normal work (includes both work outside the home and daily chores?)',
      questionType: 'radio',
      uniqueAnswerId: 'BFI_INTERFERED_WORK',
      autofill: false,
      answerOptions: [
        { display: '0', value: 0 },
        { display: '1', value: 1 },
        { display: '2', value: 2 },
        { display: '3', value: 3 },
        { display: '4', value: 4 },
        { display: '5', value: 5 },
        { display: '6', value: 6 },
        { display: '7', value: 7 },
        { display: '8', value: 8 },
        { display: '9', value: 9 },
        { display: '10', value: 10 },
      ],
      conditionalQuestions: []
    },
    {
      id: 8,
      questionText: 'On a scale of 0 (does not interfere) to 10 (completely interferes), what number best describes how, during the past 24 hours, fatigue has interfered with your:',
      questionEmphasis: 'Relations with other people?',
      questionType: 'radio',
      uniqueAnswerId: 'BFI_INTERFERED_RELATIONS',
      autofill: false,
      answerOptions: [
        { display: '0', value: 0 },
        { display: '1', value: 1 },
        { display: '2', value: 2 },
        { display: '3', value: 3 },
        { display: '4', value: 4 },
        { display: '5', value: 5 },
        { display: '6', value: 6 },
        { display: '7', value: 7 },
        { display: '8', value: 8 },
        { display: '9', value: 9 },
        { display: '10', value: 10 },
      ],
      conditionalQuestions: []
    },
    {
      id: 9,
      questionText: 'On a scale of 0 (does not interfere) to 10 (completely interferes), what number best describes how, during the past 24 hours, fatigue has interfered with your:',
      questionEmphasis: 'Enjoyment of life?',
      questionType: 'radio',
      uniqueAnswerId: 'BFI_INTERFERED_ENJOYMENT',
      autofill: false,
      answerOptions: [
        { display: '0', value: 0 },
        { display: '1', value: 1 },
        { display: '2', value: 2 },
        { display: '3', value: 3 },
        { display: '4', value: 4 },
        { display: '5', value: 5 },
        { display: '6', value: 6 },
        { display: '7', value: 7 },
        { display: '8', value: 8 },
        { display: '9', value: 9 },
        { display: '10', value: 10 },
      ],
      conditionalQuestions: []
    }
  ]
  */
};
