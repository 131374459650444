import { AssessmentQuestion } from '../lib/services/models';
import { Organization, Role } from '../core';
import moment from 'moment';

import { swpsDef } from './definitions/logs/swps';
import { swps2Def } from './definitions/logs/swps2';
import { swps3Def } from './definitions/logs/swps3';
import { swps4Def } from './definitions/logs/swps4';
import { cgiDef } from './definitions/logs/cgi';
import { essWeeklyDef } from './definitions/logs/ess-weekly';
import { essDef } from './definitions/logs/ess';
import { eveningLogDef } from './definitions/logs/evening-log';
import { gad2Def } from './definitions/logs/gad2';
import { gad7Def } from './definitions/logs/gad7';
import { isiDef } from './definitions/logs/isi';
import { medicationUseDef } from './definitions/logs/medication-use';
import { morningLogDef } from './definitions/logs/morning-log';
import { pcPtsdDef } from './definitions/logs/pc-ptsd';
import { pcl5Def } from './definitions/logs/pcl5';
import { pegDef } from './definitions/logs/peg';
import { pgiDef } from './definitions/logs/pgi';
import { phq2Def } from './definitions/logs/phq2';
import { phq8Def } from './definitions/logs/phq8';
import { promisDef } from './definitions/logs/promis';
import { shiftWorkDef } from './definitions/logs/shift-work';
import { sideEffectsDef } from './definitions/logs/side-effects';
import { sleepGoalsPostDef } from './definitions/logs/sleep-goals-post';
import { sleepGoalsPreDef } from './definitions/logs/sleep-goals-pre';
import { susDef } from './definitions/logs/sus';
import { sus2Def } from './definitions/logs/sus2';
import { trauma } from './definitions/logs/trauma';
import { osaDef } from './definitions/logs/osa';

import { aspirantDef } from './definitions/coins/aspirant';
import { defenderDef } from './definitions/coins/defender';
import { luminaryDef } from './definitions/coins/luminary';
import { maestroDef } from './definitions/coins/maestro';

import { baselineDef } from './definitions/treatment-plan-phases/baseline';
// import { checkinDef } from './definitions/treatment-plan-phases/checkin';
import { followupDef } from './definitions/treatment-plan-phases/followup';
import { followup2Def } from './definitions/treatment-plan-phases/followup2';
import { followup3Def } from './definitions/treatment-plan-phases/followup3';
import { weekFiveDef } from './definitions/treatment-plan-phases/weekFive';
import { weekFourDef } from './definitions/treatment-plan-phases/weekFour';
import { weekOneDef } from './definitions/treatment-plan-phases/weekOne';
import { weekThreeDef } from './definitions/treatment-plan-phases/weekThree';
import { weekTwoDef } from './definitions/treatment-plan-phases/weekTwo';
import { aceDaytimeSleepDef } from '../../../aire/constant-definitions/modules/definitions/ace-daytime-sleep';
import { trainingFeedback } from '../../../aire/constant-definitions/modules/definitions/training-feedback';
import { buildConfidenceDef } from '../../../aire/constant-definitions/modules/definitions/build-confidence';
import { strategicNappingDef } from '../../../aire/constant-definitions/modules/definitions/strategic-napping';
import { prioritizeThoughtsDef } from '../../../aire/constant-definitions/modules/definitions/compartmentalization';
import { controlJetLagDef } from '../../../aire/constant-definitions/modules/definitions/control-jet-lag';
import { dangersOfFatigueDef } from '../../../aire/constant-definitions/modules/definitions/dangers-of-fatigue';
import { dreamRetrainingDef } from '../../../aire/constant-definitions/modules/definitions/dream-retraining';
import { fatigueScanningDef } from '../../../aire/constant-definitions/modules/definitions/fatigue-scanning';
import { gaugeSleepHealthDef } from '../../../aire/constant-definitions/modules/definitions/gauge-sleep-health';
import { groundingDef } from '../../../aire/constant-definitions/modules/definitions/grounding';
import { powerOfSleepDef } from '../../../aire/constant-definitions/modules/definitions/power-of-sleep';
import { prepareThroughVisualizationDef } from '../../../aire/constant-definitions/modules/definitions/prepare-through-visualization';
import { relaxationDef } from '../../../aire/constant-definitions/modules/definitions/relaxation';
import { sleepCheckIn } from '../../../aire/constant-definitions/modules/definitions/sleep-check-in';
import { stressCheckIn } from '../../../aire/constant-definitions/modules/definitions/stress-check-in';
import { teamCheckIn } from '../../../aire/constant-definitions/modules/definitions/team-check-in';
import { sleepAndHighTempoOpsDef } from '../../../aire/constant-definitions/modules/definitions/sleep-and-high-tempo-ops';
import { sleepAndNightOpsDef } from '../../../aire/constant-definitions/modules/definitions/sleep-and-night-ops';
import { sleepAreaSafeguardingDef } from '../../../aire/constant-definitions/modules/definitions/sleep-area-safeguarding';
import { upYourSleepAreaDef } from '../../../aire/constant-definitions/modules/definitions/up-your-sleep-area';
import { sleepBankingDef } from '../../../aire/constant-definitions/modules/definitions/sleep-banking';
import { strategicCaffeineDef } from '../../../aire/constant-definitions/modules/definitions/strategic-caffeine';
import { tacticalBreathingDef } from '../../../aire/constant-definitions/modules/definitions/tactical-breathing';
import { teamCommunicationDef } from '../../../aire/constant-definitions/modules/definitions/team-communication';
import { twoInternalSystemsDef } from '../../../aire/constant-definitions/modules/definitions/two-internal-sleep-systems';
import { unwindBeforeBedDef } from '../../../aire/constant-definitions/modules/definitions/unwind-before-bed';
import { osaDetailsTrueDef } from './definitions/logs/osa-details-true';
import { osaDetailsFalseDef } from './definitions/logs/osa-details-false';

//WWP
import { nsiDef } from './definitions/logs/nsi';
import { niqMDef } from './definitions/logs/niq-m';
import { niqWDef } from './definitions/logs/niq-w';

//1.5 Custom batteries
import { npsDef } from './definitions/logs/nps';

//VA Custom batteries
import { demographicsDef } from './definitions/logs/demographics';
import { proprDef } from './definitions/logs/propr';
import { pssuqDef } from './definitions/logs/pssuq';

//CICADA Custom Batteries
import { sleepHealthToolsDef } from './definitions/logs/sleep-health-tools';
import { cicadaSessionsDef } from './definitions/logs/cicada-sessions';

// Intermountain
import { phq3Def } from './definitions/logs/phq3';
import { phq9Def } from './definitions/logs/phq9';

// TBI Study
import { TBIPostConcussionDef } from './definitions/logs/tbi-post-concussion';
import { TBIPcl5Def } from './definitions/logs/tbi-pcl5';
import { TBIPhq8Def } from './definitions/logs/tbi-phq8';
import { TBIGad7Def } from './definitions/logs/tbi-gad7';
import { TBIBriefFatigueInventoryDef } from './definitions/logs/tbi-brief-fatigue-inventory';
import { TBIWHODAS2Def } from './definitions/logs/tbi-whodas2';
import { TBIInsomniaTreatmentAcceptabilityDef } from './definitions/logs/tbi-insomnia-treatment-acceptability';
import { TBIClientSatisfationDef } from './definitions/logs/tbi-client-satisfaction';
import { TBISideEffectsDef } from './definitions/logs/tbi-side-effects';
import { TBIPhq2Def } from './definitions/logs/tbi-phq2';

export const APPLICATION_ORGANIZATION: Organization = {
  _id: '5f082e42-0d84-4853-896f-2884f1cd44e7',
  name: 'noctem',
  roles: [Role.DefaultUser, Role.Administrator],
};

//TODO: hard-coded soldier study end date. Remove
export const soldierStudyEndDate = moment('20201-04-30', 'YYYY-MM-DD');

export let logDefinitions = [
  swpsDef,
  swps2Def,
  swps3Def,
  swps4Def,
  cgiDef,
  essDef,
  essWeeklyDef,
  eveningLogDef,
  gad2Def,
  gad7Def,
  isiDef,
  medicationUseDef,
  morningLogDef,
  pcl5Def,
  pcPtsdDef,
  pegDef,
  pgiDef,
  phq2Def,
  phq8Def,
  promisDef,
  shiftWorkDef,
  sideEffectsDef,
  sleepGoalsPostDef,
  sleepGoalsPreDef,
  sus2Def,
  susDef,
  trauma,
  osaDef,
  osaDetailsTrueDef,
  osaDetailsFalseDef,
  nsiDef,
  niqMDef,
  niqWDef,
  npsDef,
  demographicsDef,
  proprDef,
  pssuqDef,
  sleepHealthToolsDef,
  cicadaSessionsDef,
  phq3Def,
  phq9Def,
  TBIPostConcussionDef,
  TBIPcl5Def,
  TBIPhq8Def,
  TBIGad7Def,
  TBIBriefFatigueInventoryDef,
  TBIWHODAS2Def,
  TBIInsomniaTreatmentAcceptabilityDef,
  TBIClientSatisfationDef,
  TBISideEffectsDef,
  TBIPhq2Def,
];

export enum LogDefinitionTypes {
  SWPS = 'swps',
  SWPS2 = 'swps2',
  SWPS3 = 'swps3',
  SWPS4 = 'swps4',
  CGI = 'cgi',
  ESS = 'ess',
  ESSWeekly = 'essWeekly',
  EveningLog = 'eveningLog',
  GAD2 = 'gad2',
  GAD7 = 'gad7',
  ISI = 'isi',
  MedicationUse = 'medicationUse',
  MorningLog = 'morningLog',
  PCL5 = 'pcl5',
  PCL5Short = 'pcl5Short',
  PCPTSD = 'pcPtsd',
  PCPTSD5 = 'pcPtsd5',
  PEG = 'peg',
  PGI = 'pgi',
  PHQ = 'phq',
  PHQ2 = 'phq2',
  PHQ8 = 'phq8',
  PROMIS = 'promis',
  ShiftWork = 'shiftWork',
  SideEffects = 'sideEffects',
  SleepGoalsPost = 'sleepGoalsPost',
  SleepGoalsPre = 'sleepGoalsPre',
  SUS2 = 'sus2',
  SUS = 'sus',
  DailyCheckIn = 'dailyCheckIn',
  Trauma = 'trauma',
  SCI = 'sleep-check-in',
  TeamCommunication = 'team-dynamics',
  VACDS = 'vacds',
  VACTCS = 'vactcs',
  OSA = 'osa',
  OSADetailsTrue = 'osa-details-true',
  OSADetailsFalse = 'osa-details-false',
  NSI = 'nsi',
  NIQM = 'niqm',
  NIQW = 'niqw',
  NPS = 'nps',
  Demographics = 'demographics',
  PROPR = 'propr',
  PSSUQ = 'pssuq',
  SleepHealthTools = 'sleepHealthTools',
  CicadaSessions = 'cicadaSessions',
  // Intermountain
  PHQ3 = 'phq3',
  PHQ9 = 'phq9',
  // TBI Study
  TBIPostConcussion = 'tbi-post-concussion',
  TBIPcl5 = 'tbi-pcl5',
  TBIPhq8 = 'tbi-phq8',
  TBIGad7 = 'tbi-gad7',
  TBIBriefFatigueInventory = 'tbi-brief-fatigue-inventory',
  TBIWHODAS2 = 'tbi-whodas2',
  TBIInsomniaTreatmentAcceptability = 'tbi-insomnia-treatment-acceptability',
  TBIClientSatisfation = 'tbi-client-satisfaction',
  TBISideEffects = 'tbi-side-effects',
  TBIPhq2 = 'tbi-phq2',
}

export const LogDefinitionTypeIds = {
  swps: 0,
  cgi: 1,
  ess: 2,
  essWeekly: 3,
  eveningLog: 4,
  gad2: 5,
  gad7: 6,
  isi: 7,
  mediciationUse: 8,
  morningLog: 9,
  pcl5: 10,
  pcPtsd: 11,
  peg: 12,
  pgi: 13,
  phq: 14,
  phq8: 15,
  promis: 16,
  shiftWork: 17,
  sideEffects: 18,
  sleepGoalsPost: 19,
  sleepGoalsPre: 20,
  sus2: 21,
  sus: 22,
  dailyCheckIn: 23,
  osa: 24,
  osaDetailsTrue: 25,
  osaDetailsFalse: 26,
  swps2: 27,
  swps3: 28,
  swps4: 29,
  nsi: 30,
  niqm: 31,
  niqw: 32,
  nps: 33,
  demographics: 34,
  propr: 35,
  pssuq: 36,
  sleepHealthTools: 37,
  cicadaSessions: 38,
  phq3: 39,
  phq9: 40,
  TBIPostConcussion: 41,
  TBIPcl5: 42,
  TBIPhq8: 43,
  TBIGad7: 44,
  TBIBriefFatigueInventory: 45,
  TBIWHODAS2: 46,
  TBIClientSatisfation: 47,
  TBIInsomniaTreatmentAcceptability: 48,
  TBISideEffects: 49,
  TBIPhq2: 50,
};

export const timeouts = {
  weekly: 7
}

export const moduleIds = {
  'ace-daytime-sleep': aceDaytimeSleepDef.id,
  trainingFeedback: trainingFeedback.id,
  'build-confidence': buildConfidenceDef.id,
  'prioritize-thoughts': prioritizeThoughtsDef.id,
  'control-jet-lag': controlJetLagDef.id,
  'dangers-of-fatigue': dangersOfFatigueDef.id,
  'dream-retraining': dreamRetrainingDef.id,
  'fatigue-scanning': fatigueScanningDef.id,
  'gauge-sleep-health': gaugeSleepHealthDef.id,
  grounding: groundingDef.id,
  'prepare-through-visualization': prepareThroughVisualizationDef.id,
  relaxation: relaxationDef.id,
  'sleep-check-in': sleepCheckIn.id,
  'stress-check-in': stressCheckIn.id,
  'team-check-in': teamCheckIn.id,
  'sleep-and-high-tempo-ops': sleepAndHighTempoOpsDef.id,
  'sleep-and-night-ops': sleepAndNightOpsDef.id,
  'sleep-area-safeguarding': sleepAreaSafeguardingDef.id,
  'up-your-sleep-area': upYourSleepAreaDef.id,
  'sleep-banking': sleepBankingDef.id,
  'strategic-caffeine': strategicCaffeineDef.id,
  'tactical-breathing': tacticalBreathingDef.id,
  'team-communication': teamCommunicationDef.id,
  'two-internal-sleep-systems': twoInternalSystemsDef.id,
  'unwind-before-bed': unwindBeforeBedDef.id,
  'strategic-napping': strategicNappingDef.id,
  'power-of-sleep': powerOfSleepDef.id
};

export const coinDefinitions = [aspirantDef, maestroDef , luminaryDef, defenderDef];

// !!Order here matters!!
export const treatmentPlanPhases = {
  baseline: baselineDef,
  week1: weekOneDef,
  week2: weekTwoDef,
  week3: weekThreeDef,
  week4: weekFourDef,
  post: weekFiveDef,
  followup1: followupDef,
  followup2: followup2Def,
  followup3: followup3Def
};

export const sleepFacts = [
  '24 hours of no sleep affects performance in similar ways to a blood alcohol level of .1 (.08 is considered drunk)',
  'Staying awake all night burns about 1 homemade cookie’s worth of calories',
  'Parrotfish secrete a substance that forms a natural made sleeping bag to sleep in every evening',
  'Fire ants take hundreds of power naps per day each lasting around 1 minute',
  'The first modern-day spring mattress was invented in the early 1870s by Heinrich Westphal using springs like those used in the seats of horse-drawn carriages.',
  'The use of the letter \'Z\' to represent sleeping or snoring first appeared in a Katzenjammer Kids comic strip in 1903',
  'According to the Guinness World Records, the largest blanket fort was built in 2018 measuring 625.79 square meters'
];

export const contacts = [
  {
    name: 'Anne Germain, PhD',
    company: 'Noctem Health',
    email: 'anne@noctemhealth.com',
    imageUrl: ''
  },
  {
    name: 'Megan Wolfson',
    company: 'Noctem Health',
    email: 'megan@noctemhealth.com',
    imageUrl: ''
  }
];

export const logTypes = {
  morning: 'morningLog',
  evening: 'eveningLog'
};

export const uniqueAnswerIds = {
    routineStart: 'ROUTINE_START',
    riseTime: 'RISE_TIME',
    wakeTime: 'WAKE_TIME',
    gotIntoBed: 'GOT_INTO_BED',
    attemptToSleep: 'ATTEMPT_TO_SLEEP',
    minutesToSleep: 'MINUTES_TO_SLEEP',
    wasRestless: 'WAS_RESTLESS',
    totalMinutesWokeups: 'TOTAL_MINUTES_WOKEUPS',
    alcoholicDrinks: 'ALCOHOL_DRINKS',
    caffeinatedDrinks: 'CAFFEINATED_DRINKS',
    sleepyPeriod: 'SLEEPY_PERIOD',
    sleptPast24: 'SLEPT_IN_PAST_24',
    naps: 'NAPS',
    napsNumber: 'NAPS_NUMBER',
    napsDuration: 'NAPS_DURATION'
};




export enum ModuleTypes {
    TWO_SLEEP_SYSTEMS = 'TWO_INTERNAL_SLEEP_SYSTEMS',
    SLEEP_AIDS = 'SLEEP_AIDS',
    SLEEP_AND_FATIGUE = 'SLEEP_AND_FATIGUE',
    SLEEP_ENVIRONMENT = 'SLEEP_READY_ENVIRONMENT',
    TIME_ZONE_ADAPTATION = 'TIME_ZONE_ADAPTATION',
    SLEEP_NIGHT_OPS = 'SLEEP_AND_NIGHT_OPS',
    SLEEP_TEMPO_OPS = 'SLEEP_AND_HIGH_TEMPO_OPS',
    STRATEGIC_CAFFEINE = 'STRATEGIC_CAFFEINE_USE'
  }

export enum sleepTrendsThreshold {
    NONE = 0,
    GREEN = 1,
    YELLOW = 2,
    RED = 3
}

export enum ReportType {
  INTERVENTION_REPORT = 1
}
