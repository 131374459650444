export const TBISideEffectsDef = {
  name: 'tbi-side-effects',
  description: 'Side Effect Scale',
  instructions: 'Have you noticed any of the following symptoms in the past week?',
  /*
  Note: the questions property must be empty here (but available in the database) or this assessment will be made available for all patients

  questions: [
    {
      id: 0,
      questionText: 'Physical tiredness?',
      questionEmphasis: '',
      questionType: 'radio',
      uniqueAnswerId: 'SIDE_EFFECT_TIREDNESS',
      autofill: false,
      answerOptions: [
        { display: 'None', value: 0 },
        { display: "I tire easily but I don't have to take breaks more often than usual", value: 1 },
        { display: 'I tire easily and I am forced to rest or take breaks', value: 2 },
        { display: 'I am exhausted and I cannot do almost any activity', value: 3 },
      ],
      conditionalQuestions: []
    },
    {
      id: 1,
      questionText: 'Headaches?',
      questionEmphasis: '',
      questionType: 'radio',
      uniqueAnswerId: 'SIDE_EFFECT_HEADACHES',
      autofill: false,
      answerOptions: [
        { display: 'None', value: 0 },
        { display: 'Occasional headache(s) but it does not interfere with my activities', value: 1 },
        { display: 'Constant and moderate or occasional and severe headache(s)', value: 2 },
        { display: 'Constant and severe headache(s)', value: 3 },
      ],
      conditionalQuestions: []
    },
    {
      id: 2,
      questionText: 'Dizziness?',
      questionEmphasis: '',
      questionType: 'radio',
      uniqueAnswerId: 'SIDE_EFFECT_DIZZINESS',
      autofill: false,
      answerOptions: [
        { display: 'None', value: 0 },
        { display: 'Occasional slight dizziness', value: 1 },
        { display: 'Constant slight dizziness or occasional and severe dizziness', value: 2 },
        { display: 'Constant and severe dizziness', value: 3 },
      ],
      conditionalQuestions: []
    },
    {
      id: 3,
      questionText: 'Reduced sexual interest?',
      questionEmphasis: '',
      questionType: 'radio',
      uniqueAnswerId: 'SIDE_EFFECT_SEX_INTEREST',
      autofill: false,
      answerOptions: [
        { display: 'None', value: 0 },
        { display: 'My sexual interest is reduced but not impaired', value: 1 },
        { display: 'Definite reduction in sexual interest. My sexual activities are reduced or non-existent', value: 2 },
        { display: 'Complete sexual indifference', value: 3 },
      ],
      conditionalQuestions: []
    },
    {
      id: 4,
      questionText: 'Drowsiness?',
      questionEmphasis: '',
      questionType: 'radio',
      uniqueAnswerId: 'SIDE_EFFECT_DROWSINESS',
      autofill: false,
      answerOptions: [
        { display: 'None', value: 0 },
        { display: 'Slight yawns occasionally or others may say I look sleepy', value: 1 },
        { display: 'I can easily fall asleep when left in peace', value: 2 },
        { display: 'Severely drowsy; it is difficult to stay awake during the day and it interferes with my daily routines', value: 3 },
      ],
      conditionalQuestions: []
    },
    {
      id: 5,
      questionText: 'Muscle tightness?',
      questionEmphasis: '',
      questionType: 'radio',
      uniqueAnswerId: 'SIDE_EFFECT_MUSCLE_TIGHTNESS',
      autofill: false,
      answerOptions: [
        { display: 'None', value: 0 },
        { display: 'Slight muscle stiffness but does not interfere with my daily activities', value: 1 },
        { display: 'Moderate muscle stiffness with some interference of my daily activities', value: 2 },
        { display: 'Constant and severe muscle stiffness', value: 3 },
      ],
      conditionalQuestions: []
    },
    {
      id: 6,
      questionText: 'Sensitivity to light or noise?',
      questionEmphasis: '',
      questionType: 'radio',
      uniqueAnswerId: 'SIDE_EFFECT_SENSITIVITY_LIGHT_NOISE',
      autofill: false,
      answerOptions: [
        { display: 'None', value: 0 },
        { display: 'Slight sensitivity to light or noise but does not interfere with my daily activities', value: 1 },
        { display: 'Moderate sensitivity to light or noise with some interference of my daily activities', value: 2 },
        { display: 'Constant and severe', value: 3 },
      ],
      conditionalQuestions: []
    },
  ]
  */
};
