import * as _ from 'lodash';
import { Injectable, Inject } from '@angular/core';
import { ApiHelper, IHttpProvider, IApplicationContext, ApplicationContext, BaseObject, BaseService, BaseRepository } from '../core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface ITranslations {
    Format(value: string, ...args: any[]): string;
    Get(key: string, ...args: any[]): string;
}

@Injectable()
export class BaseTranslations implements ITranslations {
    public Format(value: string, ...args: any[]): string { throw Error('Not implemented'); }
    public Get(key: string, ...args: any[]): string { throw Error('Not implemented'); }
}

@Injectable()
export class Translations extends BaseTranslations {
}

export class CacheModel {
    public Model: string;
    public LastUpdatedOn: string;
    public DurationMinutes: number;
}
export class Cache extends BaseObject {
    public Models: CacheModel[];
}

@Injectable()
export class CacheService extends BaseService<Cache> {
    modelName = 'Cache';

    constructor(@Inject(ApplicationContext) applicationContext: IApplicationContext) {
        super(new BaseRepository<Cache>(applicationContext, 'Cache'));
    }

    public getByApplicationName(name: string): Observable<Cache> {
        const query = { 'Payload.Applications.Name': name };
        return this.getAll(query).pipe(
            map(results => {
                return results && results.length > 0 ? results[0] : null;
            })
        );
    }

    public getCacheForModel(applicationName: string, modelName: string): Observable<CacheModel> {
        return this.getByApplicationName(applicationName).pipe(
            map(cache => {
                const match = _.find(cache.Models, m => m.Model === modelName);
                return match;
            })
        );
    }

}

@Injectable({
    providedIn: 'root'
})
export class TranslationService {
    public static singleton: TranslationService;
    private static data: any;
    private apiHelper: ApiHelper;
    private httpProvider: IHttpProvider;

    private clientName: string;
    private currentCulture: string;

    constructor(private applicationContext: ApplicationContext, private translations: Translations) {
        this.currentCulture = 'en-US';
        TranslationService.singleton = this;
        this.apiHelper = new ApiHelper(applicationContext);
    }

    initialize(token?: string, cacheModel?: CacheModel, currentCulture?: string) {
        if (currentCulture) {
            this.currentCulture = currentCulture;
        }
        return Observable.create(o => {
            const setupTranslations = (data) => {
                TranslationService.data = data;
                // Initialize all of the translations
                this.translations = this.setChild(this.translations);
                const lastUpdatedOn = new Date().getTime();
                const cacheTimeout = cacheModel && cacheModel.DurationMinutes ? cacheModel.DurationMinutes : 1 * 24 * 60;
                const expireTime = lastUpdatedOn + (cacheTimeout * 60000); // cacheTimeout
                localStorage.setItem('translationsCache',
                    JSON.stringify({ data: data, lastUpdatedOn: lastUpdatedOn, expires: expireTime }));
                o.next({});
                o.complete();
            }

            const cachedTranslations = <any>JSON.parse(localStorage.getItem('translationsCache'));
            // If a last updated on time is passed in, and our cache is older, we need to update
            const timestamp = new Date().getTime();
            let isExpired = cachedTranslations ? cachedTranslations.expires < timestamp : true;
            isExpired = !isExpired && cacheModel && cachedTranslations ?
                new Date(cacheModel.LastUpdatedOn).getTime() > cachedTranslations.lastUpdatedOn : isExpired;
            if (!isExpired) {
                setupTranslations(cachedTranslations.data);
            } else {
                const appContext = <IApplicationContext>this.applicationContext;
                this.httpProvider = appContext.HttpProvider;
                if (token) {
                    this.applicationContext.Token = token;
                    this.apiHelper = new ApiHelper(this.applicationContext);
                }

                const params = {
                    uri: '/api/translation/all',
                    headers: this.apiHelper.Headers(),
                    json: null,
                    data: null,
                    method: 'GET'
                }
                this.httpProvider.get(params).subscribe(response => {
                    if (response.success) {
                        setupTranslations(response.data);
                    } else {
                        console.log('Failed to get translations');
                        o.next(null);
                        o.complete();
                    }

                });
            }


        });
    }

    private setChild(parent): any {
        for (const child in parent) {
            if (parent.hasOwnProperty(child)) {
                // If it has no children, fetch the key
                if (typeof (parent[child]) === 'object') {
                    parent[child] = this.setChild(parent[child]);
                } else {
                    const translationKey = parent[child];
                    const translatedValue = this.getValueByKey(translationKey);
                    // Now change the value (which was the key by default) to the translated value

                    parent[child] = translatedValue;
                }
            }
        }
        return parent;
    }

    public get(): ITranslations {
        this.translations.Format = (value, args) => this.format(value, args);
        this.translations.Get = (key, args) => TranslationService.singleton.getValueByKey(key, null, args);
        return this.translations;
    }

    public format(value: string, ...args: any[]): string {
        const matches = value.match(/:[0-9]+:/g);

        if (matches) {
            for (let i = 0; i < matches.length; i++) {
                const match = matches[i];

                const indexReg = new RegExp('[0-9]+');
                const numberMatch = indexReg.exec(match);
                // Get the index from the match
                const index = numberMatch.length > 0 ? parseInt(numberMatch[0], 10) : -1;

                // Find the replacement from the args list passed in
                let replacement = '';
                if (index >= 0 && index < args.length) {
                    replacement = args[index];
                }

                // Replace the instances of the match
                value = value.replace(match, replacement);
            }
        }

        return value;
    }

    public getValueByKey(key: string, defaultValue?: string, ...args: any[]): string {
        // var currentCulture = translationService.currentLanguageCulture();

        if (!defaultValue) {
            defaultValue = key;
        }

        const fetchValue = (keyParam) => {
            return TranslationService.data[this.currentCulture] && TranslationService.data[this.currentCulture][keyParam] != null ?
                TranslationService.data[this.currentCulture][keyParam] : null;
        }

        // First try the key with the client name.  If there is none, use the actual key passed in
        // e.g. Key passed in is 'Home.MyBags'.  Try 'ClientName.Home.MyBags' first, then 'home.MyBags' if none
        let value;
        if (this.clientName) {
            const clientKey = this.clientName + '.' + key;
            value = fetchValue(clientKey);
        }

        value = value || fetchValue(key);
        value = value != null ? value : defaultValue;

        if (args && args.length > 0) {
            value = this.format(value, ...args);
        }
        return value;
    }


}
