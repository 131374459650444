import { ControlValueAccessor, NgControl } from '@angular/forms';
import { Input } from '@angular/core';
import { ErrorMessageHashtable, LayoutDirection, DefaultCssClasses } from '../constants';



export default class BaseInputComponent implements ControlValueAccessor {
  constructor(public control: NgControl) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  @Input() id;
  @Input() inputHelpText;
  @Input() labelText;
  @Input() layoutDirection = LayoutDirection.Horizontal;
  @Input() cssClass = '';
  @Input() readOnly = false;

  private _value;


  /**
   * Flag to disable input control
   */
  protected isDisabled = false;


 /**
  * Getter for _value
  */
  public get value() {
    return this._value;
  }

  /**
   * Setting for _value
   */
  public set value(v) {
    this._value = v;
  }

  public get errorMessages(): string[] {
    const errorMessages: string[] = [];
    if (this.control && this.control.errors) {
      Object.keys(this.control.errors).map(key => {
        errorMessages.push(this.getErrorMessage(key));
      });
    }
    return errorMessages;
  }

  private getErrorMessage(key: string): string {
    if (this.control.errors[key].errorMessage) {
      return this.control.errors[key].errorMessage;
    } else if (key in ErrorMessageHashtable) {
      return ErrorMessageHashtable[key].replace('{0}', this.getLimits(key));
    } else {
      return key;
    }
  }

  public get cssClasses(): string {
    return `${DefaultCssClasses} ${this.cssClass}`;
  }

  private getLimits(key: string): string {
    const error: any = this.control.errors[key];
    const keys: string[] = Object.keys(error);

    // Most validators only return an object with a property set to 'true'
    // However, validators that use limits such as min and max will return an object
    // with the set validation limit as the first property, and the actual value the user entered
    // as the second property.  So we need to just grab that first property if there are multiple
    if (keys?.length > 1) {
      return error[keys[0]];
    }
    return '';
  }

  public writeValue(obj): void {
    if (obj !== undefined) {
      this._value = obj;
    }
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public onChange: any = (event) => {
    this.value = event.target.value;
    this.propagateChange(this._value);
  }

  onTouched: any = () => { };
  private propagateChange = (_: any) => {};

}
