// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import pkg from '../../../../../package.json';
const buildHash = pkg.buildHash || pkg.version || 'N/A';
const appVersion = pkg.version || 'N/A';

export const environment = {
  appVersion: appVersion,
  buildHash: buildHash,
  production: false,
  organization: 'noctem',
  token: '/O3knwszBEuwjhdE1fqXaw==3Jiiicvui0yD1tugMvWwgg==',
  appHost: '',
  appSocketHost: '',
  use24Hours: true
  
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
