import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService, IApplicationContext, ApiHelper, IHttpProvider, BaseRepository, UserSettings, Role, Device, Organization } from '../../../core';
import { Injectable, Inject } from '@angular/core';


@Injectable()
export class UserService<T extends UserSettings> extends BaseService<T> {
    private http: IHttpProvider;
    private helper: ApiHelper;
    constructor(@Inject(BaseRepository) private appContext: IApplicationContext) {
        super(new BaseRepository(appContext, 'UserSettings'));
        this.http = appContext.HttpProvider;
        this.helper = new ApiHelper(appContext);
    }

    public getCurrentUser(): Observable<T> {
        const params: any = {
            uri: `${this.helper.RootUrl()}/auth/current-user`,
            json: null, data: null,
            method: 'GET',
            headers: this.helper.Headers()
        };
        return this.http.get(params).pipe(
            map((r: any) => {
                return r.user && r.user.Attributes ?  r.user.Attributes : null;
            })
        );
    }

    public getCurrentUserInfo(): Observable<{ User: T, Roles: Role[], Organizations: Organization[] }> {
        const params: any = {
            uri:  `${this.helper.RootUrl()}/auth/current-user`,
            json: null, data: null,
            method: 'GET',
            headers: this.helper.Headers()
        };
        return this.http.get(params).pipe(
            map((r: any) => {
                if (!r.user.Attributes) {
                    r.user.Attributes = { profile: { emailAddress: r.user.EmailAddress }};
                }

                if (!r.user.Attributes.profile.emailAddress) {
                    r.user.Attributes.profile.emailAddress = r.user.EmailAddress;
                }

                return r.user && r.user.Attributes ? {User: r.user.Attributes, Roles: r.user.Roles, Organizations: r.user.Organizations } : null;
            })
        );
    }

    public updateNotificationsToken(token: string): Observable<T> {
        const params: any = {
            uri: this.helper.RootUrl() + '/auth/updateNotificationToken',
            json: {
                Id: token,
                Version: "",
                AppVersion: ""
            }, data: null,
            method: 'POST',
            headers: this.helper.Headers()
        };
        return this.http.post(params).pipe(
            map((r: any) => {
                return r.user;
            })
        );
    }

    public save(user: T): Observable<T> {
        return super.save(user).pipe(
            map(response => {
                return response;
            })
        );
    }
}

export class DefaultUserService extends UserService<UserSettings> {

}
