export const TBIPhq2Def = {
  "name": "tbi-phq2",
  "description": "Patient Health Questionnaire",
  "instructions": "",
  /*
  Note: the questions property must be empty here (but available in the database) or this assessment will be made available for all patients

  "questions": [
    {
      "id": 0,
      "name": "phq2-interest",
      "questionText": "In the past week, how often have you been bothered by:",
      "questionEmphasis": "Little interest or pleasure in doing things",
      "questionType": "radio",
      "uniqueAnswerId": "PHQ2_INTEREST",
      "autofill": false,
      "answerOptions": [
        { "display": "Not at all", "value": 0 },
        { "display": "Several Days", "value": 1 },
        { "display": "More than half the days", "value": 2 },
        { "display": "Nearly every day", "value": 3 },
        { "display": "Prefer not to answer", "value": "N/A" }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 1,
      "name": "phq2-depression",
      "questionText": "In the past week, how often have you been bothered by:",
      "questionEmphasis": "Feeling down, depressed, or hopeless",
      "questionType": "radio",
      "uniqueAnswerId": "PHQ2_DEPRESSION",
      "autofill": false,
      "answerOptions": [
        { "display": "Not at all", "value": 0 },
        { "display": "Several Days", "value": 1 },
        { "display": "More than half the days", "value": 2 },
        { "display": "Nearly every day", "value": 3 },
        { "display": "Prefer not to answer", "value": "N/A" }
      ],
      "conditionalQuestions": []
    }
  ]
  */
};
