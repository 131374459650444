import { AssessmentDefinition } from '../../../../../noctem-lib/src/core/contracts/models.calculations';


export const powerOfSleep: AssessmentDefinition = {
  name: 'power-of-sleep',
  description: 'Power of Sleep',
  instructions: null,
  questions: [
    {
      id: 0,
      questionText: 'Sleep is a basic biological need, just like food and water',
      questionEmphasis: ``,
      subText: `<p>There is no training, grit, or experience that can<br />beat the need for sleep.</p>
                <p>Under normal conditions, getting one 7-9 hour period of sleep per 24-hours is recommended.</p>
                <p>Good news! When in the field, a few shorter sleep periods that all add up to 7-9 hours is effective for sustained alertness and performance.</p>
      `,
      questionType: 'module',

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
        id: 1,
        questionText: '',
        questionEmphasis: ``,
        subText: `<p>Total hours of sleep and sleep quality impact your performance edge.</p>
                 <p class="call-out">
                 Without adequate sleep, you get<br />slower and make worse<br />decisions that increase risk to<br />yourself and your unit</b>
                </p>
                <h5>Without sleep, you\'ll perform like you are drunk</h5>
                <p><img class='module-image-background' src='./assets/img/module-images/power-of-sleep/drunkard.png'/></p>
        `,
        questionType: 'module',

        uniqueAnswerId: null,
        autofill: false,
        answerOptions: null,
        conditionalQuestions: [],
        isCheckInQuestion: false
    },
    {
        id: 2,
        questionText: 'Lets look at the data.',
        questionEmphasis: ``,
        subText: `<p><img class='module-image-background' src='./assets/img/module-images/power-of-sleep/hours-awake-graph.svg'/></p>
                  <p><img class='module-image-background' src='./assets/img/module-images/power-of-sleep/blood-alcohol-graph.svg'/></p>`,
        questionType: 'module',

        uniqueAnswerId: null,
        autofill: false,
        answerOptions: null,
        conditionalQuestions: [],
        isCheckInQuestion: false
    },
  ]
};
