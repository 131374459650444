import moment from 'moment';
import { AssessmentQuestion } from '../../lib/services/models';
import { DateTimeWrapper } from '../../lib/shared/datetime-wrapper';

export class FieldBase {
  fieldName: string;
  answerId: string;
  conditions: Condition[];
}

export class ConsecutiveField extends FieldBase {
  maxSequenceLength = 0;
}

export class OccuranceField extends FieldBase {
  occurances = 0;
}

export class GeneralField extends FieldBase {}

export class SumField extends FieldBase {
  sum = 0;
}

export class FieldValue {
  fieldName: string;
  value: number|moment.Moment|string;
}

export class Condition {
  constructor(condition: Condition) {
    this.summationType = condition.summationType;
    this.fieldName = condition.fieldName;
    this.valueToCompare = condition.valueToCompare;
    this.operator = condition.operator;
    this.priority = condition.priority;
    this.additionalCondition = condition.additionalCondition;
  }
  summationType: DataSummationType;
  fieldName: string;
  valueToCompare: string | number| moment.Moment | Array<string | number>;
  operator: EqualityType;
  priority: number;
  additionalCondition?: Condition;
}



export class Calculation {
  constructor(calc: Calculation) {
    this.dataFields = calc.dataFields;
    this.operation = calc.operation;
    this.priority = calc.priority;
    this.calculations = calc.calculations;
  }
  dataFields: Array<CalculationField>;
  operation: OperationType;
  priority: number;
  calculations?: Array<Calculation>;
}

export class CalculationField {
  dataFieldName: string;
  value?: number; // use if constant is desired
}

export class CalculationsValueContainer {
  constructor() {
    this.individualFields = new FieldContainer();
  }
  /**
   *
   * @param fieldName
   * @param date
   */
  public individualFields = new FieldContainer();
  public individualFieldsByPatient = new Map<string, // patientId
                                            Map<string, // field
                                            Map<string, // date
                                            Result>>>();
  public patientIds: Array<string>;
  public adherences: number;
}


export class FieldContainer {
  constructor(){
    this.assessmentValues = new Map<string, AssessmentValueDefinition>();
  }
  startDate: moment.Moment;
  endDate: moment.Moment;
  assessmentValues: Map<string, AssessmentValueDefinition>; ;
}

export class FieldValueDefinitionBase {
  constructor(unit: UnitType) {
    this.numberOfOccurances = null;
    this.values = new Map<string, Result>();
    if(unit !== UnitType.Date) {
      this.sum = new Result(0, unit);
      this.average = new Result(0, unit);
      this.standardDeviation = new Result(0, unit);
    } else{
      this.sum = new Result(null, unit);
      this.average = new Result(null, unit);
      this.standardDeviation = new Result(null, unit);
    }
  }
  sum: Result;
  average: Result;
  numberOfOccurances: number;
  standardDeviation: Result;
  values: Map<string, Result> = new Map<string, Result>();
}

export class AssessmentValueDefinition extends FieldValueDefinitionBase {
  constructor(unit: UnitType) {
    super(unit);
    this.selected = false;
  }
  selected: boolean;

}


export class Result {
  constructor(
    val: number|string|moment.Moment|boolean,
    type: UnitType
    ) {
    this.rawValue = val;
    this.type = type;
  }
  rawValue: number|string|moment.Moment|boolean;
  type: UnitType;
  get shortDescription():string {
    return this.type?.getShortDescription(this.rawValue);
  };

  get longDescription():string {
    return this.type?.getLongDescription(this.rawValue);
  }
}


export class FieldDefinitionBase {
  constructor(def: FieldDefinitionBase) {
    this._type = def._type;
    this.name = def.name;
    this.abbreviation = def.abbreviation;
    this.description = def.description;
    this.unit = def.unit;
    this.includeZeroValuesInAggregates = def.includeZeroValuesInAggregates;
    this.defaultValueForEmpty = def.defaultValueForEmpty;
  }
  _type: FieldType;
  name: string;
  abbreviation: string;
  description: string;
  unit: UnitType;
  includeZeroValuesInAggregates?: boolean;
  defaultValueForEmpty?: string | number | boolean;
}

export class AssessmentFieldDefinition extends FieldDefinitionBase {
  constructor(def: AssessmentFieldDefinition) {
    super(def);
    this.assessmentType = def.assessmentType;
    this.answerId = def.answerId;
    this._type = FieldType.Assessment;
  }
  assessmentType: string;

  answerId: string;
}

export class CalculatedFieldDefinition extends FieldDefinitionBase {
  constructor(def: CalculatedFieldDefinition) {
    super(def);
    this.calculations = def.calculations;
    this.priority = def.priority;
    this._type = FieldType.Calculated;
  }
  calculations: Calculation[];
  priority: number;
}

export class AssessmentGroupScoreFieldDefinition extends FieldDefinitionBase {
  constructor(def: AssessmentGroupScoreFieldDefinition) {
    super(def);
    this.assessmentType = def.assessmentType;
    this._type = FieldType.AssessmentGroupScore;
  }
  assessmentType: string;
  aggregateType?: string;
}

export class AssessmentAdherenceFieldDefinition extends FieldDefinitionBase {
  constructor(def: AssessmentAdherenceFieldDefinition) {
    super(def);
    this.assessmentType = def.assessmentType;
    this._type = FieldType.AssessmentAdherence;
    this.unit = UnitType.Percent;
  }
  assessmentType: string;

}

export class SleepModuleDefinition {

  constructor(def: any) {
    this.name = def.name;
    this.formula = def.formula;
    this.title = def.title;
    this.percentComplete = def.percentComplete;
    this.isMedic = def.isMedic;

  }
  name: string;
  title: string;
  isMedic?: boolean;
  percentComplete: number;
  formula: Array<Condition>;
  categories: Array<string>;
  content: Array<ModuleContentPage>;
  get formulaDisplay(): string {
    let display = '';
    this.formula.forEach((condition, index) => {
      display += `${condition.fieldName} is ${condition.operator} than ${condition.valueToCompare}`;
      if (index < this.formula.length - 1) { display += ' AND '; }
    });
    return display;
  }
}

export class ModuleContentPage {
  constructor() {

  }
  title: string;
  content: string;
  questionText: string;
  questionsOptions: [];
  uniqueAnswerId: string;

}

export class PatientFlagDefinition {
  name: string;
  description: string;
  formula: Array<Condition>;
}

// Enum Types
export enum OperationType {
  Add,
  Subtract,
  Multiply,
  Divide
}

export enum EqualityType {
  GreaterThan = 'Greater Than',
  LessThan = 'Less Than',
  Equal = 'Equal To',
  NotEqual = 'Not Equal To',
  Contains = 'Contains'
}

export enum ComparisonType {
  And,
  Or
}

export enum DataSummationType {
  Sum = 'Sum',
  Average = 'Average',
  MaxValue = 'Max Value',
  NumberOfOccurances = 'Number of Occurences',
  LatestValue = 'Latest Value',
  BaseLineValue = 'Baseline Value',
  Concat = 'Concat'
}



export enum FieldType {
  Calculated = 'calculated',
  SleepDiary = 'sleep-diary',
  AssessmentGroupScore = 'asssessment-group-score',
  Assessment = 'assessment',
  AssessmentAdherence = 'assessment-adherence'
}

enum DataType {
  Minutes = 'minutes',
  Hours = 'hours',
  Days = 'days',
  Date = 'date',
  Time = 'time',
  MorningTime = 'morningtime',
  EveningTime = 'eveningtime',
  YesNo = 'yesno',
  Text = 'text',
  Number = 'number',
  Percent = 'percent',
  Multi = 'multi'
}


export class UnitType {
  static readonly Minutes = new UnitType(DataType.Minutes, true, '{0}{1}', '{0}{1}');
  static readonly Hours = new UnitType(DataType.Hours, true, '{0}{1}', '{0}{1}');
  static readonly Days = new UnitType(DataType.Days, true, '{0} days', '{0} days');
  static readonly Date = new UnitType(DataType.Date, false, '{0}', '{0}');
  static readonly Time = new UnitType(DataType.Time, true, '{0}:{1}', '{0}:{1}');
  static readonly MorningTime = new UnitType(DataType.MorningTime, true, '{0}:{1}', '{0}:{1}');
  static readonly EveningTime = new UnitType(DataType.EveningTime, true, '{0}:{1}', '{0}:{1}');
  static readonly YesNo = new UnitType(DataType.YesNo, false, '{0}', '{0}');
  static readonly Number = new UnitType(DataType.Number, true, '{0}', '{0}');
  static readonly Text = new UnitType(DataType.Text, false, '{0}', '{0}');
  static readonly Percent = new UnitType(DataType.Percent, true, '{0}%', '{0}%');
  static readonly Multi = new UnitType(DataType.Multi, false, '{0}', '{0}');

  private constructor(private readonly key: DataType,
                      public isNumeric: boolean,
                      public readonly abbreviation?: string,
                      public readonly description?: string,
                      ) {}


  getShortDescription(value: any): string {
    return this.getDescription(value, this.abbreviation);
  }

  getLongDescription(value: any): string {
    return this.getDescription(value, this.abbreviation);
  }

  private getDescription(value: number|string|moment.Moment|boolean, template: string) {
    if (value == null || Object.is(value,Number.NaN) || template == null) { return '-'; }
    let returnValue = '';
    switch (this.key) {
      case DataType.YesNo:
        returnValue =  (value as boolean) === true ? 'Yes' : 'No';
        break;
      case DataType.Number:
        returnValue = this.formatString(this.description, value.toString());
        break;
      case DataType.Text:
        returnValue = this.formatString(this.description, value);
        break;
      case DataType.Hours:
      case DataType.Minutes:
        const hrsMins = this.getHoursMinutesFromNumber((value as number), this.key === DataType.Hours);
        const hoursString = `${hrsMins.hours}h `;
        const minutesString = `${hrsMins.minutes}m`;
        returnValue = (hrsMins) ? this.formatString(this.description, hoursString, minutesString) : null;
        break;
      case DataType.Time:
      case DataType.MorningTime:
      case DataType.EveningTime:
        const hoursAndMinutes = this.getHoursMinutesFromNumber(value as number);
        returnValue = this.formatString(this.description,
                                          (hoursAndMinutes.hours > 9) ? hoursAndMinutes.hours.toString() : '0' + hoursAndMinutes.hours.toString(),
                                          (hoursAndMinutes.minutes > 9) ? hoursAndMinutes.minutes.toString() : '0' + hoursAndMinutes.minutes.toString());
        break;
      case DataType.Date:
        const dateValue = (value as moment.Moment);
        returnValue = dateValue.format(new DateTimeWrapper().toShortDate(dateValue, true));
        break;
      case DataType.Percent:
        returnValue = this.formatString(this.description, Math.round((value as number) * 100).toString());
        break;
      default:
        returnValue = value.toString();
        break;
    }
    return returnValue;
  }

  private getHoursMinutesFromNumber(number: number, isHours = false): any {
    if (number == null) {
      return null;
    }
    const hours = Math.floor(number / 60);
    // Question for Dustin - What was the intention of this?
    // if(hours >= 24) {
    //   hours -= 24;
    // }
    const minutes = Math.round(number % 60);
    return { hours, minutes };
  }

  private formatString(template: string, ...args): string {
    args.forEach((arg, index) => {
      template = template.replace(new RegExp('\\{' + index + '\\}', 'g'), arg);
    });
    return template;
  }

  public toString() {
    return this.key;
  }
}


export class BaseCalculationDefinitionConstants {
  minimumLogEntriesToRecommendTactics: number;
  dateKeyFormat = 'YYYY-MM-DD';
}


export class BaseCalculationDefinition {
  GlobalFlags: BaseCalculationDefinitionConstants;
  AssessmentDefinitions: Array<FieldDefinitionBase>;
  CalculatedFieldDefinitions: Array<FieldDefinitionBase>;
  AlgorithmDefinitions: Array<SleepModuleDefinition>;
  ModuleTemplates: Array<AssetDefinition>;
  FlagDefinitions: Array<PatientFlagDefinition>;
  ThresholdDefinitions: any;
  AssessmentQuestions: Array<AssessmentDefinition>;
}

export class AssessmentDefinition {
  name: string;
  description: string;
  instructions: string;
  questions: Array<AssessmentQuestion>;
  groups?: Array<string>;
}


export class AssetDefinition {
  id: number;
  name: string;
  description?: string;
  label: string;
  url?: string;
  assessments: Array<AssessmentDefinition>;
  recommendedBy?: string;
  categories?: Array<string>;
  showInLibrary?: boolean;
  readOnly?: boolean;
  medicRequested?: boolean;
  dateSent?: any;
  percentComplete?: number;
  isCheckIn?: boolean;
  checkInFrequency?: number;
}
